import {config, languages} from '../../config';
import axios from 'axios';
import Vue from 'vue';
import {setAttributeByPath} from "../../utils/common";
import i18n from '@/plugins/i18n/i18n';

const state = {
    didLoadedData: false,
    items: [],
    newItem: null,
    lastTempId: -1,
    simplifiedTutorials: [],
};

const getters = {
    didLoadedData: state => state.didLoadedData,

    tutorials: (state) => {
        return state.items.filter(i => i.parentIds.length === 0).filter((item) => item.id === item.mainTranslationId);
    },

    tutorialCount: (state) => {
        return getters.tutorials(state).length;
    },

    itemById: (state) => (id) => {
        return state.items.find(i => i.id === id);
    },

    /**
     * Returns the direct parents of the given item. Only the closest parent from one upper level are returned.
     */
    getParents: (state) => (child) => {
        return state.items.filter(item => child.parentIds.includes(item.id));
    },

    /**
     * Returns all parents of the given item. It means not only the direct parents but also their parents and parents' parents etc.
     */
    getParentsRecursively: (_, getters) => (child) => {
        const result = [];
        getters.getParents(child).forEach(parent =>
            result.push(parent, ...getters.getParentsRecursively(parent))
        );
        return result;
    },

    /**
     * Returns true if the first item is a parent of the second item. It can be a distant parent (e.g. a parent's parent).
     */
    isParent: (_, getters) => (possibleParent, child) => {
        return getters.getParentsRecursively(child).some(parent =>
            parent.id === possibleParent.id
        )
    },

    /**
     * Returns all direct children of the given item.
     */
    getChildren: () => (parent) => {
        return state.items
            .filter(i => i.parentIds.includes(parent.id))
            .sort((a, b) => a.order - b.order);
    },

    /**
     * Returns all FaqItems which are direct children of the given parent and are NOT tasks.
     */
    getNestedItems: (_, getters) => (parent) => {
        return getters.getChildren(parent)
            .filter(i => i.isTask === false);
    },

    /**
     * Returns all FaqItems which are direct children of the given parent and ARE tasks.
     */
    getTasks: (_, getters) => (parent) => {
        return getters.getChildren(parent)
            .filter(i => i.isTask === true);
    },

    getRootTutorial: (_, getters) => (child) => {
        if (child.parentIds.length > 0) {
            const parent = getters.itemById(child.parentIds[0]);
            return getters.getRootTutorial(parent);
        } else {
            return child;
        }
    },

    /**
     * Returns all FaqItems which are children of the given parent recursively (including subschildren).
     */
    getChildrenRecursively: (_, getters) => (parent) => {
        return state.items.filter(
            i => i.isTask === false &&
                i.id !== parent.id &&
                getters.isParent(parent, i)
        );
    },

    getMaxOrder: (state, getters) => (parent) => {
        const children = getters.getChildren(parent);

        if (children.length === 0) {
            return -1;
        } else {
            return children.reduce((a, v) => v.order > a ? v.order : a, 0);
        }
    },

    prepareNewItem: (state, getters) => (parent = null, isTask = false) => {
        let parentIds = [];
        let order = 0;

        if (parent !== null) {
            parentIds.push(parent.id);
            order = getters.getMaxOrder(parent) + 1;
        }

        return {
            "id": null,
            "language": parent === null ? null : parent.language,
            "intents": [],
            "text": "",
            "help": null,
            "warning": null,
            "source": null,
            "dueDate": null,
            "placeName": null,
            "person": null,
            "parentIds": parentIds,
            "childrenIds": [],
            "choice": parent !== null && isTask === false ? "" : null,
            "followupQuestion": null,
            "tagIds": [],
            "contactIds": [],
            "taskItemIds": [],
            "isTask": isTask,
            "isTemporary": true,
            "order": order,
            "mainTranslationId": null,
            "hidden": parent === null, // if this is a root tutorial, hide it by default
        }
    },

    getTranslations: state => (item) => {
        return languages
            .map(l =>
                state.items.find(i =>
                    i.language == l &&
                    i.mainTranslationId === item.mainTranslationId
                )
            )
            .filter(i => i !== undefined);
    },

    getAnotherTranslation: (state, getters) => item => {
        return getters.getTranslations(item).find(t => t.id !== item.id);
    },

    getLastUpdatedTranslation: (_, getters) => item => {
        const translations = getters.getTranslations(item);
        return translations.reduce((a, v) =>
            v.lastUpdate > a.lastUpdate ? v : a, translations[0]);
    },

    getMaxId: state => {
        const sorted = state.items.slice().sort((a, b) => b.id - a.id);
        if (sorted.length === 0) {
            return 0;
        } else {
            return sorted[0].id;
        }
    },

    prepareNewIntent: () => (main = false) => {
        return {
            text: "",
            main: main,
        }
    },

    tutorialNamesInAllLanguages: (state, getters) => (id) => {
        const translations = getters.getTranslations(getters.itemById(id));
        return translations.map(t => t.name);
    },

    simplifiedTutorials: (state) => {
        return state.simplifiedTutorials
    },
};

const actions = {

    loadTutorials({commit}, onComplete = () => {
    }) {
        commit('didLoadedData', false);
        axios.get(config.serverUrl + 'faq/items/?includeHidden=true&withHtml=true')
            .then((response) => {
                commit('setItems', response.data.items);
                commit('didLoadedData', true);
                onComplete();
            });
    },

    addItem({commit}, {item}) {
        commit('addItem', item);
    },

    async createItem({commit}, {item}) {
        return axios.post(config.serverUrl + 'faq/items/', item).then(response => {
            // use the id retrieved from the server after the new item was saved
            commit('replaceTempId', {item: item, newId: response.data.content.id});
        });
    },

    async updateItem(_, {item}) {
        return axios.put(config.serverUrl + 'faq/items/' + item.id, item);
    },

    async saveTutorial({getters, dispatch}, {tutorial}) {
        let items = [
            tutorial,
            ...getters.getChildrenRecursively(tutorial),
            ...getters.getTasks(tutorial)
        ];

        const newItems = items.filter(i => i.isTemporary);
        const updatedItems = items.filter(i => !i.isTemporary);

        // firstly add new items
        await Promise.all(newItems.map(item =>
            dispatch('createItem', {item})
        ));

        // then process updates (to provent erasing follow-up question)
        await Promise.all(updatedItems.map(item =>
            dispatch('updateItem', {item})
        ));
    },

    setItemAttribute({commit}, payload) {
        commit('setItemAttribute', payload);
    },

    async deleteItem({commit, getters}, {item, includeTranslations = false}) {
        try {
            if (!item.isTemporary) {
                await axios.delete(`${config.serverUrl}faq/items/${item.id}?includeTranslations=${includeTranslations}`);
            }
            const children = getters.getChildrenRecursively(item);
            children.forEach(child => commit('deleteItem', child)); // cascade delete children
            commit('deleteItem', item);
        } catch (e) {
            console.error(e);
            alert(i18n.t('elements.deleteErrorAlert'));
        }
    },

    async updateHiddenByIds({commit, dispatch, getters}, {ids, hidden}) {
        commit('didLoadedData', false);

        for (const id of ids) {
            const item = getters.itemById(id)
            item.hidden = hidden;
            await dispatch('updateItem', {item})
        }

        commit('didLoadedData', true);
    },

    async deleteItemsByIds({commit, dispatch, getters}, ids) {
        commit('didLoadedData', false);

        // delete items one by one;
        // cannot delete them in parallel using Promise.all to avoid concurrency issues on backend!
        for (const id of ids) {
            await dispatch('deleteItem', {item: getters.itemById(id)})
        }

        commit('didLoadedData', true);
    },

    async translateItem({commit}, {id, language}) {
        return axios.post(config.serverUrl + `faq/items/translate/${id}?targetLanguage=${language}`).then(response => {
            // use the id retrieved from the server after the new item was saved
            commit('addItem', response.data.content);
            return response.data.content.id;
        });
    },

    moveItem({commit, getters}, {item, direction}) {
        getters.getParents(item).forEach(parent => {
            const children = item.isTask ? getters.getTasks(parent) : getters.getNestedItems(parent);
            const movedItemIndex = children.findIndex(i => i.id === item.id);
            const siblingIndex = movedItemIndex + direction;

            if (siblingIndex >= 0 && siblingIndex < children.length) {
                const sibling = children[siblingIndex];
                commit('swapItemsOrder', {item, sibling});
            }
        });
    },

    async loadSimplifiedTutorials({commit}, onComplete = () => {
    }) {
        commit('didLoadedData', false);
        axios.get(config.serverUrl + `faq/items/simplified`)
            .then((response) => {
                commit('setSimplifiedTutorials', response.data.items)
                commit('didLoadedData', true);
                onComplete();
            })

    },

    addIntent({commit}, {intent}) {
        commit('addIntent', intent);
    }
};

const mutations = {
    didLoadedData(state, loaded) {
        state.didLoadedData = loaded;
    },

    setItems(state, tutorials) {
        if (tutorials !== undefined && tutorials !== null) {
            state.items = tutorials;
        } else {
            state.items = [];
        }
    },

    addItem(state, item) {
        if (item.id === null) { // for new items
            item.id = getters.getMaxId(state) + 1;
        }

        state.items.push(item);
    },

    setItemAttribute(state, {item, key, value}) {
        const index = state.items.findIndex(i => i.id === item.id);
        setAttributeByPath(state.items[index], key, value);
    },

    deleteItem(state, item) {
        const index = state.items.findIndex(i => i.id === item.id);
        Vue.delete(state.items, index);
    },

    replaceTempId(state, {item, newId}) {
        Vue.set(item, 'isTemporary', false);
        Vue.set(item, 'id', newId);
    },

    swapItemsOrder(state, {item, sibling}) {
        const siblingOrder = sibling.order;
        Vue.set(sibling, 'order', item.order);
        Vue.set(item, 'order', siblingOrder);
    },

    setSimplifiedTutorials(state, tutorials) {
        state.simplifiedTutorials = tutorials
    },

    addIntent(state, intent) {
        for (let tutorial of state.simplifiedTutorials) {
            if (tutorial.id === intent.faqItemId) {
                tutorial.intents.push(intent)
            }
        }
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
