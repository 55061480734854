<template>
    <div 
        class="floating-loading"
        :class="shown ? 'shown' : ''"
        :style="{'--size': size}"
    >
        <v-progress-circular
                :size="size"
                :width="0.15 * size"
                :color="color"
                indeterminate
        ></v-progress-circular>
    </div>
</template>

<script>
    export default {
        name: "FloatingLoading",

        props: {
            shown: {
                type: Boolean,
                default: false,
            },
            size: {
                type: Number,
                default: 40,
            },
            color: {
                type: String,
                default: 'primary',
            }
        }
    }
</script>

<style scoped>
.floating-loading {
    position: fixed;
    top: 1em;
    left: 50%;
    margin-left: calc(-0.5px * var(--size));
    z-index: 9999;
    background: rgba(255, 255, 255, 0.8);
    padding: calc(0.5px * var(--size));
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px calc(0.25px * var(--size));
    visibility: hidden;
    opacity: 0;
    transition: visibility 1s, top 1s, opacity 1s;
}

.floating-loading.shown {
    visibility: visible;
    top: 3em;
    opacity: 1;
    transition: none;
}
</style>
