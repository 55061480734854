<template>
    <v-layout class="tutorial-header" align-center>
        <v-icon v-if="showBack" @click="goToList" color="primary" large>chevron_left</v-icon>
        <slot/>
    </v-layout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "DetailHeader",

    props: {
        /**
         * Whether to display an arrow on the left leading to the previous page.
         */
        showBack: {
            type: Boolean,
            default: false,
        },

        /**
         * The target route (its name) for the back button.
         * If the button is shown, this prop is required.
         */
        backRouteName: {
            type: String,
            default: null,
        },
    },

    computed: {
        ...mapGetters('history', ['previousRoute']),
    },

    methods: {
        /**
         * Redirects from a detail to the list of all items.
         * a. If the list was the previous route, the standard history back is used to persist scroll position, filters, etc.
         * b. Otherwise, a new list component instance is created and the detail is replaced by the list in the history stack. 
         * The replace action prevents from going back from the list to the detail.
         */
        goToList() {
            if (this.previousRoute.name == this.backRouteName) {
                this.$router.back();
            } else {
                this.$router.replace({name: this.backRouteName})
            }
        }
    }
}
</script>

<style scoped>
    .tutorial-header {
        box-sizing: border-box;
        z-index: 3;
        width: 100%;
        background: #efefef;
        position: sticky;
        box-shadow: rgba(0, 0, 0, 0.15) 0 0 5px;
        border-bottom: 1px solid lightgray;
        padding: 0.8rem 1.6rem 0.8rem;
        top: 65px;
    }

    @media (max-width: 960px) {
        .tutorial-header {
            top: 55px;
        }
    }

    .tutorial-header h2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 60vw;
    }
    @media (max-width: 959px) {
        .tutorial-header h2 {
            max-width: 50vw;
        }
    }
</style>