<template>
    <v-dialog :value="model" max-width="600px" persistent scrollable>
        <v-card>
            <v-card-title class="headline">
                {{ title || $t('elements.reallyPerformThisAction') }}
            </v-card-title>

            <v-card-text class="pt-4">
                <p>{{ message || $t('elements.areYouSureToPerformThisAction') }}</p>
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn color="dark" text @click="closeDialog">{{ cancelText || $t('buttons.cancel') }}</v-btn>
                <v-btn color="primary" @click="confirmDialog">{{ confirmText || $t('buttons.ok') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: "ConfirmDialog",

        props: {
            model: {
                type: Boolean,
                default: false,
            },
            title: {
                type: String,
            },
            message: {
                type: String,
            },
            cancelText: {
                type: String,
            },
            confirmText: {
                type: String,
            },
        },

        methods: {
            confirmDialog() {
                this.$emit("confirm");
                this.closeDialog();
            },
            
            closeDialog() {
                this.$emit('close');
            },
        },
    }
</script>

<style scoped>
</style>