import Vue from 'vue';

export const userRoles = user => {
    return user.roles
        .map(role => {
            if (role === 'ROLE_ADMIN') {
                return 'admin';
            } else if (role === 'ROLE_EDITOR') {
                return 'editor';
            } else {
                return null;
            }
        })
        .filter(role => role !== null)
        .join(', ');
};

Vue.filter('userRoles', userRoles);