
const state = {
    hasChanges: false
};

const getters = {
    hasChanges(state) {
        return state.hasChanges;
    }
};

const actions = {

    makeChanges({ commit }) {
        commit('setHasChanges', true);
    },

    resolveChanges({ commit }) {
        commit('setHasChanges', false);
    },

};

const mutations = {

    setHasChanges(state, value) {
        state.hasChanges = value;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
