<template>
  <div id="auth-section">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'authSection'
}

</script>

<style>

</style>
