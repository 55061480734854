<template>
    <Loading v-if="!didLoadedData || !this.contact"></Loading>
    <div v-else>
        <detail-header :show-back="true" backRouteName="contacts">
            <h2 v-if="isNew">{{ $t('contactDetail.elements.newContact') }}</h2>
            <h2 v-else>{{ $t('contactDetail.elements.editContact') }}</h2>

            <v-spacer/>

            <v-menu bottom left v-if="currentUserCanEdit('contacts') && !isNew">
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-icon>more_vert</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="attemptToDeleteContact()">
                        <v-list-item-title>{{ $t('contactDetail.buttons.deleteContact') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <save-button v-if="currentUserCanEdit('contacts') && didLoadedData" :is-saving="isSaving" @click="save()"/>
        </detail-header>

        <v-form ref="form" d class="contact-detail container-default-padding">
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="4" v-for="(language, i) in languages" :key="`name-${language}`">
                            <v-text-field
                                :rules="[rules.required_rule, rules.max_length_rule]"
                                color="dark"
                                :label="localizedNameLabel(language)"
                                :autofocus="i === 0"
                                v-model.trim="contact.localizedAttributes[language].name"
                                @input="makeChanges"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" v-for="language in languages" :key="`description-${language}`">
                            <v-textarea
                                    color="dark"
                                    :label="localizedDescriptionLabel(language)"
                                    :rows="1"
                                    auto-grow
                                    v-model.trim="contact.localizedAttributes[language].description"
                                    @input="makeChanges"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-6">
                <v-card-title>{{ $t('contactDetail.elements.howToContact') }}</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                    color="dark"
                                    :label="$t('contactDetail.card.address')"
                                    v-model.trim="contact.address"
                                    :rules="[rules.max_length_rule]"
                                    @input="makeChanges"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <vue-tel-input-vuetify
                                    defaultCountry="CZ"
                                    color="dark"
                                    :label="$t('contactDetail.card.phone')"
                                    mode="international"
                                    :value="contact.phone"
                                    :hint="phoneHintText"
                                    :persistent-hint="true"
                                    @validate="e => onPhoneValidChange(e)"
                                    @country-changed="e => onPhoneCountryChange(e)"
                                    :rules="[rules.phone_rule, rules.max_length_rule]"
                                    @input="phoneChanged"
                            ></vue-tel-input-vuetify>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                    color="dark"
                                    :label="$t('contactDetail.card.email')"
                                    v-model.trim="contact.email"
                                    :rules="[rules.email_rule, rules.max_length_rule]"
                                    @input="makeChanges"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-text-field
                                    color="dark"
                                    :label="$t('contactDetail.card.web')"
                                    v-model.trim="contact.web"
                                    :rules="[rules.url_rule, rules.max_length_rule]"
                                    @input="makeChanges"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                    color="dark"
                                    label="Facebook"
                                    v-model.trim="contact.facebook"
                                    :rules="[rules.url_rule, rules.max_length_rule]"
                                    @input="makeChanges"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-6">
                <v-card-title>{{ $t('contactDetail.elements.whereOrganizationIs') }}</v-card-title>
                <v-card-text>
                    <div class="mb-4 d-flex align-center">
                        <v-btn v-if="hasCoordinates" class="mt-2 mr-2" @click="removePosition()">{{ $t('contactDetail.buttons.withoutDetermination') }}</v-btn>
                        <v-menu v-model="showAddressOptions" style="max-width: 600px">
                            <template v-slot:activator="{  }">
                                <v-btn class="mt-2 mr-2" :disabled="isSearching || !contact.address" @click="searchAddress()">{{isSearching ? '...' : $t('contactDetail.buttons.useAddress')}}</v-btn>
                            </template>
                            <v-list nav>
                                <v-list-item v-for="(item, index) in addressOptions" :key="index" @click="useAddressAsPosition(item)">
                                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-btn class="mt-2" @click="useMapCenterAsPosition()">{{ $t('contactDetail.buttons.useMapCenter') }}</v-btn>
                    </div>

                    <l-map
                            :center="currentCenter"
                            :zoom="currentZoom"
                            style="z-index: 0;min-height: 400px;"
                            ref="map"
                            @update:center="center => this.currentCenter = center"
                            @update:zoom="zoom => this.currentZoom = zoom"
                    >
                        <l-tile-layer :url="url" :attribution="attribution" />
                        <l-marker
                                v-if="hasCoordinates"
                                :lat-lng="coordinates"
                        ></l-marker>
                    </l-map>
                </v-card-text>
            </v-card>
        </v-form>

        <DeleteContactDialog
            v-if="!isNew"
            :model="deleteDialog"
            :contacts="[contact]"
            @delete="onDeleteContact"
            @close="closeDeleteDialog"
        />

        <confirm-dialog
            :title="$t('contactDetail.elements.saveWithoutPositionConfirm')"
            :message="$t('contactDetail.elements.saveWithoutPositionMessage')"
            :confirmText="$t('buttons.save')"
            :cancelText="$t('buttons.back')"
            :model="saveWithoutPositionDialog"
            @confirm="save(true)"
            @close="saveWithoutPositionDialog = false"
        />
    </div>
</template>

<script>
    import Loading from "../components/Loading";
    import {mapActions, mapGetters} from "vuex";
    import {LMap, LTileLayer, LMarker } from 'vue2-leaflet';
    import {latLng} from "leaflet";
    import { OpenStreetMapProvider } from 'leaflet-geosearch';
    import { languages } from '@/config';
    import SaveButton from '../components/SaveButton.vue';
    import DetailHeader from '../components/DetailHeader.vue';
    import DeleteContactDialog from '../components/DeleteContactDialog.vue';
    import ConfirmDialog from '../components/ConfirmDialog.vue';

    export default {
        name: "ContactDetail",

        components: {
            Loading,
            LMap,
            LTileLayer,
            LMarker,
            SaveButton,
            DetailHeader,
            DeleteContactDialog,
            ConfirmDialog,
        },

        mounted() {
            this.addCross();
            this.load();
            this.fixMap();
        },

        updated() {
            this.addCross();
            this.fixMap();
        },

        data() {
            return {
                contact: null,

                isPhoneValid: false,
                phoneCountryCode: null,

                isSaving: false,
                isSearching: false,
                deleteDialog: false,

                url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                currentZoom: 20,
                currentCenter: undefined,
                geosearch: new OpenStreetMapProvider(),
                addressOptions: [],
                showAddressOptions: false,
                saveWithoutPositionDialog: false,
            }
        },

        computed: {
            ...mapGetters('contacts', ['didLoadedData', 'contactById']),
            ...mapGetters('user', ['currentUserCanEdit']),

            isNew() { return this.$route.params.id === "new"; },
            contactId() { return parseInt(this.$route.params.id)},

            hasCoordinates() {
                return this.contact.latitude && this.contact.longitude;
            },
            coordinates() {
                return latLng(this.contact.latitude, this.contact.longitude);
            },
            phoneHintText() {
                if (this.isPhoneValid) {
                    return `✅ ${this.$t('contactDetail.elements.validFormatFor')} ${this.phoneCountryCode}`;
                } else if (this.isNationalEmergency(this.contact.phone)) {
                    return `✅ ${this.$t('contactDetail.elements.validEmergencyNumber')}`;
                } else {
                    return null;
                }
            },
            languages() {
                return languages;
            },

            rules() {
                return {
                    required_rule: v => typeof v === "string" && !!v.trim() || this.$t('rules.required'),
                    max_length_rule: v => (!v || v.length <= 255) || this.$t('rules.maxChars', [255]),
                    phone_rule: v => {
                        return !v || this.isPhoneValid || this.isNationalEmergency(v) || this.$t('rules.wrongPhoneFormat');
                    },
                    email_rule: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t('rules.wrongEmailFormat'),
                    url_rule: v => !v || /^http(s)?:\/\/[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&%'()*+,;=.]+$/.test(v) || this.$t('rules.wrongUrlFormat'),
                    coordinate_rule: v => !v || /^-?[0-9]+(\.[0-9]+)?$/.test(v) || this.$t('rules.wrongCoordinateFormat'),
                    both_coordinates_rule: () => (!this.contact.latitude && !this.contact.longitude) || (!!this.contact.latitude && !!this.contact.longitude) || this.$t('rules.requiredBothCoordinates'),
                }
            }
        },

        watch: {
            contactId: async function() {
                this.load();
            },
            contact: function(contact) {
                // adapt the map center and zoom based on the edited contact
                if (contact.latitude && contact.longitude) {
                    // a specific place with max zoom
                    this.currentZoom = 20;
                    this.moveMapCenterToMapItem();
                } else {
                    // the whole Czechia zoomed out
                    this.currentZoom = 7;
                    this.currentCenter = latLng(49.8038, 15.4749);
                }
            }
        },

        methods: {
            ...mapActions('contacts', ['loadContact', 'prepareNewContact', 'createContact', 'updateContact', 'prepareContactForEdit']),
            ...mapActions('changeTracking', ['makeChanges', 'resolveChanges']),

            async load() {
                if (this.isNew) {
                    this.contact = await this.prepareNewContact();
                } else {
                    await this.loadContact(this.contactId);
                    const contact = this.contactById(this.contactId);
                    this.contact = await this.prepareContactForEdit(contact);
                }
            },

            /**
             * Handling phone change manually to be able to detect changes.
             * When using v-model and @input like at standard text fields the changes were recorded too often, even when the value did not change itself.
             */
            phoneChanged(newPhone) {
                if (newPhone !== this.contact.phone) {
                    this.contact.phone = newPhone;
                    this.makeChanges();
                }
            },

            validateForm() {
                return this.$refs.form.validate();
            },

            async save(saveWithoutPosition = false) {
                if (!this.validateForm()) {
                    alert(this.$t('contactDetail.elements.invalidFormAlert'));
                    return;
                }

                if (!this.hasCoordinates && !saveWithoutPosition) {
                    this.saveWithoutPositionDialog = true;
                    return;
                }

                this.isSaving = true;
                const request = this.isNew ? this.createContact : this.updateContact;

                await request({contact: this.contact});

                this.resolveChanges();
                this.isSaving = false;

                if (this.isNew) {
                  await this.$router.push({name: "contacts"});
                }
            },

             addCross() {
                // hacking - add a cross to the middle of the map
                const map = document.querySelector('.contact-detail .vue2leaflet-map');
                if (
                    document.getElementsByClassName('cross').length === 0 &&
                    map !== null
                ) {
                    const cross = document.createElement('div');
                    cross.className = "cross";
                    map.appendChild(cross);
                }
            },

            fixMap() {
                // hacking - fix leaflet tiles loading after reopening the dialog
                if (this !== undefined && this.$refs.map !== undefined) {
                    this.$refs.map.mapObject.invalidateSize();
                }
            },

            removePosition() {
                this.contact.latitude = null;
                this.contact.longitude = null;
                this.makeChanges();
            },

            useMapCenterAsPosition() {
                const coordinates = this.currentCenter;
                this.updateContactCoordinates(coordinates.lat, coordinates.lng);
                this.makeChanges();
            },

            async searchAddress() {
                this.isSearching = true;
                const results = await this.geosearch.search({ query: this.contact.address });
                if (results.length > 0) {
                    this.addressOptions = results;
                    this.showAddressOptions = true;
                } else {
                    alert(this.$t('contactDetail.elements.addressNotFoundAlert'));
                }
                this.isSearching = false;
            },

            useAddressAsPosition(address) {
                const lon = parseFloat(address.x);
                const lat = parseFloat(address.y);
                this.updateContactCoordinates(lat, lon);
                this.moveMapCenterToMapItem();
            },

            updateContactCoordinates(lat, lon) {
                // keep only 7 decimal places for readability
                const roundBase = Math.pow(10, 7);
                this.contact.latitude = Math.round(lat * roundBase) / roundBase;
                this.contact.longitude = Math.round(lon * roundBase) / roundBase;
                this.makeChanges();
            },

            moveMapCenterToMapItem() {
                this.currentCenter = this.coordinates;
            },

            isNationalEmergency(phone) {
                return /^[19][0-9]{2,3}$/.test(phone); // e.g. 112, 911, 155
            },

            onPhoneValidChange(e) {
                this.isPhoneValid = e.isValid && e.canBeInternationallyDialled && e.possible;
            },

            onPhoneCountryChange(e) {
                this.phoneCountryCode = e.name;
            },

            localizedNameLabel(language) {
                const languageName = this.$options.filters.languageName(language);
                return `${this.$t('contactDetail.elements.localizedName')}* (${languageName})`;
            },

            localizedDescriptionLabel(language) {
                const languageName = this.$options.filters.languageName(language);
                return `${this.$t('contactDetail.elements.localizedDescription')} (${languageName})`;
            },

            attemptToDeleteContact() {
                this.deleteDialog = true;
            },

            onDeleteContact() {
                this.$router.push({name: 'contacts'});
            },

            closeDeleteDialog() {
                this.deleteDialog = false;
            },

        },

    }
</script>

<style>
    .cross {
        position: absolute;
        left: calc(50% - 24px);
        top: calc(50% - 26px);
        width: 50px;
        height: 50px;
        background-image: url('/public/cross.svg');
        z-index: 1000;
        opacity: 0.5;
    }
</style>
