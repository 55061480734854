<template>
  <div class="tiptap-wrapper mb-4">
    <div :class="{
      'custom-label': true,
      'error--text': shouldValidate && !valid
    }">
      {{ label }}
    </div>

    <editor-content :editor="editor" :class="{
      'mb-2': true,
      'error-state': shouldValidate && !valid
    }"/>

    <bubble-menu :editor="editor" :tippy-options="{ duration: 100 }" v-if="editor">
      <v-btn icon @click.prevent="editor.chain().focus().toggleBold().run()" :class="editor.isActive('bold') && 'active'">
        <v-icon>format_bold</v-icon>
      </v-btn>
      <v-btn icon @click.prevent="setLink" :class="editor.isActive('link') && 'active'">
        <v-icon>link</v-icon>
      </v-btn>
      <v-btn icon @click.prevent="editor.chain().focus().unsetLink().run()">
        <v-icon>link_off</v-icon>
      </v-btn>
    </bubble-menu>

    <VMessages :value="shouldValidate ? errorBucket : []" color="error"/>
  </div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2'
import {Text} from "@tiptap/extension-text"
import {Document} from "@tiptap/extension-document"
import {Link} from "@tiptap/extension-link"
import {Bold} from "@tiptap/extension-bold";
import {Paragraph} from "@tiptap/extension-paragraph";
import {VInput, VMessages} from "vuetify/lib/components";

export default {
  name: 'RichTextField',
  extends: VInput,

  components: {
    EditorContent,
    BubbleMenu,
    VMessages,
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
    }
  },

  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
          Document,
          Text,
          Bold,
          Paragraph,
          Link.configure({
            openOnClick: false,
          }),
      ],
      onUpdate: () => {
        let html = this.editor.getHTML();

        // if it contains only empty paragraphs, replace it with an empty string
        // it helps with further validation outside this component
        if (html.match(/^((<p>)?\s*(<\/p>)?)+$/g)) {
          html = '';
        }

        this.$emit('input', html);
      },
    })
  },

  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor
            .chain()
            .focus()
            .extendMarkRange('link')
            .unsetLink()
            .run()

        return
      }

      // update link
      this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url })
          .run()
    },
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">
.tiptap-wrapper {
  width: 100%;
}
.ProseMirror {
  border-bottom: thin solid gray; /* simulate Vuetify text field */
}
.error-state .ProseMirror {
  border-bottom: thin solid red; /* simulate Vuetify text field */
}
.ProseMirror {
  outline: 0 solid transparent; /* remove default border around the content editable element */
  > * + * {
    margin-top: 0.75em;
  }
  p:last-child {
    margin-bottom: 0;
  }
}
.tippy-box {
  background: white;
  border-radius: 0.3em;
  border: 1px solid gray;
  padding: 0.4em;
}
.tippy-box button.active {
  color: black;
  background: lightgray;
}
</style>