import {config} from '@/config';
import axios from 'axios';
import i18n from '@/plugins/i18n/i18n';

// The current user management

const LOCAL_STORAGE_TOKEN_KEY = "auth_token";

const state = {
    didLoadedUserData: false,
    data: null,
    token: null,
    isDownloadingUserData: false
};

const getters = {
    isLoggedIn(state) {
        return state.token !== null;
    },
    username(state) {
        if (state.data === null) {
            return null;
        } else {
            return state.data.username;
        }
    },
    currentUser(state) {
        if (state.data === null) {
            return null;
        } else {
            return state.data;
        }
    },
    currentUserHasRole: (state) => role => {
        if (state.data === null) {
            return false;
        } else {
            return state.data.roles.includes(role);
        }
    },
    currentUserCanAccess: state => module => {
        if (state.data === null) {
            return false;
        }

        let forbiddenModules = [];

        if (state.data.roles.includes("ROLE_ADMIN")) {
            forbiddenModules = [];
        } else if (state.data.roles.includes("ROLE_EDITOR")) {
            forbiddenModules = ["users"];
        } else {
            forbiddenModules = ["reports", "users", "userMessages"];
        }

        return forbiddenModules.includes(module) === false;
    },
    currentUserCanEdit: state => module => {
        if (state.data === null) {
            return false;
        }

        if (state.data.roles.includes("ROLE_ADMIN")) {
            return true;
        } else if (state.data.roles.includes("ROLE_EDITOR")) {
            return ["users"].includes(module) === false;
        } else {
            return false;
        }
    },
};

const actions = {

    login({ commit }, { username, password, onComplete = () => {} }) {
        axios.post(config.serverUrl + 'login', {
            username: username,
            password: password
        })
            .then((response) => {
                const token = response.data.token;
                localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, token);
                commit('setToken', token);
            })
            .catch(() => {
                alert(i18n.t('login.elements.errorWhileLoginAlert'));
            })
            .finally(() => onComplete());
    },

    restoreLogin({ commit }) {
        const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        commit('setToken', token);
    },

    logout({ commit }) {
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
        commit('resetAll');
        commit('setToken', null);
    },

    downloadUserData({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            commit('startUserDataDownload');
            axios.get(config.serverUrl + 'user')
                .then((response) => {
                    commit('setUserData', response.data.content);
                    commit('stopUserDataDownload');
                    resolve();
                })
                .catch(() => {
                    dispatch('logout');
                    reject();
                });
        });
    },

    async changePassword(_, {oldPassword, newPassword }) {
        try {
            const data = {oldPassword, newPassword};
            await axios.put(config.serverUrl + `user`, data);
        } catch (e) {
            console.log(e.response.status, e.response.data.message);
            if (e.response.status === 403) {
                alert(i18n.t('userDetail.elements.wrongOldPasswordAlert'));
            } else {
                console.error(e);
                alert(i18n.t('elements.saveErrorAlert'));
            }
            throw e;
        }
    },

};

const mutations = {

    setToken(state, token) {
        state.token = token;
        if (token === null) {
            delete axios.defaults.headers.common['Authorization'];
        } else {
            axios.defaults.headers.common['Authorization'] = token;
        }
    },

    setUserData(state, data) {
        state.data = data;
    },

    startUserDataDownload(state) {
        state.didLoadedUserData = false;
        state.isDownloadingUserData = true;
    },

    stopUserDataDownload(state) {
        state.didLoadedUserData = true;
        state.isDownloadingUserData = false;
    },

    resetAll(state) {
        state.data = null;
        state.didLoadedUserData = false;
        state.isDownloadingUserData = false;
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
