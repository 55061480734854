<template>
    <Loading v-if="!didLoadedData || !this.user"></Loading>
    <div v-else>
        <detail-header :show-back="true" backRouteName="users">
            <h2 v-if="isNew">{{ $t('userDetail.newUser') }}</h2>
            <h2 v-else>{{ $t('userDetail.editUser') }}</h2>

            <v-spacer/>

            <v-menu bottom left v-if="currentUserCanEdit('users') && !isNew">
                <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                        <v-icon>more_vert</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="attemptToDeleteUser()">
                        <v-list-item-title>{{ $t('userDetail.buttons.deleteUser') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <save-button v-if="currentUserCanEdit('users') && didLoadedData" :is-saving="isSaving" :success="savedSuccessfully" @click="save()"/>
        </detail-header>

        <v-form ref="form" class="container-default-padding">
            <v-card>
                <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                            color="dark"
                            :label="$t('userDetail.form.firstLastName')"
                            v-model.trim="user.name"
                            :rules="[rules.required_rule, rules.max_length_rule]"
                            @input="makeChanges"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                            color="dark"
                            :label="$t('userDetail.form.login')"
                            v-model.trim="user.username"
                            autocomplete="username"
                            :rules="[rules.required_rule, rules.max_length_rule]"
                            @input="makeChanges"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-select
                            color="dark"
                            :label="$t('userDetail.form.role')"
                            :items="roles"
                            :value="topUserRole(this.user)"
                            @input="role => user.roles = role ? [role] : []"
                        ></v-select>
                      </v-col>

                      <v-col cols="12">
                        <div v-if="isChangingPassword || isNew" class="grey lighten-5 pt-3 px-4 mb-3">
                          <div class="d-flex justify-space-between align-center">
                            <h4>{{ $t('userDetail.passwordChange') }}</h4>
                            <v-btn v-if="!isNew" small dense text @click="doNotChangePassword()">{{ $t('userDetail.buttons.dontChangePass') }}</v-btn>
                          </div>
                          <v-text-field
                              :label="$t('userDetail.form.password')"
                              v-model.trim="user.password"
                              type="password"
                              autocomplete="new-password"
                              :rules="[rules.required_rule, rules.password_rule, rules.max_length_rule]"
                              @input="makeChanges();"
                              @blur="$refs.form.validate()"
                          ></v-text-field>
                          <v-text-field
                              :label="$t('userDetail.form.passwordAgain')"
                              v-model.trim="confirmPassword"
                              type="password"
                              autocomplete="new-password"
                              :rules="[rules.required_rule, rules.confirm_password_rule]"
                              @input="makeChanges"
                          ></v-text-field>
                        </div>
                        <v-btn v-else @click="changePassword()">{{ $t('userDetail.buttons.changePass') }}</v-btn>
                      </v-col>

                    </v-row>
                </v-card-text>
            </v-card>

        </v-form>
    </div>
</template>

<script>
    import Loading from "../components/Loading";
    import {mapActions, mapGetters} from "vuex";
    import SaveButton from '../components/SaveButton.vue';
    import DetailHeader from '../components/DetailHeader.vue';

    export default {
        name: "UserDetail",

        components: {
            Loading,
            SaveButton,
            DetailHeader,
        },

        mounted() {
            this.load();
        },

        data() {
            return {
                isChangingPassword: false,
                confirmPassword: '',

                user: null,
                isSaving: false,
                savedSuccessfully: false,

            }
        },

        computed: {
            ...mapGetters('users', ['didLoadedData', 'userById', 'topUserRole']),
            ...mapGetters('user', ['currentUser']),
            ...mapGetters('user', ['currentUserCanEdit']),

            isNew() { return this.$route.params.id === "new"; },
            userId() { return parseInt(this.$route.params.id)},

            rules() {
                return {
                    required_rule: v => typeof v === "string" && !!v.trim() || this.$t('rules.required'),
                    max_length_rule: v => (!v || v.length <= 255) || this.$t('rules.maxChars', [255]),
                    password_rule: v => (!v || v.length >= 8) || this.$t('rules.passwordMinChars', [8]),
                    confirm_password_rule: v => v === this.user.password || this.$t('rules.passwordsDontMatch'),
                }
            },

            roles() {
                return [
                  { text: this.$t('userDetail.roles.admin'), value: 'ROLE_ADMIN' },
                  { text: this.$t('userDetail.roles.editor'), value: 'ROLE_EDITOR' },
                  { text: this.$t('userDetail.roles.noRole'), value: null}
                ]
            },
        },

        watch: {
            userId() {
                this.load();
            },
        },

        methods: {
            ...mapActions('users', ['loadUser', 'prepareNewUser', 'createUser', 'updateUser', 'prepareUserForEdit', 'deleteUsers']),
            ...mapActions('changeTracking', ['makeChanges', 'resolveChanges']),

            async load() {
                if (this.isNew) {
                    this.user = await this.prepareNewUser();
                } else {
                    await this.loadUser(this.userId);
                    const user = this.userById(this.userId);
                    this.user = await this.prepareUserForEdit(user);
                }
            },

            validateForm() {
                return this.$refs.form.validate();
            },

            async save() {
                if (!this.validateForm()) {
                    alert(this.$t('elements.invalidFormAlert'));
                    return;
                }

                try {
                  this.isSaving = true;
                  const request = this.isNew ? this.createUser : this.updateUser;

                  await request({user: this.user});

                  this.resolveChanges();
                  this.savedSuccessfully = true;
                  this.isSaving = false;

                  if (this.isNew) {
                    await this.$router.push({name: 'users'});
                  }

                } catch (e) {
                  this.savedSuccessfully = false;
                  this.isSaving = false;
                }
            },

            async attemptToDeleteUser() {
                if (this.user.id === this.currentUser.id) {
                    alert(this.$t('userDetail.elements.cantDeleteYourselfAlert'));
                } else if (window.confirm(this.$t('userDetail.elements.deleteUserConfirm', [this.user.name]))) {
                    await this.deleteUsers([this.user]);
                    this.$router.push({name: 'users'});
                }
            },

            changePassword() {
                this.isChangingPassword = true;
            },

            doNotChangePassword() {
                this.user.password = null;
                this.confirmPassword = null;
                this.isChangingPassword = false;
            },

        },

    }
</script>

<style>
</style>
