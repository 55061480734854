<template>
    <v-list-item two-line
                 v-if="item !== null"
                 style="margin-left: 0;"
    >
        <v-list-item-content>
            <v-list-item-subtitle>{{ msg }}</v-list-item-subtitle>
            <v-list-item-title class="pointer mt-1" @click="openIntentsDialog()">
                <v-input
                    :value="item.intents"
                    :rules="[rules.intents_rule]"
                    class="pointer text-field-line"
                    @click="openIntentsDialog()"
                >
                    <span v-if="item.intents.length === 0">{{ $t('elements.none') }}</span>
                    <span v-else>{{ item | tutorialName | placeholder($t('elements.noTitle')) }}</span>
                            <span v-if="item.intents.length > 5" class="ml-1">
                                (<i>+ {{ item.intents.length - 1 }} {{ $t('elements.next5') }}</i>)
                            </span>
                            <span v-else-if="item.intents.length > 1" class="ml-1">
                                (<i>+ {{ item.intents.length - 1 }} {{ $t('elements.next1') }}</i>)
                            </span>
                </v-input>
            </v-list-item-title>
        </v-list-item-content>
        <IntentsChatbot
                :model="isIntentModalVisible"
                :intents="item.intents"
                :msg="msg"
                @updateIntents="updateIntents"
                @close="isIntentModalVisible = false"
        />
    </v-list-item>


</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import IntentsChatbot from "./IntentsChatbot";
    import {config} from "../config";

    export default {
        name: "ChatbotDetailItem",
        components: {IntentsChatbot},
        props: {
            chatbot: Object,
            item: Object,
            flat: Boolean,
            index: Number,
            prefix: String,
            msg: String,
            param: String
        },

        computed: {
            itemNumbering() {
                if (this.level > 0) {
                    if (this.prefix !== "") {
                        return this.prefix + (this.index + 1) + ".";
                    } else {
                        return this.index + 1 + ".";
                    }
                } else {
                    return "";
                }
            },
            newItemNumbering() {
                if (this.itemNumbering !== "") {
                    return this.itemNumbering + (this.children.length + 1) + ".";
                } else {
                    return this.children.length + 1 + ".";
                }
            },

            rules() {
                return {
                    intents_rule: v => v && v.length >= config.chatbotIntentLimit || this.$t('rules.fewQuestions')
                }
            },

            ...mapGetters('chatbots', ['prepareNewItem', 'prepareNewIntent']),
        },

        data() {
            return {
                modal: false,
                isIntentModalVisible: false,
            };
        },

        methods: {
            setName(name) {
                if (this.item.intents.length === 0) {
                    const intent = this.prepareNewIntent(true);
                    this.setAttribute('intents', [intent]);
                }
                this.setAttribute('intents.0.text', name);
            },

            setAttribute(key, value) {
                this.setItemAttribute({item: this.chatbot, key, value});
                this.makeChanges();
            },

/*            pushNewQuestion(value) {
                //copie unusual qestion.slice -> push.value, pak nasetovat do chatbota (setAttribute[klic unusualk question, value kopie])
                const copy = this.chatbot.unusualQuestions.slice();
                copy[this.index] = question;

            },*/

            openIntentsDialog() {
                this.isIntentModalVisible = true;
            },

            updateIntents(intents) {
                switch (this.param) {
                    case "fullGreet" :
                        this.setAttribute("fullGreet.intents", intents);
                        break;
                    case "shortGreet" :
                        this.setAttribute("shortGreet.intents", intents);
                        break;
                    case "goodbye" :
                        this.setAttribute("goodbye.intents", intents);
                        break;
                    case "fallback" :
                        this.setAttribute("fallback.intents", intents);
                        break;
                    case "happy" :
                        this.setAttribute("happy.intents", intents);
                        break;
                    case "restart" :
                        this.setAttribute("restart.intents", intents);
                        break;
                    default:
                        this.setAttribute("unhappy.intents", intents);
                        break;
                }
            },

            ...mapActions('chatbots', ['setItemAttribute', 'addItem']),
            ...mapActions('changeTracking', ['makeChanges'])
        }
    };
</script>

<style>
    .v-expansion-panel.flat:before {
        box-shadow: none;
    }
    .v-expansion-panel.flat .v-expansion-panel-content__wrap {
        padding: 0 !important;
    }
</style>

<style scoped>
    tr {
        cursor: pointer;
    }
</style>
