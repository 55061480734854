<template>
    <v-dialog
            :value="model"
            width="750"
            :scrollable="true"
            :persistent="true"
    >
        <v-card>
            <v-card-title class="headline">
                {{ $t('intents.chatbot.elements.chatbotStatements') }}
            </v-card-title>

            <v-divider/>

            <v-card-text class="pt-4">
                <v-form ref="form">
                    <v-alert type="info">
                        {{ $t('intents.chatbot.elements.enterAlternativeSentences') }} "{{msg.replace("*", "")}}".
                    </v-alert>

                    <h4>{{ $t('intents.chatbot.elements.mainSentence') }}</h4>

                    <div class="d-flex" v-if="mainIntent">
                        <v-text-field
                                v-model.trim="mainIntent.text"
                                :placeholder="placeholder(0)"
                                :rules="rules.requiredRules"
                                @keyup="addNewIntentIfNecessary()"
                                @keydown.enter="jumpToIntent(1)"
                                autofocus
                        ></v-text-field>
                    </div>

                    <h4>{{ $t('intents.chatbot.elements.enterAlternativeVariations', [intentLimit - 1]) }}</h4>

                    <div v-for="(intent, i) in alternativeIntents" :key="i" class="d-flex">
                        <v-text-field
                                ref="intent"
                                v-model.trim="intent.text"
                                :placeholder="placeholder(i + 1)"
                                @keyup="addNewIntentIfNecessary()"
                                @keydown.enter="jumpToIntent(i + 2)"
                        ></v-text-field>
                        <v-icon
                                v-if="intentCount > intentLimit + 1"
                                color="gray"
                                @click="removeIntent(i + 1)"
                                class="ml-4"
                        >
                            mdi-close
                        </v-icon>
                    </div>
                </v-form>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn right color="dark" text @click="close">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn v-if="currentUserCanEdit('chatbots')" color="primary" @click="save">{{ $t('buttons.ok') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapGetters} from "vuex";
    import {isEmpty} from "../filters/isEmpty";
    import {config} from "../config";

    export default {
        name: "IntentsChatbots",

        props: {
            model: {type: Boolean, default: false},
            intents: {type: Array, default: () => []},
            msg: {type: String, required: true},
        },

        data() {
            return {
                modifiedIntents: [],
            }
        },

        watch: {
            model: function(val) {
                if (val) {
                    this.modifiedIntents = this.prepareIntents();
                }
            }
        },

        computed: {
            intentLimit() {
                return config.chatbotIntentLimit;
            },
            intentCount() {
                return this.modifiedIntents.length;
            },
            mainIntent() {
                return this.modifiedIntents.find(i => i.main);
            },
            alternativeIntents() {
                return this.modifiedIntents.filter(i => !i.main);
            },

            /**
             * Returns intents that are not empty after trimming whitespaces.
             */
            validIntents() {
                return this.modifiedIntents.filter(i => i.text.trim() !== "");
            },

            rules() {
                return {
                    requiredRules: [ v => !isEmpty(v) || this.$t('rules.requiredItem')],
                }
            },

            placeholders() {
                return this.$t('intents.chatbot.elements.sentencePlaceholders');
            },

            ...mapGetters('chatbots', ['prepareNewIntent']),
            ...mapGetters('user', ['currentUserCanEdit']),
        },

        methods: {
            prepareIntents() {
                const intents = this.intents.map(i => ({...i})); // copy for editing
                const limit = intents.length < config.chatbotIntentLimit ? config.chatbotIntentLimit : intents.length + 1;

                intents.sort((a, b) => a.id - b.id);

                for (let i = intents.length; i < limit; i++) {
                    intents.push(this.prepareNewIntent());
                }

                if (intents.find(i => i.main) === undefined) {
                    intents[0].main = true;
                }

                return intents;
            },
            addNewIntent() {
                this.modifiedIntents.push(this.prepareNewIntent());
            },
            addNewIntentIfNecessary() {
                const noEmptyIntent = this.modifiedIntents.find(i => !i.text) === undefined;
                if (noEmptyIntent || this.modifiedIntents.length < config.chatbotIntentLimit) {
                    this.addNewIntent();
                }
            },
            placeholder(index) {
                if (index >= this.placeholders.length) {
                    return `${this.$t('intents.chatbot.elements.nextStatement')}...`;
                } else {
                    return this.placeholders[index];
                }
            },

            removeIntent(index) {
                this.modifiedIntents.splice(index, 1);
                this.addNewIntentIfNecessary();
            },
            jumpToIntent(index) {
                const ref = this.$refs.intent[index - 1];
                if (ref) {
                    ref.focus();
                } else {
                    this.addNewIntent();
                    this.$nextTick(() => this.jumpToIntent(index));
                }
            },
            save() {
                if (!this.mainIntent || isEmpty(this.mainIntent.text)) {
                    alert(this.$t('intents.chatbot.elements.enterMainSentenceAlert'));
                    return;
                }

                if (this.validIntents.length < config.chatbotIntentLimit) {
                    alert(this.$t('intents.chatbot.elements.enterMoreDifferentVariationsAlert'));
                    return;
                }
                
                this.$emit('updateIntents', this.validIntents);
                this.close();
            },
            close() {
                this.$emit('close');
            },
        }
    }
</script>

<style scoped>

</style>
