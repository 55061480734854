<template>
    <div>
        <v-layout class="pb-3" align-center>
            <h1>{{ $t('chatbotSetting.title') }}</h1>
        </v-layout>

        <Loading v-if="didLoadedData === false"></Loading>

        <div v-else>
            <v-data-table
                    :headers="headers"
                    :items="chatbots"
                    class="elevation-1"
                    :disable-pagination="true"
                    :hide-default-footer="true"
                    :disable-sort="true"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                    <tr v-if="items.length===0"><td colspan="5">{{ $t('elements.noData') }}</td></tr>
                    <tr v-else v-for="item in items" :key="item.id" v-on:click="$router.push('/chatbots/' + item.id)">
                        <td><span :class="`icon-flag ${item.language}`"></span> <b>{{localizedTitleChatbot(item.language)}}</b></td>
                        <td class="text--secondary">{{ formatTimestamp(item.lastUpdate) }}</td>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Loading from "../components/Loading";
    import moment from 'moment';

    export default {
        components: {Loading},

        mounted() {
            this.loadChatbots();
        },

        computed: {
             headers() {
                return  [
                    {
                        text: this.$t('chatbotSetting.table.title'),
                        align: 'left',
                        value: 'language',
                    },
                    {
                        text: this.$t('chatbotSetting.table.lastUpdate'),
                        align: 'left',
                        value: 'lastUpdate',
                    }
                ]
             },

            ...mapGetters('chatbots', ['chatbots', 'didLoadedData']),
        },

        methods: {
            localizedTitleChatbot(language) {
                const languageName = this.$options.filters.languageName(language);
                return `${this.$t('chatbotSetting.elements.assistant')} - ${languageName}`
            },

            formatTimestamp(timestamp) {
                if (timestamp === null || timestamp === undefined || timestamp === 0) {
                    return "-";
                } else {
                    return moment(timestamp).format("lll");
                }
            },
            ...mapActions('chatbots', ['loadChatbots']),
        },


    };
</script>

<style scoped>
    tr {
        cursor: pointer;
    }
</style>
