export default {
    buttons: {
        login: 'Přihlásit se',
        changePass: 'Změnit heslo',
        logout: 'Odhlásit se',
        enter: 'Vstoupit',
        new: 'Nový',
        delete: 'Smazat',
        close: 'Zavřít',
        save: 'Uložit',
        hide: 'Skrýt',
        back: 'Zpět',
        cancel: 'Zrušit',
        saving: 'Ukládání',
        saved: 'Uloženo',
        error: 'Chyba',
        ok: 'OK'
    },
    rules: {
        required: 'Povinné',
        requiredItem: 'Povinná položka',
        maxChars: 'Povoleno max. {0} znaků',
        wrongPhoneFormat: 'Špatný formát nebo neplatná předvolba! Příklad: +420 732 298 029',
        wrongEmailFormat: 'Špatný formát! Příklad: name@company.com',
        wrongUrlFormat: 'Špatný formát! Příklad: http://google.com',
        wrongCoordinateFormat: 'Špatný formát! Příklad: 49.1524345',
        requiredBothCoordinates: 'Musíte vyplnit obě souřadnice!',
        passwordMinChars: 'Heslo musí mít minimálně {0} znaků!',
        passwordsDontMatch: 'Hesla se neshodují.',
        fewQuestions: 'Málo otázek!',
        wrongImageFormat: 'Obrázek musí být v {0} formátu!'
    },
    elements: {
        noData: 'Žádná data',
        noTitle: 'Bez názvu',
        none: 'Žádné',
        next5: 'dalších',
        next1: 'další',
        noDescription: 'Bez popisu',
        saveErrorAlert: 'Chyba při ukládání!',
        deleteErrorAlert: 'Chyba při mazání!',
        addErrorAlert: 'Chyba při přidávání!',
        leaveWithoutSaveConfirm: 'Opravdu odejít bez uložení změn?',
        leaveWithoutSaveConfirm2: 'Neuložil jste změny! Opravdu odejít?',
        invalidFormAlert: 'Některá povinná pole nejsou vyplněna nebo je někde špatný formát! Prosím, zkontrolujte všechny položky.',
        reallyPerformThisAction: 'Opravdu provést tuto akci?',
        areYouSureToPerformThisAction: "Jste si jistý, že chcete provést tuto akci?",
        loading: "Načítání...",
    },
    langs: {
        en: "angličtina",
        ru: "ruština",
        uk: "ukrajinština"
    },
    overview: {
        title: 'Přehled'
    },
    tutorials: {
        title: 'Tutoriály',
        description: 'Správa FAQ a tutoriálů pro mobilní aplikaci.',
        buttons: {
            makeVisible: 'Zviditelnit',
        },
        elements: {
            languageName: 'Název',
            searchPlaceholder: 'Hledat',
            withoutTranslation: 'Bez překladu',
            tutorialWithoutTags: 'Tutoriál nemá tagy',
            noTagsChatbotWarning: 'Tutorial není označen tagem, což muže ovlivnit činnost chatbotu',
            withoutTag: 'Bez tagu',
            deleteTutorialsConfirm: 'Opravdu chcete smazat {0} tutoriálů a všechny jejich zanořené položky?'
        },
        table: {
            title: 'Název',
            tags: 'Tagy',
            lastUpdate: 'Upraveno'
        }
    },
    tutorialDetail: {
        tutorialAdding: 'Přidání tutoriálu',
        buttons: {
            deleteTranslation: 'Smazat překlad',
            deleteWholeTutorial: 'Smazat celý tutoriál',
            addSolutionStep: 'Přidat krok řešení',
        },
        elements: {
            solutionProcedure: 'Postup řešení',
            noSolution: 'Žádný postup.',
            deleteWholeTutorialConfirm: 'Opravdu odstranit celý tutoriál "{0}" a všechny jeho zanořené položky?',
            deleteWholeTutorialAndTransConfirm: 'Opravdu odstranit celý tutoriál "{0}", všechny jeho zanořené položky a všechny jeho překlady?',
            deleteTutorialLanguageConfirm: 'Opravdu odstranit jazyk {0} u tutoriálu "{1}"? Dojde k odstranění včetně zanořených položek. Ostatní jazykové verze zůstanou.',
            saveTutorialFirstAlert: 'Nejprve prosím tutoriál uložte!',
            saveChangesFirstAlert: 'Nejprve prosím uložte změny.',
        },
        item: {
            elements: {
                questions: 'Otázky',
                language: 'Jazyk',
                hidden: 'Skrytý',
                tags: 'Tagy',
                userQuestions: 'Otázky uživatele',
                buttonText: 'Text tlačítka',
                tutorialName: 'Název',
                itemText: 'Text',
                help: 'Nápověda',
                title: 'Nadpis',
                warning: 'Varování',
                sources: 'Zdroj informací',
                source: 'Zdroj',
                contact: 'Kontakt',
                followUpItems: 'Navazující položky',
                followUpQuestion: 'Navazující otázka',
                saveChangesBeforeAddingNewItemConfirm: 'Tutoriál není uložený a proto nelze přidat zanořenou položku. Přejete si nyní uložit všechny změny?',
                deleteItemAndNestedItemsConfirm: 'Opravdu odstranit položku "{0}" a všechny položky v ní zanořené?',
            },
            buttons: {
                addFollowUpItem: 'Přidat navazující položku'
            }
        },
        translate: {
            title: 'Překlad tutoriálu',
            description: 'Tento tutorial bude automaticky přeložen do zvoleného jazyku pomocí Google Translate a nezaručuje se správný překlad. Po přeložení je nutné tutoriál projít a zkontrolovat. Zvolte prosím cílový jazyk.',
            selectTargetLang: 'Vyberte cílový jazyk',
            buttons: {
                translate: 'Přeložit'
            }
        }
    },
    tags: {
        title: 'Tagy',
        description: 'Správa kategorií pro tutoriály.',
        buttons: {
            order: 'Pořadí',
        },
        elements: {
            noTag: 'Žádný tag.',
            deleteTagsConfirm: 'Opravdu chcete smazat {0} tagů? Tagy se odeberou i od všech tutoriálů.'
        },
        ordering: {
            title: 'Pořadí tagů'
        },
        dialog: {
            elements: {
                newTag: 'Nový tag',
                editTag: 'Úprava tagu',
                svgImage: 'Obrázek (formát: SVG)',
                deleteTagConfirm: 'Opravdu smazat tag "{0}"? Tag se odebere i od všech tutoriálů.',
                localizedDescription: 'Popis',
                errorWhileImageUploadAlert: 'Chyba při nahrávání obrázku! Kontaktujte prosím správce'
            }
        }
    },
    contacts: {
        title: 'Kontakty',
        description: 'Adresář kontaktů. Kontakty lze přiřazovat k tutoriálům.',
        elements: {
            noContact: 'Žádné kontakty',
            errorWhileLoadingUsageAlert: 'Chyba při načítání využití kontaktu!'
        },
        table: {
            name: 'Jméno',
            phone: 'Telefon',
            email: 'E-mail',
            address: 'Adresa'
        }
    },
    contactDetail: {
        buttons: {
            deleteContact: 'Smazat kontakt',
            withoutDetermination: 'BEZ URČENÍ',
            useAddress: 'Použít adresu',
            useMapCenter: 'Použít střed mapy'
        },
        elements: {
            newContact: 'Nový kontakt',
            editContact: 'Úprava kontaktu',
            howToContact: 'Jak je mohu kontaktovat?',
            whereOrganizationIs: 'Kde organizace sídlí?',
            saveWithoutPositionConfirm: 'Uložit kontakt bez pozice na mapě?',
            saveWithoutPositionMessage: 'Nemáte zadané místo na mapě, kde se kontakt nachází. Tento kontakt nebude na mapě kontaktů zobrazen.',
            validFormatFor: 'Validní formát pro',
            validEmergencyNumber: 'Validní tísňové číslo',
            invalidFormAlert: 'Některá pole nebo pozice kontaktu nejsou správně vyplněna! Prosím, překontrolujte je.',
            addressNotFoundAlert: 'Zadaná adresa nebyla nalezena. Zkuste ji prosím uvést v jiném formátu.',
            localizedName: 'Jméno',
            localizedDescription: 'Popis'
        },
        card: {
            address: 'Adresa',
            phone: 'Telefon',
            email: 'E-mail',
            web: 'Web',
        },
        deleteDialog: {
            elements: {
                wantToDeleteContacts: 'Opravdu smazat následující kontakty?',
                contactIsNotAssigned: 'Kontakt není přiřazen k žádné položce.',
                contactIsAssignedAttention: 'Pozor! Tento kontakt je přiřazen k následujícím tutoriálům. Jeho smazáním se tento kontakt odebere i od těchto položek.',
            }
        },
        chooseContact: {
            elements: {
                selectContact: 'Vyberte kontakt',
                noContact: 'Žádné kontakty.',
                searchContact: 'Hledat kontakt'
            }
        }
    },
    reports: {
        title: 'Hlášení problémů',
        description: 'Hlášení chyb v tutoriálech od uživatelů.',
        buttons: {
            new: 'Nové',
        },
        elements: {
            newReport: 'Nové hlášení',
            editReport: 'Úprava hlášení',
            relatedTutorial: 'Související tutoriál',
            noReportForTutorial: 'K tomuto hlášení není navázán žádný tutoriál.',
            newReports: 'Nové',
            archivedReports: 'Archivované',
            noReport: 'Žádné hlášení',
            loadingReports: 'Načítání reportů',
            itemsPerPage: 'Položek na stránku',
            deleteReportConfirm: 'Opravdu smazat toto hlášení?',
            deleteReportsConfirm: 'Opravdu smazat {0} vybraná hlášení?',
            none: 'Žádný'
        },
        form: {
            description: 'Popis',
            note: 'Poznámka',
            archived: 'archivované'
        },
        table: {
            tutorial: 'Tutoriál',
            problemDesc: 'Popis problému',
            note: 'Poznámka'
        }
    },
    chatbotSetting: {
        title: 'Nastavení asistentky',
        description: 'Zde můžete nastavit, jak se asistentka má vyjádřit na obecné otázky. Definujete tak její osobnost.',
        elements: {
            assistant: 'Asistentka'
        },
        table: {
            title: 'Název',
            lastUpdate: 'Upraveno'
        },
    },
    chatbotDetail: {
        elements: {
            mainParams: 'Hlavní parametry',
            parametersExplanation: 'Hlavní parametry slouží pro nastavení odpovědí asistenta v rámci základní konverzace. Každý parametr musí mít alespoň 2 varianty odpovědi. Čím více alternativ uvedete, tím více bude asistentka působit lidsky.',
            unusualQuestions: 'Netradiční otázky',
            unusualQuestionsExplanation: 'Netradiční otázky slouží pro případy, kdy uživatel napíše zprávu která nemá nic společného s tutoriály, např. uživatelé se často ze srandy ptají chatbota, zda je člověk, odkud pochází nebo jak je starý. Čím více uvedete netradičních otázek, tím bude chatbot působit více lidsky a diskuze s ním bude příjemnější.',
            requireMinSentencesAlert: 'Všechny položky musí mít alespoň {0} varianty vět. Chybí další varianty u "{1}".',
            requireMultipleVariantsForUnusual: 'Zadejte prosím alespoň 2 varianty u každé sekce netradiční otázky. Otázka "{0}" má málo variant.',
            noQuestion: 'Bez otázky',
            noAnswer: 'Bez odpovědi'
        },
        buttons: {
            addUnusualQuestion: 'Přidat netradiční otázku'
        },
        intents: {
            fullGreet: "Dlouhý pozdrav",
            shortGreet: "Krátký pozdrav",
            goodbye: "Rozloučení",
            fallback: "Neporozumění",
            happy: "Přijetí",
            unhappy: "Zamítnutí",
            restart: "Restart",
        }
    },
    intents: {
        questions: {
            elements: {
                unusualQuestion: 'Netradiční otázka',
                enterMultipleVariants: 'Vyplňte co nejvíce variant, jak se uživatel může tázat na danou věc. Poté uveďte několik variant, jak může chatbot reagovat. Čím více variant uvedete, tím bude lépe chatbot fungovat. Na pořadí nezáleží.',
                questions: 'Otázky',
                enterMultipleVariants2: 'Zadejte alternativy, jak uživatel může vyjádřit svůj problém související s tímto tutoriálem. Čím více variant uvedete, tím bude lépe chatbot fungovat.',
                score: 'Skóre',
                mainQuestion: 'Hlavní otázka (použije se jako název)',
                alternativeQuestionWording: 'Alternativní znění otázky (min. {0})',
                mainAnswer: 'Hlavní odpověď',
                alternativeAnswerWording: 'Alternativní znění odpovědi (min. {0})',
                questionPlaceholders: [
                    "Kdo jsi?",
                    "Jste skutečný člověk?",
                    "Jste robot?",
                ],
                questionPlaceholders2: [
                    "Jak mohu požádat o vízum?",
                    "Potřebuji vízum. Můžete mi pomoci?",
                    "Jaké jsou požadavky pro získání víza?",
                ],
                answerPlaceholders: [
                    "Jsem elektronický mozek, který dokáže reagovat jako člověk, ale efektivněji.",
                    "Jsem stroj a jsem na to hrdý.",
                    "Jsem chatbot a jsem tu, abych vám pomohl",
                ],
                nextQuestion: 'Další otázka',
                nextAnswer: 'Další odpověď',
                deleteQuestionConfirm: 'Opravdu chcete odstranit otázku?',
                enterMainQuestionAlert: 'Uveďte prosím hlavní otázku. Bez ní není možné změny uložit.',
                enterMainAnswerAlert: 'Uveďte prosím hlavní odpověď. Bez ní není možné změny uložit.',
                enterMoreDifferentQuestionsAlert: 'Uveďte prosím více rozdílných otázek, aby bylo možné správně natrénovat chatbota. Skóre kvality musí být alespoň 30%.',
                enterMoreAnswersAlert: 'Uveďte prosím více odpovědí, aby chatbot reagoval přirozeněji.',
                reformulateSimilarSentence: 'Věta je podobná {0}. větě. Použijte prosím jinou formulaci.'
            }
        },
        chatbot: {
            elements: {
                chatbotStatements: 'Vyjádření asistenta',
                enterAlternativeSentences: 'Zadejte alternativy vět, jak se má asistent vyjadřovat při události',
                mainSentence: 'Hlavní věta',
                enterAlternativeVariations: 'Alternativní věty (slouží jako variace hlavních vět, min. {0})',
                sentencePlaceholders: [
                    "Dobrý den, jsem bot a jsem tu, abych vám pomohl.",
                    "Dobrý den, jsem bot a pravděpodobně potřebujete pomoc?",
                    "Dobrý den, jsem bot a jsem tu, abych vám pomohl",
                ],
                nextStatement: 'Další vyjádření',
                enterMainSentenceAlert: 'Uveďte prosím hlavní větu. Bez ní není možné změny uložit.',
                enterMoreDifferentVariationsAlert: 'Uveďte prosím více variant, jak má chatbot reagovat.',
            }
        }
    },
    users: {
        title: 'Uživatelé',
        description: 'Nastavte, kdo má přístup do administrace, nebo změňte uživateli heslo.',
        table: {
            noUsers: 'Žádní uživatelé',
            name: 'Jméno',
            login: 'Přihlašovací jméno',
            role: 'Role'
        },
        elements: {
            deleteConfirm: 'Opravdu chcete smazat {0} označených uživatelů?'
        }
    },
    userDetail: {
        newUser: 'Nový uživatel',
        editUser: 'Úprava uživatele',
        passwordChange: 'Změna hesla',
        buttons: {
            deleteUser: 'Smazat uživatele',
            changePass: 'Změnit heslo',
            dontChangePass: 'Neměnit heslo'
        },
        form: {
            firstLastName: 'Jméno a příjmení',
            login: 'Přihlašovací jméno',
            role: 'Role',
            password: 'Heslo',
            passwordAgain: 'Heslo znova',
            oldPassword: 'Staré heslo',
            newPassword: 'Nové heslo',
            newPasswordConfirm: 'Potvrzení nového hesla'
        },
        roles: {
            admin: 'Admin (spravuje vše vč. uživatelů)',
            editor: 'Editor (spravuje vše kromě uživatelů)',
            noRole: 'Bez role (může pouze zobrazovat)'
        },
        elements: {
            cantDeleteYourselfAlert: 'Nemůžete smazat sebe sama!',
            deleteUserConfirm: 'Opravdu smazat uživatele {0}?',
            wrongOldPasswordAlert: 'Zadali jste nesprávné staré heslo!',
            cannotLostLastAdminAlert: 'Nemůžete se zbavit role admin, protože v aplikace není žádný jiný admin!',
            usernameAlreadyExists: 'Nelze upravit uživatele, protože jiný uživatel se stejným přihlašovacím jménem již existuje!'
        }
    },
    login: {
        title: 'Přihlášení',
        form: {
            login: 'Přihlašovací jméno',
            password: 'Heslo'
        },
        elements: {
            errorWhileLoginAlert: 'Nastal problém s přihlášením. Zkontrolujte své uživatelské jméno a heslo.'
        }
    },
    home: {
        title: 'Správa dat pro mobilní aplikaci Chytrá migrace'
    },
    userMessages: {
        title: 'Otázky uživatelů',
        description: "Uživatelovy otázky položené asistentce.",
        obtained: " (získané při konverzaci s asistentkou)",
        uncategorizedMessagesAlert: "Pozor! Máte {0} otázek, které uživatelé často kladou a na které není k dispozici odpověď:",
        more: "dalších",
        elements: {
            searchPlaceholder: "Hledat tutoriál",
            noMessages: "Žádné otázky",
            uncategorizedMessages: "Nezařazené zprávy",
        },
        filter: {
            messages: "Vyfiltrovat zprávy:",
            sortMessages: "Seřadit zprávy podle:",
            sortTutorials: "Seřadit tutoriály podle:",
            uncategorized: "Nezařazené",
            moreThan: "Více než",
            title: "Název",
            count: "Počet",
            invalidInput: "Pouze kladná čísla",
        },
        assignMessageDialog: {
            title: "Přiřazení otázek k tutoriálu",
            cancel: "Zrušit",
            assign: "Přiřadit",
        }

    },
    statistics: {
        title: 'Statistiky tutoriálů',
        description: "Statisiky tutoriálů, které se dají porovnávat mezi sebou.",
        elements: {
            noStatistics: "Žádné tutoriály",
            mostHelpfulTitle: "Nejužitečnější tutoriály",
            leastHelpfulTitle: "Nejméně užitečné tutoriály",
            mostUnfinishedTitle: "Nejčastěji nedokončené konverzace",
            selectAll: "Označit vše",
            sortTutorialsBy: "Seřadit tutoriály podle:",
            showMore: "Více",
            maxCount: "Maximální počet:",
        },
        graphElements: {
            helpfulFeedback: "Užitečné",
            notHelpfulFeedback: "Neužitečné",
            continued: "Pokračování",
            unfinished: "Nedokončené",
            compare: "Porovnat",
            reset: "Obnovit",
        }
    }
}