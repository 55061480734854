import axios from 'axios';
import { config, languages } from './../../config';
import { normalize, schema } from 'normalizr';
import Vue from "vue";
import cloneDeep from "clone-deep";
import { localizedObject } from '../../filters/localized';
import i18n from '@/plugins/i18n/i18n';

const state = {
    didLoadedData: false,
    tags: []
};

const tag = new schema.Entity('tags');

const getters = {
    tags: state => Object.values(state.tags).sort((a, b) => a.order < b.order ? -1 : 1),
    tagCount: state => Object.keys(state.tags).length,
    localizedTags: (state, getters) => language =>
        getters.tags.map(t => localizedObject(t, language)),
    maxOrder: state => Object.values(state.tags).reduce((a, v) => v.order > a ? v.order : a, 0),
    emptyTag: (state, getters) => {
        const localizedAttributes = {};

        languages.forEach(l => localizedAttributes[l] = {
            name: "",
        });

        return {
            color: null,
            localizedAttributes: localizedAttributes,
            order: getters.maxOrder + 1,
        };
    },
    colors: () => ["FF5722", "FF9800", "f44336", "E91E63", "9C27B0", "673AB7", "3F51B5", "2196F3", "03A9F4", "00BCD4", "009688", "4CAF50", "8BC34A", "795548", "9E9E9E", "607D8B"],
    didLoadedData: state => state.didLoadedData,
    imageSrc: () => (tag) => `${config.serverUrl}faq/tags/${tag.id}/photo?version=${Math.random()}`, // added some random to prevent caching
};

const actions = {

    async loadTags({ commit }, onComplete = () => { }) {
        commit('didLoadedData', false);
        axios.get(config.serverUrl + 'faq/tags/')
            .then((response) => {
                commit('setTags', response.data.items);
                commit('didLoadedData', true);
                onComplete();
            });
    },

    prepareNewTag({ getters }) {
        return cloneDeep(getters.emptyTag);
    },

    async createTag({ commit }, tag) {
        try {
            const response = await axios.post(config.serverUrl + 'faq/tags/', tag)
            const createdTag = response.data.content;
            commit('addTag', createdTag);
            return createdTag;
        } catch (e) {
            console.error(e);
            alert(i18n.t('elements.addErrorAlert'));
        }
    },

    prepareTagForEdit(_, tag) {
        // make a copy
        let copy = cloneDeep(tag);

        // fix missing languages
        languages.forEach(language => {
            if (copy.localizedAttributes[language] === undefined) {
                copy.localizedAttributes[language] = { name: "" };
            }
        });

        return copy;
    },

    async updateTag({ commit }, tag) {
        const id = tag.id;

        try {
            const response = await axios.put(config.serverUrl + `faq/tags/${id}`, tag);
            const updatedTag = response.data.content;
            commit('updateTag', updatedTag);
            return updatedTag;
        } catch (e) {
            console.error(e);
            alert(i18n.t('elements.saveErrorAlert'));
        }
    },

    async deleteTag({ dispatch }, tagId) {
        await dispatch('deleteTags', [tagId]);
    },

    async deleteTags({ commit }, tagIds) {
        commit('didLoadedData', false);
        try {
            await Promise.all(tagIds.map(async id => {
                await axios.delete(config.serverUrl + `faq/tags/${id}`);
                commit('deleteTag', id);
            }));
            commit('didLoadedData', true);
        } catch (e) {
            console.error(e);
            alert(i18n.t('elements.deleteErrorAlert'));
        }
    },

    async uploadImage({ getters }, { tag, image }) {
        let formData = new FormData();
        formData.append('file', image, image.name);

        try {
            const options = { headers: { 'content-type': 'multipart/form-data' } };
            await axios.post(getters.imageSrc(tag), formData, options);
        } catch (e) {
            console.error(e);
            alert(i18n.t('tags.dialog.elements.errorWhileImageUploadAlert'));
        }
    },

    async deleteImage({ getters }, tag) {
        try {
            await axios.delete(getters.imageSrc(tag));
        } catch (e) {
            console.error(e);
            alert(i18n.t('elements.deleteErrorAlert'));
        }
    },

};

const mutations = {
    didLoadedData(state, loaded) {
        state.didLoadedData = loaded;
    },

    setTags(state, tags) {
        const normalized = normalize(tags, [tag]);
        if (normalized.entities !== undefined && normalized.entities.tags !== undefined) {
            state.tags = normalized.entities.tags;
        } else {
            state.tags = {};
        }
    },

    addTag(state, tag) {
        Vue.set(state.tags, tag.id, tag);
    },

    updateTag(state, tag) {
        Vue.set(state.tags, tag.id, tag);
    },

    deleteTag(state, id) {
        Vue.delete(state.tags, id);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
