import axios from "axios";
import {config} from "@/config";

const state = {
    didLoadedData: false,
    didLoadedMostHelpful: false,
    didLoadedMostNotHelpful: false,
    didLoadedMostUnfinished: false,

    statistics: [],
    mostHelpfulTutorials: [],
    mostNotHelpfulTutorials: [],
    mostUnfinishedTutorials: [],
};

const getters = {
    didLoadedData: state => state.didLoadedData,
    didLoadedMostHelpful: state => state.didLoadedMostHelpful,
    didLoadedMostNotHelpful: state => state.didLoadedMostNotHelpful,
    didLoadedMostUnfinished: state => state.didLoadedMostUnfinished,

    statistics: (state) => {
        return state.statistics
    },

    mostHelpfulTutorials: (state) => {
        return state.mostHelpfulTutorials
    },

    mostNotHelpfulTutorials: (state) => {
        return state.mostNotHelpfulTutorials
    },

    mostUnfinishedTutorials: (state) => {
        return state.mostUnfinishedTutorials
    },
}

const actions = {
    async loadAllStatistics({commit}) {
        commit('didLoadedData', false)
        const response = await axios.get(config.serverUrl + 'chatbot/stats/frequency/')
        commit('setStatistics', response.data.items)
        commit('didLoadedData', true)
    },

    async loadMostHelpful({commit}) {
        commit('didLoadedMostHelpful', false)
        const response = await axios.get(config.serverUrl + `chatbot/stats/frequency/mostHelpful/${config.mostHelpfulTutorialsNumber}`)
        commit('setMostHelpful', response.data.items)
        commit('didLoadedMostHelpful', true)
    },

    async loadMostNotHelpful({commit}) {
        commit('didLoadedMostNotHelpful', false)
        const response = await axios.get(config.serverUrl + `chatbot/stats/frequency/mostNotHelpful/${config.mostNotHelpfulTutorialsNumber}`)
        commit('setMostNotHelpful', response.data.items)
        commit('didLoadedMostNotHelpful', true)
    },

    async loadMostUnfinished({commit}) {
        commit('didLoadedMostUnfinished', false)
        const response = await axios.get(config.serverUrl + `chatbot/stats/frequency/mostUnfinished/${config.mostUnfinishedTutorialsNumber}`)
        commit('setMostUnfinished', response.data.items)
        commit('didLoadedMostUnfinished', true)
    },

}

const mutations = {
    didLoadedData(state, loaded) {
        state.didLoadedData = loaded;
    },

    setStatistics(state, statistics) {
        state.statistics = statistics.map(freq => ({
            ...freq,
            isCompared: false,
        }))
    },

    setAllIsCompared(state, value){
      state.statistics = state.statistics.map(freq => ({
          ...freq,
          isCompared: value
      }))
    },

    setIsCompared(state, {faqItemId, value}){
        const index = state.statistics.findIndex(item => item.faqItemId === faqItemId);
        if(index !== -1) {
            state.statistics[index].isCompared = value
        }
    },

    setMostHelpful(state, mostHelpful) {
      state.mostHelpfulTutorials = mostHelpful
            .filter(item => item.helpfulFeedback !== 0)
            .sort((a, b) => {
                if(a.helpfulFeedback === b.helpfulFeedback) {
                    return a.faqItemName.localeCompare(b.faqItemName);
                }
            });
    },

    setMostNotHelpful(state, mostNotHelpful) {
      state.mostNotHelpfulTutorials = mostNotHelpful
            .filter(item => item.notHelpfulFeedback !== 0)
            .sort((a, b) => {
                if(a.notHelpfulFeedback === b.notHelpfulFeedback) {
                    return a.faqItemName.localeCompare(b.faqItemName);
                }
            });
    },

    setMostUnfinished(state, mostUnfinished) {
        state.mostUnfinishedTutorials = mostUnfinished
            .filter(item => item.unfinished !== 0)
            .sort((a, b) => {
                if(a.unfinished === b.unfinished) {
                    return a.faqItemName.localeCompare(b.faqItemName);
                }
            });
    },

    didLoadedMostHelpful(state, loaded) {
        state.didLoadedMostHelpful = loaded
    },

    didLoadedMostNotHelpful(state, loaded) {
        state.didLoadedMostNotHelpful = loaded
    },

    didLoadedMostUnfinished(state, loaded) {
        state.didLoadedMostUnfinished = loaded
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}