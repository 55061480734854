import axios from 'axios';
import {config, languages} from './../../config';
import {normalize, schema} from 'normalizr';
import Vue from "vue";
import {sanitize} from "../../utils/common";
import cloneDeep from "clone-deep";
import i18n from '@/plugins/i18n/i18n';

const state = {
    didLoadedData: false,
    contacts: {},
    contactUsages: {},
};

const contact = new schema.Entity('contacts');

const getters = {
    contactsMap: state => state.contacts,
    contacts: state => Object.values(state.contacts).sort((a, b) => sanitize(a.name) < sanitize(b.name) ? -1 : 1),
    contactById: state => id => state.contacts[id],
    matchingContacts: (state, getters) => query => 
        getters.contacts.filter(contact => {
            const attributes = [
                ...Object.values(contact.localizedAttributes).map(a => a.name),
                contact.email, 
                contact.phone, 
                contact.address, 
            ];

            const searchedString = sanitize(attributes.join(", "));
            const queryParts = sanitize(query).split(" ");

            return queryParts.every(part => searchedString.includes(part));
        }
    ),
    contactCount: state => Object.keys(state.contacts).length,
    emptyLocalizedAttributes: () => {
        const localizedAttributes = {};
        languages.forEach(l => localizedAttributes[l] = {
            name: "",
        });
        return localizedAttributes;
    },
    emptyContact: (state, getters) => {
        return {
            phone: null,
            email: null,
            address: null,
            latitude: null,
            longitude: null,
            localizedAttributes: {...getters.emptyLocalizedAttributes},
        };
    },
    didLoadedData: state => state.didLoadedData,
    contactUsageById: state => id => state.contactUsages[id],
};

const actions = {

    async loadContacts({commit}, onComplete = () => {}) {
        commit('didLoadedData', false);
        axios.get(config.serverUrl + 'contacts/')
            .then((response) => {
                commit('setContacts', response.data.items);
                commit('didLoadedData', true);
                onComplete();
            });
    },
    
    async loadContact({commit}, id) {
        commit('didLoadedData', false);
        const response = await axios.get(`${config.serverUrl}contacts/${id}`);
        commit('updateContact', response.data.content);
        commit('didLoadedData', true);
    },

    prepareNewContact({ commit, getters }) {
        commit('didLoadedData', true);
        return cloneDeep(getters.emptyContact);
    },

    async createContact({ commit }, { contact }) {
        try {
            const response = await axios.post(config.serverUrl + 'contacts/', contact);
            const createdContact = response.data.content;
            commit('addContact', createdContact);
            return createdContact;
        } catch(e) {
            console.error(e);
            alert(i18n.t('elements.addErrorAlert'));
        }
    },

    prepareContactForEdit({ getters }, contact) {
        // make a copy
        let copy = cloneDeep(contact);

        // fix missing languages
        languages.forEach(language => {
            if (copy.localizedAttributes[language] === undefined) {
                copy.localizedAttributes[language] = {...getters.emptyLocalizedAttributes};
            }
        });

        return copy;
    },

    async updateContact({ commit }, { contact }) {
        try {
            const response = await axios.put(config.serverUrl + `contacts/${contact.id}`, contact);
            const updatedContact = response.data.content;
            commit('updateContact', updatedContact);
            return updatedContact;
        } catch(e) {
            console.error(e);
            alert(i18n.t('elements.saveErrorAlert'));
        }
    },

    async deleteContacts({commit}, contacts) {
        try {
            await Promise.all(contacts.map(async contact => {
                await axios.delete(config.serverUrl + `contacts/${contact.id}`);
                commit('deleteContact', contact);
            }));
        } catch (e) {
            console.error(e);
            alert(i18n.t('elements.deleteErrorAlert'));
        }
    },

    async loadContactUsage({commit}, contact) {
        await axios.get(config.serverUrl + 'contacts/' + contact.id + '/usage')
            .then((response) => {
                commit('setContactUsage', response.data.content);
            })
            .catch(() => {
                alert(i18n.t('contacts.elements.errorWhileLoadingUsageAlert'));
            });
    },

    async loadContactsUsages({ dispatch }, contacts) {
        await Promise.all(contacts.map(contact => 
            dispatch('loadContactUsage', contact)
        ));
    },

};

const mutations = {
    didLoadedData (state, loaded) {
        state.didLoadedData = loaded;
    },

    setContacts(state, contacts) {
        const normalized = normalize(contacts, [contact]);
        if (normalized.entities !== undefined && normalized.entities.contacts !== undefined) {
            state.contacts = normalized.entities.contacts;
        } else {
            state.contacts = {};
        }
    },

    addContact(state, contact) {
        Vue.set(state.contacts, contact.id, contact);
    },

    updateContact(state, contact) {
        Vue.set(state.contacts, contact.id, contact);
    },

    deleteContact(state, contact) {
        Vue.delete(state.contacts, contact.id);
    },

    setContactUsage(state, usage) {
        Vue.set(state.contactUsages, usage.contactId, usage);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
