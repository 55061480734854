<template>
    <v-dialog :value="model" max-width="600px" persistent scrollable>
        <v-card v-if="tag">
            <v-card-title class="headline">
                <span v-if="isNew">{{ $t('tags.dialog.elements.newTag') }}</span>
                <span v-else>{{ $t('tags.dialog.elements.editTag') }}</span>
            </v-card-title>

            <v-divider/>

            <v-card-text class="pt-4">
                <div v-if="isSaving" class="pb-3">
                    <Loading></Loading>
                </div>
                <v-form v-else ref="form">
                    <v-text-field
                            v-for="(language, i) in languages"
                            :key="`name-${language}`"
                            :rules="[rules.required_rule, rules.max_length_rule]"
                            color="dark"
                            :label="localizedNameLabel(language)"
                            :autofocus="i === 0"
                            v-model.trim="editedTag.localizedAttributes[language].name"
                            @input="makeChanges"
                    ></v-text-field>
                    <v-radio-group
                            v-model="editedTag.color"
                            row
                            :rules="[rules.required_rule]"
                    >
                        <v-radio v-for="color in colors" :key="color" :value="'#' + color" @change="makeChanges">
                            <template v-slot:label>
                                <v-icon :color="'#' + color">label</v-icon>
                            </template>
                        </v-radio>
                    </v-radio-group>

                    <v-img 
                        v-if="hasImage && !removedOldImage" 
                        :src="imageSrc(editedTag)"
                        class="preview-image pointer"
                        contain 
                        height="250" 
                        @error="hasImage = false"
                        @click="openImage"
                    >
                        <v-btn dark fab small color="white" class="delete-image-button ma-3" @click.stop="removeOldImage">
                            <v-icon color="grey darken-1">mdi-delete</v-icon>
                        </v-btn>
                    </v-img>
                    <v-file-input 
                        v-else
                        v-model="newImage"
                        :rules="[rules.file_rule]"
                        prepend-icon="image"
                        truncate-length="15"
                        :label="$t('tags.dialog.elements.svgImage')"
                        @change="makeChanges"
                    ></v-file-input>

                </v-form>

            </v-card-text>

            <v-divider/>

            <v-card-actions v-if="!isSaving">
                <v-btn v-if="currentUserCanEdit('tags') && !isNew" color="error" text @click="attemptToDeleteTag">{{ $t('buttons.delete') }}</v-btn>
                <v-spacer/>
                <v-btn color="dark" text @click="closeDialog">{{ $t('buttons.close') }}</v-btn>
                <v-btn v-if="currentUserCanEdit('tags')" color="success" @click="save">{{ $t('buttons.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import Loading from "../components/Loading";
    import {languages} from "../config.js";

    export default {
        name: "TagDialog",
        components: {Loading},

        props: {
            model: {
                type: Boolean,
                default: false,
            },
            tag: {
                type: Object,
            },
        },

        data() {
            return {
                isSaving: false,
                hasImage: true,
                removedOldImage: false,
                newImage: null,
            }
        },

        watch: {
            tag(tag) {
                // reset form validation to prevent error messages in an empty form for a new tag
                if (tag && tag.id === undefined && this.$refs.form) {
                    this.$refs.form.resetValidation(); 
                }

                // reset image settings
                this.hasImage = true;
                this.removedOldImage = false;
                this.newImage = null;
            }
        },

        computed: {
            ...mapGetters('tags', ['colors', 'imageSrc']),
            ...mapGetters('changeTracking', ['hasChanges']),
            ...mapGetters('user', ['currentUserCanEdit']),

            isNew() { return this.editedTag.id === undefined; },

            languages() {
                return languages;
            },
          
            editedTag() {
                return {...this.tag};
            },

            rules() {
                return {
                    required_rule: v => typeof v === "string" && !!v.trim() || this.$t('rules.required'),
                    max_length_rule: v => (!v || v.length <= 255) || this.$t('rules.maxChars', [255]),
                    file_rule: v => !v || v.type === "image/svg+xml" || this.$t('rules.wrongImageFormat', ['SVG']),
                }
            }
        },

        methods: {
            ...mapActions('tags', ['createTag', 'updateTag', 'uploadImage', 'deleteImage', 'deleteTag']),
            ...mapActions('changeTracking', ['makeChanges', 'resolveChanges']),

            openImage() {
                const url = this.imageSrc(this.editedTag);
                window.open(url);
            },

            async save() {
                if (!this.$refs.form.validate()) {
                    return alert(this.$t('elements.invalidFormAlert'));
                }

                this.isSaving = true;
                const savedTag = this.isNew 
                    ? await this.createTag(this.editedTag)
                    : await this.updateTag(this.editedTag);

                if (this.newImage) {
                    await this.uploadImage({tag: savedTag, image: this.newImage});
                } else if (this.removedOldImage) {
                    await this.deleteImage(savedTag);
                }

                this.resolveChanges();
                this.closeDialog();
                setTimeout(() => this.isSaving = false, 500);
            },

            removeOldImage() {
                this.removedOldImage = true;
                this.makeChanges();
            },

            attemptToDeleteTag() {
                if (confirm(this.$t('tags.dialog.elements.deleteTagConfirm', [this.editedTag.name]))) {
                    this.deleteTag(this.editedTag.id);
                    this.closeDialog();
                }
            },

            closeDialog() {
                if (!this.hasChanges || confirm(this.$t('elements.leaveWithoutSaveConfirm'))) {
                    this.resolveChanges();
                    this.$emit('close');
                }
            },

            localizedNameLabel(language) {
                const languageName = this.$options.filters.languageName(language);
                return `${this.$t('tags.dialog.elements.localizedDescription')} (${languageName})`;
            },
        },
    }
</script>

<style scoped>
.preview-image {
    border: 1px solid gray;
}

.delete-image-button {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 2;
}
</style>