<template>
    <v-dialog :value="model" max-width="350px" persistent scrollable>
        <v-card v-if="isLoggedIn">
            <v-card-title class="headline">
                {{ $t('userDetail.passwordChange') }}
            </v-card-title>

            <v-divider/>

            <v-card-text class="pt-4">
                <div v-if="isSaving" class="pb-3">
                    <Loading></Loading>
                </div>
                <v-form v-else ref="form">
                  <v-text-field
                      :rules="[rules.required_rule]"
                      :label="$t('userDetail.form.oldPassword')"
                      v-model.trim="oldPassword"
                      type="password"
                      autocomplete="current-password"
                  ></v-text-field>
                  <v-text-field
                      :rules="[rules.required_rule, rules.password_rule, rules.max_length_rule]"
                      :label="$t('userDetail.form.newPassword')"
                      v-model.trim="newPassword"
                      type="password"
                      autocomplete="new-password"
                  ></v-text-field>
                  <v-text-field
                      :rules="[rules.required_rule, rules.confirm_password_rule]"
                      :label="$t('userDetail.form.newPasswordConfirm')"
                      v-model.trim="confirmNewPassword"
                      type="password"
                      autocomplete="new-password"
                  ></v-text-field>
                  <img src="/img/change-password.svg" alt="Decorative image: A person showing a laptop with a change password form" class="my-5 placeholder-image" />
                </v-form>

            </v-card-text>

            <v-divider/>

            <v-card-actions v-if="!isSaving">
                <v-spacer/>
                <v-btn color="dark" text @click="closeDialog">{{ $t('buttons.close') }}</v-btn>
                <v-btn color="success" @click="save">{{ $t('buttons.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import Loading from "../components/Loading";

    export default {
        name: "ChangePasswordDialog",
        components: {Loading},

        props: {
            model: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                isSaving: false,
                oldPassword: "",
                newPassword: "",
                confirmNewPassword: "",
            }
        },

        computed: {
            ...mapGetters('user', ['isLoggedIn']),

            rules() {
                return {
                    required_rule: v => typeof v === "string" && !!v.trim() || this.$t('rules.required'),
                    max_length_rule: v => (!v || v.length <= 255) || this.$t('rules.maxChars', [255]),
                    password_rule: v => (!v || v.length >= 8) || this.$t('rules.passwordMinChars', [8]),
                    confirm_password_rule: v => v === this.newPassword || this.$t('rules.passwordsDontMatch'),
                }
            }

        },

        methods: {
            ...mapActions('user', ['changePassword']),

            async save() {
                if (!this.$refs.form.validate()) {
                    return alert(this.$t('elements.invalidFormAlert'));
                }

                try {
                  this.isSaving = true;

                  await this.changePassword({
                    oldPassword: this.oldPassword,
                    newPassword: this.newPassword
                  });

                  this.closeDialog();
                  setTimeout(() => this.isSaving = false, 500);

                } catch (e) {
                  this.isSaving = false;
                }
            },

            closeDialog() {
                this.$emit('close');
            },
        },
    }
</script>

<style scoped>
.placeholder-image {
  width: 100%;
}
</style>