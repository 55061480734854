<template>
    <div>
        <floating-loading :shown="!didLoadedData"></floating-loading>

        <v-layout class="pb-3" align-center>
            <h1>{{ $t('users.title') }}</h1>
            <v-spacer></v-spacer>
            <v-btn v-if="currentUserCanEdit('users') && selected.length === 0" dark color="primary" :to="{name: 'userDetail', params: {id: 'new'}}">+ {{ $t('buttons.new') }}</v-btn>
            <v-btn v-else-if="currentUserCanEdit('users')" color="error" @click="deleteSelected">{{ $t('buttons.delete') }}</v-btn>
        </v-layout>

        <v-row>
            <v-col cols="12">
                <v-data-table
                        :headers="headers"
                        :items="tableData"
                        class="elevation-1 clickable-rows"
                        :sort-by.sync="$query.sort"
                        :sort-desc.sync="$query.desc"
                        :disable-pagination="true"
                        :hide-default-footer="true" 
                        :no-data-text="$t('users.table.noUsers')"
                        @click:row="item => $router.push({name: 'userDetail', params: {id: item.id}})"
                        show-select
                        v-model="selected"
                >
                    <template v-slot:[`item.name`]="{ item }" >
                      <b>{{ item.name }}</b>
                    </template>
                    <template v-slot:[`item.this`]="{ item }" >
                      <i>{{ item | userRoles | placeholder('-') }}</i>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>

</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import FloatingLoading from '../components/FloatingLoading.vue';

    export default {
        name: "Users",
        components: {FloatingLoading},

        mounted() {
            this.loadUsers();
        },

        data() {
            return {
                selected: [],
            }
        },

        computed: {
            ...mapGetters('users', ['users', 'emptyUser', 'didLoadedData']),
            ...mapGetters('user', ['currentUser', 'currentUserCanEdit']),

            tableData() {
                return this.users.map(user => ({
                  ...user,
                  isSelectable: user.id !== this.currentUser.id
                }))
            },

            headers() {
                return [
                    {text: this.$t('users.table.name'), align: 'left', value: 'name'},
                    {text: this.$t('users.table.login'), align: 'left', value: 'username'},
                    {text: this.$t('users.table.role'), align: 'left', value: 'this'},
                ]
            },
        },

        methods: {
            ...mapActions('users', ['loadUsers', 'prepareNewUser', 'deleteUsers']),

            async deleteSelected() {
                if (window.confirm(this.$t('users.elements.deleteConfirm', [this.selected.length]))) {
                    await this.deleteUsers(this.selected);
                    this.selected = [];
                }
            },
        },

    }
</script>

<style scoped>
    .clickable-rows >>> tbody tr {
        cursor: pointer;
    }
</style>