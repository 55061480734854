<template>
    <div>
        <floating-loading :shown="!didLoadedData"></floating-loading>

        <v-layout class="pb-3" align-center>
            <h1>{{ $t('contacts.title') }}</h1>
            <v-spacer></v-spacer>
            <v-btn v-if="currentUserCanEdit('contacts') && selected.length === 0" dark color="primary" :to="{name: 'contactDetail', params: {id: 'new'}}">+ {{ $t('buttons.new') }}</v-btn>
            <v-btn v-else-if="currentUserCanEdit('contacts')" color="error" @click="deleteSelected">{{ $t('buttons.delete') }}</v-btn>
        </v-layout>

        <v-row>
            <v-col cols="12">
                <v-data-table
                        :headers="headers"
                        :items="contacts"
                        class="elevation-1 clickable-rows"
                        :sort-by.sync="$query.sort"
                        :sort-desc.sync="$query.desc"
                        :disable-pagination="true"
                        :hide-default-footer="true" 
                        :no-data-text="$t('contacts.elements.noContact')"
                        @click:row="item => $router.push({name: 'contactDetail', params: {id: item.id}})"
                        show-select
                        v-model="selected"
                >
                    <template v-slot:[`item.name`]="{ item }" >
                        <b>{{ item | localized('name') }}</b>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <DeleteContactDialog 
            :model="deleteDialog" 
            :contacts="deletedContacts"
            @delete="selected = []"
            @close="closeDeleteDialog"
        />
    </div>

</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import DeleteContactDialog from "../components/DeleteContactDialog";
    import FloatingLoading from '../components/FloatingLoading.vue';

    export default {
        name: "contacts",
        components: {DeleteContactDialog, FloatingLoading},

        mounted() {
            this.loadContacts();
        },

        data() {
            return {
                deletedContacts: null,
                deleteDialog : false,
                selected: [],
            }
        },

        computed: {
            headers() {
                return [
                    {text: this.$t('contacts.table.name'), align: 'left', value: 'name'},
                    {text: this.$t('contacts.table.phone'), align: 'left', value: 'phone'},
                    {text: this.$t('contacts.table.email'), align: 'left', value: 'email'},
                    {text: this.$t('contacts.table.address'), align: 'left', value: 'address'},
                ]
            },
            
            ...mapGetters('contacts', ['contacts', 'emptyContact', 'didLoadedData']),
            ...mapGetters('user', ['currentUserCanEdit']),
        },

        methods: {
            ...mapActions('contacts', ['loadContacts', 'prepareNewContact']),

            deleteSelected() {
                this.deletedContacts = this.selected;
                this.deleteDialog = true;
            },
                
            closeDeleteDialog() {
                this.deleteDialog = false;
            },
},

    }
</script>

<style scoped>
    .clickable-rows >>> tbody tr {
        cursor: pointer;
    }
</style>