<template>
    <v-dialog v-model="model" max-width="600px" persistent scrollable>
        <v-card v-if="orderingTags.length">
            <v-card-title class="headline">
                <span>{{ $t('tags.ordering.title') }}</span>
            </v-card-title>

            <v-divider/>

            <v-card-text class="pt-4">
                <div v-if="isSaving" class="pb-3">
                    <Loading></Loading>
                </div>
                <div v-else>
                    <SlickList 
                        lockAxis="y"
                        v-model="orderingTags" 
                        :useDragHandle="true" 
                        id="tags-ordering" 
                        appendTo="#tags-ordering"
                        helperClass="dragged-item"
                        @input="makeChanges"
                    >
                        <SlickItem v-for="(tag, index) in orderingTags" :index="index" :key="tag.id">
                            <strong>{{ index + 1 }}.</strong>
                            <v-chip
                                class="ma-1 ml-8 pointer"
                                :color="tag.color"
                                label
                                text-color="white"
                            >
                                {{ tag.name }}
                            </v-chip>
                            <v-icon v-handle class="drag-handle" size="20">
                                drag_handle
                            </v-icon>
                        </SlickItem>
                    </SlickList>
                </div>
            </v-card-text>

            <v-divider/>

            <v-card-actions v-if="!isSaving">
                <v-spacer/>
                <v-btn color="dark" text @click="closeDialog">{{ $t('buttons.close') }}</v-btn>
                <v-btn v-if="currentUserCanEdit('tags')" color="success" @click="save">{{ $t('buttons.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort';
    import cloneDeep from "clone-deep";
    import Loading from "../components/Loading";

    export default {
        name: "TagsOrderingDialog",
        components: {
            Loading,
            SlickItem,
            SlickList,
        },

        directives: { handle: HandleDirective },

        props: {
            model: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                isSaving: false,
                orderingTags: []
            }
        },

        computed: {
            ...mapGetters('tags', ['tags']),
            ...mapGetters('changeTracking', ['hasChanges']),
            ...mapGetters('user', ['currentUserCanEdit']),
        },

        methods: {
            ...mapActions('tags', ['updateTag']),
            ...mapActions('changeTracking', ['makeChanges', 'resolveChanges']),

            async save() {
                this.isSaving = true;

                const tagsWithNewOrder = this.orderingTags
                    .map((tag, index) => ({ ...tag, newOrder: index }))
                    .filter(tag => tag.order !== tag.newOrder)
                    .map(tag => ({ ...tag, order: tag.newOrder }));

                const promises = tagsWithNewOrder.map(this.updateTag);
                await Promise.allSettled(promises)

                this.resolveChanges();
                this.closeDialog();
                setTimeout(() => this.isSaving = false, 500);
            },


            closeDialog() {
                if (!this.hasChanges || confirm(this.$t('elements.leaveWithoutSaveConfirm'))) {
                    this.resolveChanges();
                    this.$emit('close');
                }
            },
        },

        watch: {
            model(isOpened) {
                if (isOpened) {
                    // clone tags from store to local state for future ordering
                    this.orderingTags = cloneDeep(this.tags);
                }
            }
        }
    }
</script>

<style>

.drag-handle {
    cursor: grab;
    float: right;
    line-height: 2 !important;
}

.dragged-item .drag-handle {
    pointer-events: auto !important;
    cursor: grabbing;
}

</style>