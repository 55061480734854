import axios from 'axios';
import {config} from '@/config';
import {normalize, schema} from 'normalizr';

const state = {
    didLoadedData: false,
    entitiesStatistics: []
};

const entityStatistics = new schema.Entity('entitiesStatistics', {}, {idAttribute: 'name'});

const getters = {
    entitiesStatistics: state => state.entitiesStatistics,
    didLoadedData: state => state.didLoadedData
};

const actions = {

    async loadEntitiesStatistics({commit}) {
        commit('didLoadedData', false);
        const response = await axios.get(config.serverUrl + 'dashboard/entities-statistics');
        commit('setEntitiesStatistics', response.data.items);
        commit('didLoadedData', true);
    },

};

const mutations = {
    didLoadedData (state, loaded) {
        state.didLoadedData = loaded;
    },

    setEntitiesStatistics(state, entitiesStatistics) {
        const normalized = normalize(entitiesStatistics, [entityStatistics]);
        if (normalized.entities !== undefined && normalized.entities.entitiesStatistics !== undefined) {
            state.entitiesStatistics = normalized.entities.entitiesStatistics;
        } else {
            state.entitiesStatistics = {};
        }
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
