const state = {
    previousRoute: null
};

const getters = {
    previousRoute(state) {
        return state.previousRoute;
    }
};

const actions = {

    recordRoute({ commit }, route) {
        commit('setPreviousRoute', route);
    },

    clear({ commit }) {
        commit('setPreviousRoute', null);
    },

};

const mutations = {

    setPreviousRoute(state, route) {
        state.previousRoute = route;
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
