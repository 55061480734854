<template>
    <v-app id="inspire">
        <v-content>
            <Loading v-if="isBeingLoggedIn"></Loading>
            <v-container v-else fluid>
                <v-layout
                        align-center
                        justify-center
                >
                    <v-flex
                            xs12
                            sm8
                            md4
                    >
                        <v-card class="elevation-12">
                            <v-toolbar
                                    color="primary"
                                    dark
                                    flat
                            >
                                <v-toolbar-title>{{ $t('login.title') }}</v-toolbar-title>
                            </v-toolbar>
                            <v-form v-on:submit.prevent="doLogin()">
                                <v-card-text>
                                        <v-text-field
                                                :label="$t('login.form.login')"
                                                name="login"
                                                autocomplete="username"
                                                prepend-icon="person"
                                                type="text"
                                                v-model="username"
                                        ></v-text-field>
                                        <v-text-field
                                                id="password"
                                                :label="$t('login.form.password')"
                                                name="password"
                                                autocomplete="password"
                                                prepend-icon="lock"
                                                type="password"
                                                v-model="password"
                                        ></v-text-field>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn type="submit" color="primary">{{ $t('buttons.login') }}</v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Loading from "../components/Loading";

    export default {
        components: {Loading},

        props: {
            source: String,
        },

        mounted() {
            this.redirectIfAlreadyLoggedIn();
        },

        data: () => ({
            drawer: null,
            username: "",
            password: "",
            isBeingLoggedIn: false
        }),

        computed: {
            ...mapGetters('user', ['isLoggedIn'])
        },

        methods: {
            doLogin() {
                this.isBeingLoggedIn = true;
                this.login({
                    username: this.username,
                    password: this.password,
                    onComplete: () => {
                        this.isBeingLoggedIn = false;
                        this.redirectIfAlreadyLoggedIn();
                    }
                });
            },
            redirectIfAlreadyLoggedIn() {
                if (this.isLoggedIn) {
                    this.$router.push({name: 'dashboard'});
                }
            },
            ...mapActions('user', ['login'])
        }
    }
</script>
