<template>
    <div>
        <Loading v-if="!didLoadedData || (isSaving && isNew) || isDeleting || tutorial === null"></Loading>
        <v-form v-model="valid" ref="form" v-else>
            <detail-header :show-back="true" back-route-name="tutorials">
                <h2 v-if="isNew" class="my-2">{{ $t('tutorialDetail.tutorialAdding') }}</h2>
                <h2 v-else class="mr-3">
                    {{ tutorial | tutorialName | placeholder($t('elements.noData')) }}
                </h2>

                <div v-if="!isNew" class="d-flex align-center">
                    <span
                            v-for="item in translations"
                            :key="item.id"
                            :class="getFlag(item)"
                            @click="$router.push('/tutorials/' + item.id)"
                    ></span>
                    <v-btn
                            v-if="currentUserCanEdit('tutorials') && !isNew && translations.length < languages.length"
                            x-small
                            icon
                            outlined
                            elevation="1"
                            fab
                            class="ml-2"
                            id="add-translation"
                            @click="openTranslateDialog"
                    >
                        <v-icon color="black">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </div>

                <v-spacer></v-spacer>

                <v-menu bottom left v-if="currentUserCanEdit('tutorials') && isNew === false">
                    <template v-slot:activator="{ on }">
                        <v-btn
                            icon
                            v-on="on"
                            id="tutorialMenu"
                        >
                            <v-icon>more_vert</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item v-if="translations.length > 1" @click="attemptToDeleteTutorial(false)">
                            <v-list-item-title>{{ $t('tutorialDetail.buttons.deleteTranslation') }} ({{ tutorial.language | languageName }})</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="attemptToDeleteTutorial(true)">
                            <v-list-item-title>{{ $t('tutorialDetail.buttons.deleteWholeTutorial') }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <save-button v-if="currentUserCanEdit('tutorials')" :is-saving="isSaving" @click="save()"/>
            </detail-header>

            <div class="px-4 pb-4">
                <v-expansion-panels
                        :accordion="true"
                        :focusable="true"
                        :value="0"
                >
                    <TutorialDetailItem
                            v-bind:item="tutorial"
                            v-bind:level="0"
                            v-on:valid-changed="v => valid = v"
                            :highlightedItemId="highlightedItemId"
                            @save="save"
                            @highlightItemId="highlightedItemId = $event"
                    ></TutorialDetailItem>
                </v-expansion-panels>

                <h2 class="mt-6 mb-3">{{ $t('tutorialDetail.elements.solutionProcedure') }}</h2>

                <v-expansion-panels
                        :accordion="true"
                        :focusable="true"
                        :value="expandedPanelIndex"
                >
                    <TutorialDetailItem
                            v-for="(task, index) in getTasks(tutorial)"
                            ref="tasks"
                            :key="task.id"
                            :item="task"
                            :level="1"
                            :index="index"
                            :count="getTasks(tutorial).length"
                            prefix=""
                            v-on:valid-changed="v => valid = v"
                            :enable-ordering="true"
                            @save="save"
                            @highlightItemId="highlightedItemId = $event"
                    ></TutorialDetailItem>
                </v-expansion-panels>

                <v-btn
                        v-if="currentUserCanEdit('tutorials')"
                        text
                        color="primary"
                        v-on:click="() => addTask()"
                        :class="(getTasks(tutorial).length > 0 && 'mt-4') + ' mb-4'"
                >+ {{ $t('tutorialDetail.buttons.addSolutionStep') }}</v-btn>
                <div v-else-if="getTasks(tutorial).length === 0">{{ $t('tutorialDetail.elements.noSolution') }}</div>

            </div>
            <TutorialTranslateItem
                    v-if="tutorialId !== null"
                    :mainItemId="tutorialId"
                    :language="tutorial.language"
                    :model="isTranslateModalVisible"
                    @close="isTranslateModalVisible = false"
            />

        </v-form>


    </div>
</template>

<script>
    import TutorialDetailItem from "../components/TutorialDetailItem";
    import Loading from "../components/Loading";
    import {mapActions, mapGetters} from "vuex";
    import {tutorialName} from "../filters/tutorialName";
    import {placeholder} from "../filters/placeholder";
    import TutorialTranslateItem from "../components/TutorialTranslateItem";
    import DetailHeader from "../components/DetailHeader";
    import {languages} from "../config";
    import SaveButton from "@/components/SaveButton";

    export default {
        name: "TutorialDetail",

        components: {
          SaveButton,
            Loading,
            TutorialDetailItem,
            TutorialTranslateItem,
            DetailHeader,
        },

        mounted() {
            this.load();
            this.loadTags();
            this.loadContacts();
        },

        watch: {
            tutorial: function () {
                this.isTranslateModalVisible = false;
            }
        },

        beforeRouteUpdate (to, from, next) {
            next();
            this.load();
        },

        data() {
            return {
                tutorial: null,
                valid: false,
                isSaving: false,
                isDeleting: false,
                isTranslateModalVisible: false,

                isSavingNewTask: false,
                isAddTaskFormVisible: false,
                newTaskIsValid: false,
                newTask: null,

                highlightedItemId: null,
            }
        },


        computed: {
            isNew() { return this.$route.params.id === "new"; },
            tutorialId() { return parseInt(this.$route.params.id) || null },
            didLoadedData() { return this.$store.state.tutorials.didLoadedData
                && this.$store.state.tags.didLoadedData
                && this.$store.state.contacts.didLoadedData; },
            languages() { return languages; },
            translations() { return this.getTranslations(this.tutorial); },

            /**
             * If some item should be expanded via the `highlightedItemId ` attribute in data,
             * then this property contains the index of the related expansion panel
             * that directly contains the highlighted item or contains the highlighted item in one of its children.
             */
            expandedPanelIndex() {
                if (this.highlightedItemId === null ) {
                    return -1;
                }

                return this.getTasks(this.tutorial).findIndex(task =>
                    task.id === this.highlightedItemId
                );
            },

            ...mapGetters('tutorials', ['itemById', 'getTasks', 'prepareNewItem', 'getTranslations', 'getAnotherTranslation']),
            ...mapGetters('changeTracking', ['hasChanges']),
            ...mapGetters('user', ['currentUserCanEdit']),
        },

        methods: {
            load() {
                this.loadTutorials(() => {
                    if (this.isNew) {
                        const newItem = this.prepareNewItem();
                        this.addItem({item: newItem});
                        this.tutorial = newItem;
                    } else {
                        this.tutorial = this.itemById(this.tutorialId);
                    }
                });
            },

            async save(successCallback) {
                if (!this.$refs.form.validate()) {
                    // find the first input with some error
                    const firstError = this.$refs.form.inputs.find(e => e.errorBucket && e.errorBucket.length);
                    // firstly, remove all previous highlights to enable rescrolling to the error if it occurs 2 times in a row (always change the value)
                    this.highlightedItemId = null;
                    // wait for the UI to update
                    this.$nextTick(() => {
                        // find the closest FaqItem relatively to the erroneous input and highlight this FaqItem
                        // it expands recursively all panels so the erroneous FaqItem is expanded and visible
                        this.highlightedItemId = firstError.$parent.$parent.$parent.item.id;
                        // wait for the exapansion panels to expand in order to show the erroneous input
                        setTimeout(() => {
                            // scroll the page so the erroneous input is in the middle of the screen
                            firstError.$el.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
                        }, 600); // 600ms works fine for now, but if some slower devices need more time, increase it; it should always be higher than in TutorialDetailItem.watch.highlightedItemId.
                    });
                    // notify the user that the saving cannot be done due to an error
                    alert(this.$t('elements.invalidFormAlert'));
                    return;
                }

                this.isSaving = true;

                try {
                  this.fixNewlines();
                    await this.saveTutorial({ tutorial: this.tutorial });
                    await this.archiveToBeArchivedMessages()
                    this.resolveChanges();
                    this.isSaving = false;
                    this.$router.replace('/tutorials/' + this.tutorial.id);
                    if (successCallback) { successCallback(); }
                } catch(e) {
                    this.isSaving = false;
                    console.error(e);
                    alert(this.$t('elements.saveErrorAlert'));
                }
            },

            fixNewlines() {
                this.tutorial.text = this.tutorial.text.replace(/(\n(\s*\n)?){2,}/g, '\n');
            },

            async attemptToDeleteTutorial(includeTranslations = false) {
                const name = placeholder(tutorialName(this.tutorial), this.$t('elements.noTitle'));
                const languageName = this.$options.filters.languageName(this.tutorial.language);
                const anotherTranslation = this.getAnotherTranslation(this.tutorial);
                const anotherTranslationId = anotherTranslation ? anotherTranslation.id : null;

                const message = this.translations.length === 1
                    ? this.$t('tutorialDetail.elements.deleteWholeTutorialConfirm', [name]) 
                    : includeTranslations
                        ? this.$t('tutorialDetail.elements.deleteWholeTutorialAndTransConfirm', [name]) 
                        : this.$t('tutorialDetail.elements.deleteTutorialLanguageConfirm', [languageName, name]);

                if (confirm(message)) {
                    this.isDeleting = true;

                    await this.deleteItem({item: this.tutorial, includeTranslations});
                    this.resolveChanges();
                    this.isDeleting = false;

                    if (includeTranslations || !anotherTranslationId) {
                        this.$router.push({name: 'tutorials'});
                    } else {
                        this.$router.push({name: 'tutorialDetail', params: { id: anotherTranslationId }});
                    }
                }
            },

            addTask() {
                if (this.tutorial.isTemporary) {
                    alert(this.$t('tutorialDetail.elements.saveTutorialFirstAlert'));
                } else {
                    let newTask = this.prepareNewItem(this.tutorial, true);
                    this.addItem({item: newTask});
                    this.highlightedItemId = newTask.id;
                    this.$nextTick(() => 
                        this.$refs.tasks[this.$refs.tasks.length - 1]
                            .$el
                            .scrollIntoView({behavior: 'smooth', block: 'center'})
                    );
                }
            },

            getFlag(item) {
                let classes = `icon-flag ${item.language} `;

                if(item.language === this.tutorial.language) {
                    classes += ' active-flag ';
                }

                return classes;
            },

            openTranslateDialog() {
                if (this.hasChanges) {
                    alert(this.$t('tutorialDetail.elements.saveChangesFirstAlert'));
                } else {
                    this.isTranslateModalVisible = true;
                }
            },

            ...mapActions('tutorials', ['loadTutorials', 'saveTutorial', 'deleteItem', 'addItem']),
            ...mapActions('tags', ['loadTags']),
            ...mapActions('contacts', ['loadContacts']),
            ...mapActions('changeTracking', ['resolveChanges']),
            ...mapActions('userMessages', ['archiveToBeArchivedMessages'])
        }
    };
</script>

<style scoped>
    tr {
        cursor: pointer;
    }

    .icon-flag {
        cursor: pointer;
        opacity: 0.30;
    }

    .active-flag {
       opacity: 1;
       pointer-events: none;
    }

    .v-btn--fab.v-size--x-small {
        width: 24px;
        height: 24px;
        margin-bottom: 5px;
        opacity: 0.5;
    }

    .v-btn--fab.v-size--x-small:hover {
        opacity: 1;
    }
</style>
