<template>
    <div>
        <h1>{{ $t('home.title') }}</h1>
        <v-btn large class="mt-4" color="primary" v-on:click="goToLogin()">{{ $t('buttons.enter') }}</v-btn>
    </div>
</template>

<script>
    export default {
        methods: {
            goToLogin() {
                this.$router.push('/login');
            }
        }
    };
</script>
