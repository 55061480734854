import {config} from '../../config';
import axios from 'axios';
import Vue from 'vue';
import { setAttributeByPath} from "../../utils/common";
import i18n from '@/plugins/i18n/i18n';

const state = {
    didLoadedData: false,
    items: [],
    newItem: null,
    lastTempId: -1
};

const getters = {
    didLoadedData: state => state.didLoadedData,

    chatbots: (state) => {
        return state.items;
    },

    chatbotsCount: (state) => {
        return getters.chatbots(state).length;
    },

    itemById: (state) => (id) => {
        return state.items.find(i => i.id === id);
    },

    prepareNewItem: () => () => {
        return {
            "dueDate": null,
            "fullGreet": {
                "intents": []
            },
            "shortGreet": {
                "intents": []
            },
            "goodbye": {
                "intents": []
            },
            "fallback": {
                "intents": []
            },
            "happy": {
                "intents": []
            },
            "unhappy": {
                "intents": []
            },
            "restart": {
                "intents": []
            },
            "unusualQuestions": [
                {
                    "answers" : [
                        {
                            "intents" : []
                        }
                    ],
                    "questions": [
                        {
                            "intents" : []
                        }
                    ],
                }
            ],
        }
    },

    getMaxId: state => {
        const sorted = state.items.slice().sort((a, b) => b.id - a.id);
        if (sorted.length === 0) {
            return 0;
        } else {
            return sorted[0].id;
        }
    },

    prepareNewIntent: () => (main = false) => {
        return {
            text: "",
            main: main,
        }
    },

    prepareNewQuestion: () => (main = false) => {
        return {
            isNew: true,
            "answers" : [
                {
                    text: "",
                    main: main,
                }
            ],
            "questions": [
                {
                    text: "",
                    main: main,
                }
            ],
        }
    },

    prepareNewAnswer: () => (main = false) => {
        return {
            text: "",
            main: main,
        }
    },
    
};

const actions = {

    loadChatbots({ commit }, onComplete = () => {}) {
        commit('didLoadedData', false);
        axios.get(config.serverUrl + 'chatbots/')
            .then((response) => {
                commit('setItem', response.data.items);
                commit('didLoadedData', true);
                onComplete();
            });
    },

    addItem({ commit }, { item }) {
        commit('addItem', item);
    },

    saveBot({ commit }, { chatbot, onSuccess = () => {}, onFailure = () => {} }) {
        let promises = [];
        let chatbotId = () => chatbot.id;

        const traverse = (item) => {
            let request;
            delete item.lastUpdate;

            if (item.isTemporary) {
                request = new Promise((resolve, reject) => {
                    const copy = Object.assign({}, item);
                    delete copy["id"]; // do not send temp id to the server
                    axios.post(config.serverUrl + 'chatbot/', copy).then(response => {
                        // instead, use the id retrieved from the server after the new item was saved
                        const newId = response.data.content.id;
                        commit('replaceTempId', {item: item, newId: newId});
                        if (item.id === chatbotId) { chatbotId = () => newId; }
                        resolve();
                    }).catch(() =>
                        reject()
                    );
                });
            } else {
                request = axios.put(config.serverUrl + 'chatbots/' + item.id, item);
            }

            promises.push(request);

            //getters.getQuestions()(item).forEach(traverse);
        };

        traverse(chatbot);

        Promise.all(promises)
            .then(() => {
                onSuccess(chatbotId());
            })
            .catch(function () {
                alert(i18n.t('elements.saveErrorAlert'));
                onFailure();
            });
    },

    setItemAttribute({ commit }, payload) {
        commit('setItemAttribute', payload);
    },

};

const mutations = {
    didLoadedData (state, loaded) {
        state.didLoadedData = loaded;
    },

    setItem(state, chatbots) {
        if (chatbots !== undefined && chatbots !== null) {
            state.items = chatbots;
        } else {
            state.items = [];
        }
    },

    addItem(state, item) {
        if (item.id === null) { // for new items
            item.id = getters.getMaxId(state) + 1;
        }

        state.items.unusualQuestions.push(item);
    },

    setItemAttribute(state, {item, key, value}) {
        const index = state.items.findIndex(i => i.id === item.id);
        setAttributeByPath(state.items[index], key, value);
    },

    deleteItem(state, item) {
        const index = state.items.findIndex(i => i.id === item.id);
        Vue.delete(state.items, index);
    },

    replaceTempId(state, {item, newId}) {
        Vue.set(item, 'isTemporary', false);
        Vue.set(item, 'id', newId);
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
