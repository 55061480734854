<template>
    <div>
        <floating-loading :shown="!didLoadedData"></floating-loading>

        <v-layout class="pb-3" align-center>
            <h1>{{ $t('tags.title') }}</h1>
            <v-spacer></v-spacer>
            <v-btn v-if="currentUserCanEdit('tags') && tags.length > 0" color="dark" @click="showOrderingDialog()" class="mr-4">
                <v-icon>drag_handle</v-icon>
                {{ $t('tags.buttons.order') }}
            </v-btn>
            <v-btn v-if="currentUserCanEdit('tags') && selected.length === 0" dark color="primary" @click="showCreateDialog()">
                <v-icon>mdi-plus</v-icon>
                {{ $t('buttons.new') }}
            </v-btn>
            <v-btn v-else-if="currentUserCanEdit('tags')" color="error" @click="deleteSelected">{{ $t('buttons.delete') }}</v-btn>
        </v-layout>

        <div v-if="tags.length === 0">{{ $t('tags.elements.noTag') }}</div>

        <v-row v-else>
            <v-col cols="12" sm="6" md="4" v-for="tag in tags" :key="tag.id">

                <v-card elevation="2" @click="showEditDialog(tag)">

                    <div class="floating-checkbox" @click.stop.prevent>
                        <v-checkbox 
                            v-model="selected" 
                            multiple
                            dense 
                            hide-details 
                            class="ma-0 pa-0" 
                            :value="tag.id" 
                        />
                    </div>

                    <v-img
                        height="200"
                        :src="imageSrc(tag)"
                    >
                        <template v-slot:placeholder>
                            <div class="placeholder fill-height fill-width" :style="{backgroundColor: tag.color}">
                                <v-icon>mdi-label</v-icon>
                            </div>
                        </template>
                    </v-img>

                    <v-card-text class="d-flex align-center">
                        <v-chip
                                v-for="language in languages"
                                :key="`tag-${language}`"
                                class="ma-1"
                                :color="tag.color"
                                label
                                text-color="white"
                                @click="showEditDialog(tag)"
                        >
                            <v-icon left>mdi-label</v-icon>
                            {{ tag | localized('name', language) | ellipsis(30) }}
                        </v-chip>
                    </v-card-text>

                </v-card>
            </v-col>
        </v-row>

        <tag-dialog 
            :model="dialog"
            :tag="editedTag"
            @close="closeDialog"
        />

        <tags-ordering-dialog
            :model="orderingDialog"
            @close="closeDialog"
        />
    </div>

</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import FloatingLoading from "../components/FloatingLoading";
    import TagDialog from '../components/TagDialog.vue';
    import TagsOrderingDialog from '../components/TagsOrderingDialog.vue';
    import {languages} from "../config.js";

    export default {
        name: "Tags",

        components: {
            FloatingLoading,
            TagDialog,
            TagsOrderingDialog
        },

        mounted() {
            this.loadTags();
        },

        data() {
            return {
                dialog: false,
                orderingDialog: false,
                editedTag: null,
                selected: [],
            }
        },

        computed: {
            ...mapGetters('tags', ['tags', 'emptyTag', 'didLoadedData', 'colors', 'imageSrc']),
            ...mapGetters('user', ['currentUserCanEdit']),

            languages() {
                return languages;
            },
        },

        methods: {
            ...mapActions('tags', ['loadTags', 'prepareNewTag', 'prepareTagForEdit', 'deleteTags']),

            async showCreateDialog() {
                this.editedTag = await this.prepareNewTag();
                this.dialog = true;
            },

            async showEditDialog(tag) {
                this.editedTag = await this.prepareTagForEdit(tag);
                this.dialog = true;
            },

            async showOrderingDialog() {
                this.orderingDialog = true;
            },

            closeDialog() {
                this.dialog = false;
                this.orderingDialog = false;
                this.loadTags();
            },

            async deleteSelected() {
                if (confirm(this.$t('tags.elements.deleteTagsConfirm', [this.selected.length]))) {
                    await this.deleteTags(this.selected);
                    this.selected = [];
                }
            },
        },
    }
</script>

<style scoped>

.placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
}

.placeholder i {
    color: white;
    font-size: 5rem;
}

.floating-checkbox {
    position: absolute;
    left: 0.8rem;
    top: 0.8rem;
    z-index: 1;
    background-color: white;
    border-radius: 50% !important;
    padding: 0.3rem;
}

.floating-checkbox >>> .v-input--selection-controls__input {
    /* Override default right margin to prevent too much white space on the right. */
    margin-right: 0 !important;
}

</style>