<template>
    <div>
        <floating-loading :shown="!didLoadedData"></floating-loading>

        <v-layout class="pb-3" align-center>
            <h1>{{ $t('tutorials.title') }}</h1>
            <v-spacer></v-spacer>
            <v-btn v-if="currentUserCanEdit('tutorials') && selected.length === 0" dark color="primary" @click="$router.push('/tutorials/new')">+ {{ $t('buttons.new') }}</v-btn>
            <v-btn v-if="currentUserCanEdit('tutorials') && selected.length > 0" color="light" @click="setHiddenForSelected(false)">{{ $t('tutorials.buttons.makeVisible') }}</v-btn>
            <v-btn v-if="currentUserCanEdit('tutorials') && selected.length > 0" color="light" @click="setHiddenForSelected(true)">{{ $t('buttons.hide') }}</v-btn>
            <v-btn v-if="currentUserCanEdit('tutorials') && selected.length > 0" color="error" @click="deleteSelected">{{ $t('buttons.delete') }}</v-btn>
        </v-layout>

        <v-row>
            <v-col cols="6" sm="3">
                <v-select
                    label="Tag"
                    :items="tagFilterValueItems"
                    v-model="$query.tag"
                    prepend-icon="label"
                    clearable
                >
                    <template v-slot:item="{ item }">
                        <v-icon :color="item.color" class="mr-2">{{ item.value === -1 ? 'label_off' : 'label' }}</v-icon>
                        {{ item.text }}
                    </template>
                </v-select>
            </v-col>
            <v-col cols="6" sm="6">
                <v-text-field
                    v-model="queryFilterValue"
                    :label="$t('tutorials.elements.searchPlaceholder')"
                    clearable
                    prepend-icon="search"
                    :append-icon="queryFilterValue && queryFilterValue !== $query.search ? 'check' : null"
                    @click:append="applyQueryFilter()"
                    @click:clear="queryFilterValue = ''; applyQueryFilter()"
                    @keypress.enter="applyQueryFilter()"
                    @blur="applyQueryFilter()"
                />
            </v-col>
            <v-col cols="12" sm="3">
                <v-checkbox
                    class="float-right"
                    color="dark"
                    v-model="$query.withoutTranslation"
                    :label="$t('tutorials.elements.withoutTranslation')"
                ></v-checkbox>
            </v-col>
        </v-row>

        <v-data-table
                :headers="headers"
                :items="filteredTutorials"
                class="elevation-1"
                :disable-pagination="true"
                :hide-default-footer="true"
                :sort-by.sync="$query.sort"
                :sort-desc.sync="$query.desc"
                :custom-sort="customSort"
        >
            <template v-slot:[`header.name`]>
                {{ $t('tutorials.elements.languageName') }} 
                <span 
                    v-for="language in languages"
                    :key="language"
                    :class="`icon-flag sort-language ${language} ${$query.sortLanguage === language ? 'active' : ''}`" 
                    @click.stop="$query.sortLanguage = language; $query.sort = 'name'; $query.desc = false;"
                />
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                <template v-if="items.length===0">
                    <tr>
                        <td colspan="5">{{ $t('elements.noData') }}</td>
                    </tr>
                </template>
                <template v-else v-for="(tutorial, tutorialIndex) in items">
                    <tr v-for="(item, index) in  getTranslations(tutorial).sort((a,b) => (a.language > b.language) ? 1 : -1)"
                        :key="item.id" v-on:click="$router.push('/tutorials/' + item.id)"
                        :style="(index+1 === getTranslations(tutorial).length && tutorialIndex < items.length - 1) ? 'border-bottom: solid 2px #616161;' : ''"
                        :class="item.hidden ? 'hidden-tutorial' : ''"
                    >
                        <td>
                            <v-checkbox v-model="selected" :value="item.id" multiple @click.stop.prevent />
                        </td>

                        <td>
                            <b>{{ item | tutorialName | placeholder($t('elements.noTitle')) }}</b>
                            <span :class="`icon-flag ${item.language}`"></span>
                        </td>

                        <td class="pa-1" v-if="getTags(item).length > 0">
                            <v-chip
                                    v-for="tag in getTags(item)"
                                    :key="tag.id"
                                    class="mb-1 mr-1"
                                    :color="tag.color"
                                    label
                                    text-color="white"
                            >
                                <v-icon left>mdi-label</v-icon>
                                {{ tag.name | placeholder($t('elements.noTitle')) }}
                            </v-chip>
                        </td>
                        <td class="pa-1" v-else >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" text-color="red">
                                        <v-icon left
                                                color="black"
                                                dark>
                                            warning
                                        </v-icon>
                                        {{ $t('tutorials.elements.tutorialWithoutTags') }}                                        
                                        </span>
                                </template>
                                <span>{{ $t('tutorials.elements.noTagsChatbotWarning') }}</span>
                            </v-tooltip>
                        </td>
                        <td class="text&#45;&#45;secondary">{{ formatTimestamp(item.lastUpdate) }}</td>
                    </tr>
                </template>
                </tbody>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import FloatingLoading from "../components/FloatingLoading";
    import moment from 'moment';
    import { languages } from '../config';
    import { nlpUtils } from '@/utils/nlp';
    import { sanitize } from '@/utils/common';

    export default {
        name: "Tutorials",

        components: {FloatingLoading},

        mounted() {
            this.loadTutorials();
            this.loadTags();
        },

        data() {
            return {
                language: languages[0],
                queryFilterValue: this.$query.search,
                selected: [],
            }
        },

        computed: {
            ...mapGetters('tutorials', ['tutorials', 'didLoadedData', 'getTranslations', 'tutorialNamesInAllLanguages', 'getLastUpdatedTranslation']),
            ...mapGetters('tags', ['localizedTags']),
            ...mapGetters('user', ['currentUserCanEdit']),

            languages() {
                return languages;
            },

            headers() {
                return [
                    {
                      text: '',
                    },
                    {
                        text: this.$t('tutorials.table.title'),
                        align: 'left',
                        value: 'name',
                    },
                    {
                        text: this.$t('tutorials.table.tags'),
                        align: 'left',
                        value: 'tags',
                        sortable: false,
                    },
                    {
                        text: this.$t('tutorials.table.lastUpdate'),
                        align: 'left',
                        value: 'lastUpdate',
                    }
                ]
            },

            tagFilterValueItems() {
                const items = this
                    .localizedTags(this.language)
                    .map(tag => ({
                        isTag: true,
                        text: tag.name,
                        value: tag.id,
                        color: tag.color,
                    }));
                items.unshift({
                    isTag: true,
                    text: this.$t('tutorials.elements.withoutTag'),
                    value: -1,
                });
                return items;
            },

            filteredTutorials() {
                let result = this.tutorials;

                // items without a translation
                if (this.$query.withoutTranslation) {
                    result = result.filter(tutorial => this.getTranslations(tutorial).length < languages.length);
                }

                // filter by a tag
                if (this.$query.tag === -1) { // tutorials without any tag
                    result = result.filter(tutorial => 
                        tutorial.tagIds.length === 0
                    );
                } else if (this.$query.tag !== null && !isNaN(this.$query.tag)) { // tutorials containing the searched tag
                    result = result.filter(tutorial => 
                        tutorial.tagIds.includes(this.$query.tag)
                    );
                }
                
                // filter by free text
                // the tutorial must have ALL the required tokens in at least one of its name translations
                if (this.$query.search) {
                    const requiredTokens = nlpUtils.tokenizeWithoutWhitespaces(this.$query.search);
                    result = result.filter(tutorial => 
                        this.tutorialNamesInAllLanguages(tutorial.id).some(name => 
                            requiredTokens.every(token => 
                                sanitize(name).includes(sanitize(token)) 
                            ) 
                        )
                    );
                }

                return result;
            },

        },

        methods: {

            /**
             * A custom sort that enables to sort by name in different languages.
             * Other attributes are sort normally.
             */
            customSort(items, index, isDesc) {
                return items.sort((a, b) => {
                    const sortByAttribute = index[0];
                    let isALower = false;

                    if (sortByAttribute === "name") { // sorting by tutorial's name (its primary intent)
                        const aLocalized = this.getTranslations(a).find(translation => translation.language === this.$query.sortLanguage);
                        const bLocalized = this.getTranslations(b).find(translation => translation.language === this.$query.sortLanguage);
                        if (!aLocalized) { return 1; } // if the tutorial does not have the required translation, it is sorted after the other tutorials
                        if (!bLocalized) { return -1; } // if the tutorial does not have the required translation, it is sorted after the other tutorials
                        isALower = sanitize(aLocalized.name) < sanitize(bLocalized.name) ? -1 : 1; // compare their names without diacritics lowercased
                    
                    } else if (sortByAttribute === "lastUpdate") {
                        const aTime = this.getLastUpdatedTranslation(a).lastUpdate;
                        const bTime = this.getLastUpdatedTranslation(b).lastUpdate;
                        isALower = aTime < bTime ? -1 : 1;

                    } else {
                        // other attributes are sorted normally
                        isALower = a[index] < b[index] ? -1 : 1;
                    }

                    return isDesc[0] ? -isALower : isALower; // if sorting is descending, reverse the result
                });
            },

            formatTimestamp(timestamp) {
                if (timestamp === null || timestamp === undefined || timestamp === 0) {
                    return "-";
                } else {
                    return moment(timestamp).format("lll");
                }
            },

            getTags(item) {
                return item.tagIds
                    .map(id => this.localizedTags(item.language).find(t => t.id === id))
                    .filter(t => t !== undefined);
            },

            async setHiddenForSelected(newValue) {
              await this.updateHiddenByIds({ids: this.selected, hidden: newValue})
            },

            async deleteSelected() {
                if (confirm(this.$t('tutorials.elements.deleteTutorialsConfirm', [this.selected.length]))) {
                    await this.deleteItemsByIds(this.selected);
                    this.selected = [];
                    await this.loadTutorials(); // reload all items because main translations might have changed
                }
            },

            applyQueryFilter() {
                this.$query.search = this.queryFilterValue;
            },

            ...mapActions('tutorials', ['loadTutorials', 'deleteItemsByIds', 'updateHiddenByIds']),
            ...mapActions('tags', ['loadTags'])
        }
    };
</script>

<style scoped>
    tr {
        cursor: pointer;
    }

    td {
        border-bottom: inherit !important;
    }

    .icon-flag {
        width: 16px;
        height: 12px;
        cursor: pointer;
    }

    .sort-language:not(.active) {
        opacity: 0.3;
    }

    .theme--light.v-data-table tbody tr:not(:last-child) {
        border-bottom: none;
    }

    .hidden-tutorial td {
      color: #c4c4c4 !important;
    }

</style>
