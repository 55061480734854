<template>
  <v-dialog
      :value="model"
      width="750"
      :scrollable="true"
      :persistent="true"
  >
    <v-card>
      <v-card-title>
        {{ $t('userMessages.assignMessageDialog.title') }}
      </v-card-title>
      <v-divider/>
      <v-card-text class="pt-4">
        <v-text-field style="font-weight: bold" append-icon="" v-model="newIntent">{{ newIntent }}</v-text-field>
        <v-spacer/>
        <v-text-field
            v-model="query"
            :label="$t('userMessages.elements.searchPlaceholder')"
            clearable
            prepend-icon="search"
            :append-icon="query ? 'check' : null"
            @click:append="applyQueryFilter()"
            @click:clear="query = ''; applyQueryFilter()"
            @keypress.enter="applyQueryFilter()"
            @blur="applyQueryFilter()"
        />
        <v-spacer/>
        <v-expansion-panels focusable v-if="filteredTutorials.length !== 0">

          <v-expansion-panel v-if="predictedTutorial !== undefined">
            <v-expansion-panel-header style="font-weight: bold">
              <v-btn
                  small
                  color="primary"
                  outlined
                  @click="assign(predictedTutorial.id); archive()">
                {{
                  $t('userMessages.assignMessageDialog.assign')
                }}
              </v-btn>
              <span :class="`icon-flag ${predictedTutorial.language} smaller`"></span>
              {{ predictedTutorial.mainIntentName }}

            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item
                    v-for="intent in predictedTutorial.intents"
                    :key="intent.id"
                >
                  <v-list-item-content>
                    <v-list-item-title style="white-space: pre-wrap">{{ intent.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel
              v-for="tutorial in filteredTutorials"
              :key="tutorial.id"
          >
            <v-expansion-panel-header>
              <v-btn
                  small
                  color="primary"
                  outlined
                  @click="assign(tutorial.id); archive()">
                {{
                  $t('userMessages.assignMessageDialog.assign')
                }}
              </v-btn>
              <span :class="`icon-flag ${tutorial.language} smaller`"></span>
              {{ tutorial.mainIntentName }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item
                    v-for="intent in tutorial.intents"
                    :key="intent.id"
                >
                  <v-list-item-content>
                    <v-list-item-title style="white-space: pre-wrap">{{ intent.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-else>
          <p>{{$t("elements.noData")}}</p>
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn right color="dark" text @click="close">{{ $t('buttons.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {nlpUtils} from "@/utils/nlp";
import { sanitize } from "@/utils/common";

export default {
  name: "AssignUserMessageDialog",
  props: {
    model: {type: Boolean, default: false},
    userMessage: {type: Object, default: () => []},
    newIntent: {type: String, default: ""},
    predictedTutorialId: {type: Number, default: undefined},
  },
  data() {
    return {
      items: [],
      querySearchValue: "",
      uncategorizedMessages: "Uncategorized messages",
      query: "",
      selectedFaqId: undefined,
      assigned: false,
    }
  },
  mounted() {
    this.loadSimplifiedTutorials();
  },
  methods: {
    ...mapActions('userMessages', ['updateUserMessage']),
    ...mapActions('intents', ['createIntent']),
    ...mapActions('tutorials', ['addIntent']),
    ...mapActions('simplifiedTutorials', ['loadSimplifiedTutorials']),

    close() {
      this.$emit('close')
      this.selectedFaqId = undefined
    },
    applyQueryFilter() {
      this.querySearchValue = this.query
    },

    async assign(faqItemId) {
      this.selectedFaqId = faqItemId
      const intent = {
        faqItemId: this.selectedFaqId,
        text: this.newIntent,
        archived: false,
      }
      this.close()
      await this.createIntent({item: intent})
      this.addIntent({intent: intent})
    },

    async archive() {
      this.userMessage.archived = true
      await this.updateUserMessage({userMessage: this.userMessage})
    },
  },

  computed: {
    filteredTutorials() {
      let result = this.simplifiedTutorials.slice();

      if(this.predictedTutorialId !== undefined) {
        result = result.filter(tutorial => tutorial.id !== this.predictedTutorialId)
      }

      result.sort((a,b) => a.mainIntentName.localeCompare(b.mainIntentName))

      if (this.querySearchValue) {
        const requiredTokens = nlpUtils.tokenizeWithoutWhitespaces(this.querySearchValue);
        result = result.filter(item => requiredTokens.every(token => sanitize(item.mainIntentName).includes(sanitize(token))))
      }

      result = result.filter(tutorial => tutorial.language === this.userMessage.language)

      return result;
    },

    predictedTutorial() {
      let tutorial = undefined;
      if(this.predictedTutorialId !== undefined) {
        tutorial = this.simplifiedTutorials.find(tutorial => tutorial.id === this.predictedTutorialId)
      }
      return tutorial;
    },

    ...mapGetters('simplifiedTutorials', ['simplifiedTutorials'])
  }
}
</script>

<style scoped>

.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none;
}

.smaller {
  width: 16px;
  height: 12px;
}



</style>