import axios from 'axios';
import {config} from '@/config';
import {normalize, schema} from 'normalizr';
import Vue from "vue";
import {sanitize} from "@/utils/common";
import cloneDeep from "clone-deep";
import i18n from '@/plugins/i18n/i18n';

// Admin management of all users

const state = {
    didLoadedData: false,
    users: {},
};

const user = new schema.Entity('users');

const getters = {
    users: state => Object.values(state.users)
        .sort((a, b) => sanitize(a.name) < sanitize(b.name) ? -1 : 1),
    userById: state => id => state.users[id],
    userCount: state => Object.keys(state.users).length,
    emptyUser: () => {
        return {
            name: null,
            username: null,
            password: null,
            roles: [],
        };
    },
    didLoadedData: state => state.didLoadedData,

    /**
     * Returns a user's role that has the highest permissions.
     * @returns {(function(*): (string))|*}
     */
    topUserRole: () => user => {
        const roleOrder = ['ROLE_ADMIN', 'ROLE_EDITOR'];

        for (const role of roleOrder) {
            if (user.roles.includes(role)) {
                return role;
            }
        }

        return user.roles.length > 0 ? user.roles[0] : null;
    }
};

const actions = {
    async loadUsers({commit}) {
        commit('didLoadedData', false);
        const response = await axios.get(config.serverUrl + 'users/');
        commit('setUsers', response.data.items);
        commit('didLoadedData', true);
    },
    
    async loadUser({commit}, id) {
        commit('didLoadedData', false);
        const response = await axios.get(`${config.serverUrl}users/${id}`);
        commit('updateUser', response.data.content);
        commit('didLoadedData', true);
    },

    prepareNewUser({ commit, getters }) {
        commit('didLoadedData', true);
        return cloneDeep(getters.emptyUser);
    },

    async createUser({ commit }, { user }) {
        try {
            const response = await axios.post(config.serverUrl + 'users/', user);
            const createdUser = response.data.content;
            commit('addUser', createdUser);
            return createdUser;
        } catch (e) {
            if (e.response.status === 409 && e.response.data.message === "Username already exists") {
                alert(i18n.t('userDetail.elements.usernameAlreadyExists'));
            } else {
                console.error(e);
                alert(i18n.t('elements.addErrorAlert'));
            }
            throw e;
        }
    },

    prepareUserForEdit(_, user) {
        return cloneDeep(user);
    },

    async updateUser({ commit }, { user }) {
        try {
            const response = await axios.put(config.serverUrl + `users/${user.id}`, user);
            const updatedUser = response.data.content;
            commit('updateUser', updatedUser);
            return updatedUser;
        } catch (e) {
            console.log(e.response);
            if (e.response.status === 409 && e.response.data.message === "Username already exists") {
                alert(i18n.t('userDetail.elements.usernameAlreadyExists'));
            } else if (e.response.status === 409 && e.response.data.message === "Cannot lose the last admin") {
                alert(i18n.t('userDetail.elements.cannotLostLastAdminAlert'));
            } else {
                console.error(e);
                alert(i18n.t('elements.saveErrorAlert'));
            }
            throw e;
        }
    },

    async deleteUsers({commit}, users) {
        try {
            commit('didLoadedData', false);
            await Promise.all(users.map(async user => {
                await axios.delete(config.serverUrl + `users/${user.id}`);
                commit('deleteUser', user);
            }));
            commit('didLoadedData', true);
        } catch (e) {
            console.error(e);
            alert(i18n.t('elements.deleteErrorAlert'));
        }
    },

};

const mutations = {
    didLoadedData (state, loaded) {
        state.didLoadedData = loaded;
    },

    setUsers(state, users) {
        const normalized = normalize(users, [user]);
        if (normalized.entities !== undefined && normalized.entities.users !== undefined) {
            state.users = normalized.entities.users;
        } else {
            state.users = {};
        }
    },

    addUser(state, user) {
        Vue.set(state.users, user.id, user);
    },

    updateUser(state, user) {
        Vue.set(state.users, user.id, user);
    },

    deleteUser(state, user) {
        Vue.delete(state.users, user.id);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
