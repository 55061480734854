import Vue from 'vue'
import Vuex from 'vuex'
import tutorials from './modules/tutorials'
import user from './modules/user'
import tags from './modules/tags'
import contacts from "./modules/contacts";
import reports from "./modules/reports";
import changeTracking from './modules/changeTracking';
import chatbots from "./modules/chatbots";
import history from "./modules/history";
import users from "./modules/users";
import userMessages from "./modules/userMessages";
import groupedUserMessages from "./modules/userMessages";
import messagesByFaqId from "./modules/userMessages";
import mostFrequentMessages from "./modules/userMessages";
import dashboard from "@/store/modules/dashboard";
import simplifiedTutorials from './modules/tutorials'
import intents from "@/store/modules/intents";
import statistics from "@/store/modules/statistics";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        tutorials,
        chatbots,
        tags,
        user, // the current user
        users, // admin management of all users
        contacts,
        reports,
        changeTracking,
        history,
        dashboard,
        userMessages,
        groupedUserMessages,
        messagesByFaqId,
        simplifiedTutorials,
        mostFrequentMessages,
        intents,
        statistics,
    },
    strict: debug
})
