import { render, staticRenderFns } from "./ChatbotDetailItem.vue?vue&type=template&id=6eeda7fd&scoped=true&"
import script from "./ChatbotDetailItem.vue?vue&type=script&lang=js&"
export * from "./ChatbotDetailItem.vue?vue&type=script&lang=js&"
import style0 from "./ChatbotDetailItem.vue?vue&type=style&index=0&lang=css&"
import style1 from "./ChatbotDetailItem.vue?vue&type=style&index=1&id=6eeda7fd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eeda7fd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VInput } from 'vuetify/lib/components/VInput';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VInput,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle})
