<template>
    <v-dialog
            :value="model"
            width="1000"
            :scrollable="true"
            :persistent="true"
    >
        <v-card
                class="d-flex"
                outlined
                tile>
            <v-card-title class="headline">
                {{ $t('intents.questions.elements.unusualQuestion') }}
            </v-card-title>

            <v-divider/>

            <v-card-text class="pt-4">
                <v-form ref="form">
                    <div class="d-flex align-center mb-2">
                        <v-alert type="info">
                            {{ $t('intents.questions.elements.enterMultipleVariants') }}
                        </v-alert>
                        <div class="ml-4 d-flex flex-column align-center justify-center">
                            <v-progress-circular
                                class="white"
                                :rotate="360"
                                :size="50"
                                :width="8"
                                :value="intentQualityScore"
                                :color="intentQualityScoreColor"
                                >
                                <b>{{ this.intentQualityScore }}</b>
                            </v-progress-circular>
                            {{ $t('intents.questions.elements.score') }}
                        </div>
                    </div>
                    <v-row
                            align="baseline"
                            justify="space-around"
                            style="height: auto"
                    >
                        <v-col cols="12" md="6">
                            <v-card
                                    class="pa-3 pa-md-6"
                                    outlined
                                    tile
                            >
                                <div>
                                    <div>
                                        <h4>{{ $t('intents.questions.elements.mainQuestion') }}</h4>

                                        <div class="d-flex" v-if="mainIntent">
                                            <v-text-field
                                                    v-model.trim="mainIntent.text"
                                                    :placeholder="placeholder(0)"
                                                    :rules="rules.requiredRules"
                                                    @keyup="() => { 
                                                        addNewIntentIfNecessary(); 
                                                        validate(); /* validate to update similar intents warnings at other fields */ 
                                                    }"
                                                    @keydown.enter="jumpToIntent(1)"
                                                    autofocus
                                            ></v-text-field>
                                        </div>
                                        <h4>{{ $t('intents.questions.elements.alternativeQuestionWording', [intentLimit - 1]) }}</h4>

                                        <div v-for="(intent, i) in alternativeIntents" :key="i" class="d-flex">
                                            <v-text-field
                                                    ref="intent"
                                                    v-model.trim="intent.text"
                                                    :placeholder="placeholder(i + 1)"
                                                    :error="intent | hasSimilarIntent(modifiedIntents)"
                                                    :rules="intent | similarIntentRules(modifiedIntents)"
                                                    @keyup="() => { 
                                                        addNewIntentIfNecessary(); 
                                                        validate(); /* validate to update similar intents warnings at other fields */ 
                                                    }"
                                                    @keydown.enter="jumpToIntent(i + 2)"
                                            ></v-text-field>
                                            <v-icon
                                                    v-if="intentCount > intentLimit + 1"
                                                    color="gray"
                                                    @click="removeIntent(i + 1)"
                                                    class="ml-4"
                                            >
                                                mdi-close
                                            </v-icon>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-card
                                    class="pa-3 pa-md-6"
                                    outlined
                                    tile
                            >
                                <div>
                                    <div>
                                        <h4>{{ $t('intents.questions.elements.mainAnswer') }}</h4>

                                        <div class="d-flex" v-if="mainAnswer">
                                            <v-text-field
                                                    v-model.trim="mainAnswer.text"
                                                    :placeholder="placeholderAnswer(0)"
                                                    :rules="rules.requiredRules"
                                                    @keyup="addNewAnswerIfNecessary()"
                                                    @keydown.enter="jumpToAnswer(1)"
                                            ></v-text-field>
                                        </div>
                                        <h4>{{ $t('intents.questions.elements.alternativeAnswerWording', [intentLimit - 1]) }}</h4>

                                        <div v-for="(answer, i) in alternativeAnswers" :key="i" class="d-flex">
                                            <v-text-field
                                                    ref="answer"
                                                    v-model.trim="answer.text"
                                                    :placeholder="placeholderAnswer(i + 1)"
                                                    @keyup="addNewAnswerIfNecessary()"
                                                    @keydown.enter="jumpToAnswer(i + 2)"
                                            ></v-text-field>
                                            <v-icon
                                                    v-if="answerCount > intentLimit + 1"
                                                    color="gray"
                                                    @click="removeAnswer(i + 1)"
                                                    class="ml-4"
                                            >
                                                mdi-close
                                            </v-icon>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
                <v-btn v-if="currentUserCanEdit('chatbots')" color="red" text @click="remove(index)" class="ml-4">{{ $t('buttons.delete') }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn right color="dark" text @click="removeIfNew(); close();">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn v-if="currentUserCanEdit('chatbots')" color="primary" @click="save">{{ $t('buttons.ok') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapGetters} from "vuex";
    import {isEmpty} from "../filters/isEmpty";
    import {config} from "../config";
    import {nlpUtils} from "../utils/nlp";

    export default {
        name: "IntentsQuestions",

        props: {
            model: {type: Boolean, default: false},
            index: Number,
            unusualQuestions: {
                questions: {type: Array, default: () => []},
                answers: {type: Array, default: () => []},
            },
        },

        data() {
            return {
                modifiedIntents: [],
                modifiedAnswers: [],
            }
        },

        watch: {
            model: function(val) {
                if (val) {
                    this.modifiedIntents = this.prepareIntents();
                    this.modifiedAnswers = this.prepareAnswers();
                }
            }
        },

        computed: {
            intentLimit() {
                return config.chatbotIntentLimit;
            },


            intentCount() {
                return this.modifiedIntents.length;
            },

            mainIntent() {
                return this.modifiedIntents.find(i => i.main);
            },

            alternativeIntents() {
                return this.modifiedIntents.filter(i => !i.main);
            },

            /**
             * Returns intents that are not empty after trimming whitespaces.
             */
            validIntents() {
                return this.modifiedIntents.filter(i => i.text.trim() !== "");
            },

            intentQualityScore() {
                return nlpUtils.intentQualityScore(this.validIntents);
            },

            intentQualityScoreColor() {
                return nlpUtils.intentQualityScoreColor(this.validIntents, config.tutorialIntentLimit);
            },


            answerCount() {
                return this.modifiedAnswers.length;
            },

            mainAnswer() {
                return this.modifiedAnswers.find(i => i.main);
            },
            
            alternativeAnswers() {
                return this.modifiedAnswers.filter(i => !i.main);
            },

            /**
             * Returns answers that are not empty after trimming whitespaces.
             */
            validAnswers() {
                return this.modifiedAnswers.filter(i => i.text.trim() !== "");
            },

            rules() {
                return {
                    requiredRules: [ v => !isEmpty(v) || this.$t('rules.requiredItem')],
                }
            },

            placeholders() {
                return this.$t('intents.questions.elements.questionPlaceholders');
            },

            placeholdersAnswers() {
                return this.$t('intents.questions.elements.answerPlaceholders');
            },

            ...mapGetters('chatbots', ['prepareNewIntent', 'prepareNewAnswer']),
            ...mapGetters('user', ['currentUserCanEdit']),
        },

        methods: {
            prepareIntents() {
                const intents = this.unusualQuestions.questions.map(i => ({...i})); // copy for editing
                const limit = intents.length < config.chatbotIntentLimit ? config.chatbotIntentLimit : intents.length + 1;

                intents.sort((a, b) => a.id - b.id);

                for (let i = intents.length; i < limit; i++) {
                    intents.push(this.prepareNewIntent());
                }

                if (intents.find(i => i.main) === undefined) {
                    intents[0].main = true;
                }

                return intents;
            },
            addNewIntent() {
                this.modifiedIntents.push(this.prepareNewIntent());
            },
            addNewIntentIfNecessary() {
                const noEmptyIntent = this.modifiedIntents.find(i => !i.text) === undefined;
                if (noEmptyIntent || this.modifiedIntents.length < config.chatbotIntentLimit) {
                    this.addNewIntent();
                }
            },
            placeholder(index) {
                if (index >= this.placeholders.length) {
                    return `${this.$t('intents.questions.elements.nextQuestion')}...`;
                } else {
                    return this.placeholders[index];
                }
            },
            placeholderAnswer(index) {
                if (index >= this.placeholdersAnswers.length) {
                    return `${this.$t('intents.questions.elements.nextAnswer')}...`;
                } else {
                    return this.placeholdersAnswers[index];
                }
            },
            removeIntent(index) {
                this.modifiedIntents.splice(index, 1);
                this.addNewIntentIfNecessary();
            },
            jumpToIntent(index) {
                const ref = this.$refs.intent[index - 1];
                if (ref) {
                    ref.focus();
                } else {
                    this.addNewIntent();
                    this.$nextTick(() => this.jumpToIntent(index));
                }
            },


            prepareAnswers() {
                const answers = this.unusualQuestions.answers.map(i => ({...i})); // copy for editing
                const limit = answers.length < config.chatbotIntentLimit ? config.chatbotIntentLimit : answers.length + 1;

                answers.sort((a, b) => a.id - b.id);

                for (let i = answers.length; i < limit; i++) {
                    answers.push(this.prepareNewAnswer());
                }

                if (answers.find(i => i.main) === undefined) {
                    answers[0].main = true;
                }

                return answers;
            },
            addNewAnswer() {
                this.modifiedAnswers.push(this.prepareNewAnswer());
            },
            addNewAnswerIfNecessary() {
                const noEmptyIntent = this.modifiedAnswers.find(i => !i.text) === undefined;
                if (noEmptyIntent || this.modifiedAnswers.length < config.chatbotIntentLimit) {
                    this.addNewAnswer();
                }
            },
            removeAnswer(index) {
                this.modifiedAnswers.splice(index, 1);
                this.addNewAnswerIfNecessary();
            },
            jumpToAnswer(index) {
                const ref = this.$refs.answer[index - 1];
                if (ref) {
                    ref.focus();
                } else {
                    this.addNewAnswer();
                    this.$nextTick(() => this.jumpToAnswer(index));
                }
            },

            remove(index) {
                if (confirm(this.$t('intents.questions.elements.deleteQuestionConfirm'))){
                    this.$emit('deleteQuestion', index);
                    this.close();
                }
            },

            validate() {
                this.$refs.form.validate();
            },

            save() {
                if (!this.mainIntent || isEmpty(this.mainIntent.text)) {
                    alert(this.$t('intents.questions.elements.enterMainQuestionAlert'));
                    return;
                }

                if (!this.mainAnswer || isEmpty(this.mainAnswer.text)) {
                    alert(this.$t('intents.questions.elements.enterMainAnswerAlert'));
                    return;
                }

                if (this.intentQualityScore < 30) {
                    alert(this.$t('intents.questions.elements.enterMoreDifferentQuestionsAlert'));
                    return;
                }

                if (this.validAnswers.length < config.chatbotIntentLimit) {
                    alert(this.$t('intents.questions.elements.enterMoreAnswersAlert'));
                    return;
                }

                this.$emit('updateQuestion', {
                    questions: this.validIntents,
                    answers: this.validAnswers,
                });
                this.close();
            },
            removeIfNew() {
                if (this.unusualQuestions.isNew) {
                    this.$emit('deleteQuestion', this.index);
                }
            },
            close() {
                this.$emit('close');
            },
        }
    }
</script>

<style scoped>


</style>
