<template>
    <v-app id="inspire">
        <!-- Different states of the drawer on different devices:
          Mobile (xs): drawer hidden until the hamburger is pressed
          Tablet (sm): drawer partly visible on the left, by default only icons, expand after pressing the hambuger
          Desktop (mdAndUp): drawer fully visible on the left, collapse and show only icons after pressing the hamburger, auto expand on mouse hover
         -->
        <v-navigation-drawer
                v-if="isLoggedIn"
                :value="shouldExpandDrawer"
                @input="updateUserWantsDrawerExpandedOnMobile"
                app
                :clipped="$vuetify.breakpoint.smAndUp"
                :permanent="$vuetify.breakpoint.smAndUp"
                :mini-variant="$vuetify.breakpoint.sm && !shouldExpandDrawer" 
                :expand-on-hover="$vuetify.breakpoint.mdAndUp && !shouldExpandDrawer"
        >
            <v-list dense>
                <template v-for="item in allowedItems">
                    <v-list-item
                            :key="item.text"
                            :to="item.path"
                    >
                        <v-list-item-action>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.text }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
            <div class="version">v{{ version }}</div>
        </v-navigation-drawer>

        <v-app-bar
                :clipped-left="$vuetify.breakpoint.smAndUp"
                app
                color="blue darken-3"
                dark
        >

            <v-app-bar-title>
                <!-- After clicking, set the new drawer state using the current drawer state, not the variable `userWantsDrawerExpanded`, which may be null even if the drawer is expanded. -->
                <v-app-bar-nav-icon 
                    @click.stop="userWantsDrawerExpanded = !shouldExpandDrawer"
                    v-if="isLoggedIn"
                ></v-app-bar-nav-icon>
            </v-app-bar-title>

            <router-link :to="{name: 'dashboard'}" class="logo-wrapper">
                <img src="/img/logo.png" class="logo ml-2" alt="Logo" />
            </router-link>

            <v-spacer></v-spacer>

            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                            text
                            v-on="on"
                            class="mr-1"
                    >
                       {{ currentUiLang }}
                       <v-icon
                            right
                            light
                        >
                            {{ attrs['aria-expanded'] === 'true' ? "mdi-chevron-up" : "mdi-chevron-down" }}
                        </v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="lang in uiLangs" :key="lang" @click="setUiLang(lang)">
                        <v-list-item-title>{{ lang }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn
                            icon
                            large
                            v-on="on"
                            class="mr-1"
                    >
                        <v-avatar
                                size="32px"
                                item
                        >
                            <v-icon>account_circle</v-icon>
                        </v-avatar>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-if="username !== null">
                        <v-list-item-title><i>{{ username }}</i></v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="$router.push('login')" v-if="!isLoggedIn">
                        <v-list-item-title>{{ $t('buttons.login') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showChangePasswordDialog()" v-if="isLoggedIn">
                      <v-list-item-title>{{ $t('buttons.changePass') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="doLogout()" v-if="isLoggedIn">
                        <v-list-item-title>{{ $t('buttons.logout') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

        </v-app-bar>

        <v-content>
            <Loading v-if="isDownloadingUserData"></Loading>
            <v-container v-else fluid :class="containerClass">
                <router-view></router-view>
            </v-container>
        </v-content>

        <change-password-dialog :model="changePasswordDialog" @close="changePasswordDialog = false" />
    </v-app>
</template>

<script>
    import Loading from "./components/Loading";
    import {mapActions, mapGetters, mapState} from "vuex";
    import ChangePasswordDialog from "@/components/ChangePasswordDialog";
    import {config} from "@/config";

    export default {
        components: {ChangePasswordDialog, Loading},

        props: {
            source: String,
        },

        data: () => ({
            changePasswordDialog: false,

            /**
             * Set to true, if the navigation drawer should be expanded, false if collapsed.
             * If null, the default setting for the particular screen size applies.
             * See the comment at the drawer itself above.
             */
            userWantsDrawerExpanded: null,
        }),

        computed: {
            hasUserData() { return this.$store.user.data !== null},
            ...mapGetters('user', ['isLoggedIn', 'username', 'currentUserCanAccess']),
            ...mapState('user', ['isDownloadingUserData', 'didLoadedUserData']),

            items() {
                return [
                    {icon: 'home', text: this.$t('overview.title'), path: '/dashboard'},
                    {icon: 'list', text: this.$t('tutorials.title'), path: '/tutorials'},
                    {icon: 'bar_chart', text: this.$t('statistics.title'), path: '/statistics'},
                    {icon: 'label', text: this.$t('tags.title'), path: '/tags'},
                    {icon: 'phone', text: this.$t('contacts.title'), path: '/contacts'},
                    {icon: 'message', text: this.$t('reports.title'), path: '/reports'},
                    {icon: 'sms', text: this.$t('chatbotSetting.title'), path: '/chatbots'},
                    {icon: 'forum', text: this.$t('userMessages.title'), path: '/user-messages'},
                    {icon: 'person', text: this.$t('users.title'), path: '/users'},
                ]
            },

            allowedItems() {
                return this.items.filter(item => {
                  const module = item.path.substr(1);
                  return this.currentUserCanAccess(module);
                });
            },

            containerClass() {
              const routesWithoutPadding = ['tutorialDetail', 'chatbotDetail', 'contactDetail', 'userDetail'];
                return routesWithoutPadding.includes(this.$route.name)
                    ? 'no-padding'
                    : 'container-default-padding';
            },

            shouldExpandDrawer() {
                // On desktops, expand the menu by default, otherwise let the user open it manually via the hamburger menu.
                return this.userWantsDrawerExpanded === null ? this.$vuetify.breakpoint.mdAndUp : this.userWantsDrawerExpanded;
            },

            version() {
                return config.version;
            },

            uiLangs() {
                return this.$i18n.availableLocales;
            },

            currentUiLang() {
                return this.$i18n.locale;
            }
        },

        methods: {
            /**
             * Updates the variable reflecting whether the user wants the drawer expanded.
             * The update takes effect only on mobile devices where the control of the drawer is handled by the default Vuetify logic.
             * On larger devices, there is a custom logic for expanding and collapsing the drawer using the mini variant. See the comment at the drawer itself above.
             */
            updateUserWantsDrawerExpandedOnMobile(expand) {
                if (this.$vuetify.breakpoint.xs) {
                    this.userWantsDrawerExpanded = expand;
                }
            },

            showChangePasswordDialog() {
                this.changePasswordDialog = true;
            },

            doLogout() {
                this.logout();
                this.$router.push('/login');
            },

            setUiLang(locale) {
                this.$i18n.locale = locale;
            },

            ...mapActions('user', ['logout'])
        }
    }
</script>

<style scoped>
    .logo-wrapper {
        height: 80%;
    }

    .logo {
        height: 100%;
    }

    .v-content {
        background: rgb(247, 247, 247);
    }
</style>

<style>
    .pointer {
        cursor: pointer;
    }

    .container {
        max-width: 1400px !important;
    }

    .container-default-padding {
        padding: 16px 20px 16px 20px !important;
    }

    .no-padding {
        padding: 0 !important;
    }

    .custom-label {
        margin-top: 6px;
        font-size: 12px;
        color: dimgray;
    }

    /* Simulating gray line used for text fields. */
    .v-input.text-field-line .v-input__slot {
        border-bottom: thin solid rgba(0,0,0,.42);
    }

    .icon-flag {
        display: inline-block;
        vertical-align: middle;
        margin: 0px 0.5rem;
        width: 30px;
        height: 26px;
    }

    .icon-flag.en {
        background-image: url('./assets/gb.svg');
    }

    .icon-flag.ru {
        background-image: url('./assets/ru.svg');
    }

    .icon-flag.uk {
        background-image: url('./assets/uk.svg');
    }

    .version {
      position: absolute;
      bottom: 0.5em;
      left: 1em;
      color: gray;
      font-size: 0.8em;
    }
</style>
