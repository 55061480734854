<template>
    <v-expansion-panel
            v-if="item !== null"
            style="margin-left: 0;"
            :class="flat && 'flat'"
             ref="panel"
    >
        <v-expansion-panel-header v-if="level > 0">
            <h3 class="flex-shrink-1">
                {{ itemNumbering }}
                {{ item | tutorialName | placeholder($t('elements.noTitle')) }}
            </h3>
            <v-spacer></v-spacer>
            <v-btn 
                icon 
                class="flex-grow-0 flex-shrink-0" 
                :disabled="index == 0"
                @click.stop="moveItem({item, direction: -1})"
            >
                <v-icon>arrow_upward</v-icon>
            </v-btn>
            <v-btn 
                icon 
                class="flex-grow-0 flex-shrink-0 mr-2"
                :disabled="index == count - 1"
                @click.stop="moveItem({item, direction: 1})"
            >
                <v-icon>arrow_downward</v-icon>
            </v-btn>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="pt-2">

            <Loading v-if="isBeingDeleted"></Loading>

            <div v-else>
                <v-layout wrap v-if="level === 0" class="mt-2">
                    <v-flex xs12>
                        <div class="custom-label">{{ $t('tutorialDetail.item.elements.questions') }}*</div>
                        <v-input
                            :value="item.intents"
                            :rules="[rules.intents_rule]"
                            class="pointer text-field-line"
                            @click="openIntentsDialog()"
                        >
                            <span v-if="item.intents.length === 0">{{ $t('elements.none') }}</span>
                            <span v-else>{{ item | tutorialName }}</span>
                            <span v-if="item.intents.length > 5" class="ml-1">
                                (<i>+ {{ item.intents.length - 1 }} {{ $t('elements.next5') }}</i>)
                            </span>
                            <span v-else-if="item.intents.length > 1" class="ml-1">
                                (<i>+ {{ item.intents.length - 1 }} {{ $t('elements.next1') }}</i>)
                            </span>
                        </v-input>
                    </v-flex>

                    <v-flex xs12 md6>
                      <v-select
                          :value="item.language"
                          @change="setAttribute('language', $event)"
                          v-if="level === 0"
                          :items="['en', 'ru', 'uk']"
                          :label="$t('tutorialDetail.item.elements.language') + '*'"
                          required
                          :rules="[rules.required_rule]"
                          :disabled="item.mainTranslationId !== null && getTranslations(item).length >= 1"
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 md6 pl-md-3>
                      <v-checkbox
                          :label="$t('tutorialDetail.item.elements.hidden') + '*'"
                          :input-value="item.hidden"
                          @change="setAttribute('hidden', $event)"
                          v-if="level === 0"
                      ></v-checkbox>
                    </v-flex>

                  <v-flex xs12 mt-2>
                        <div class="custom-label">{{ $t('tutorialDetail.item.elements.tags') }}</div>
                        <div class="d-flex flex-wrap">
                            <v-checkbox
                                    v-for="tag in availableTags"
                                    :key="tag.id"
                                    :input-value="hasTag(tag)"
                                    class="mx-2 mt-0"
                                    :color="tag.color"
                                    v-on:change="val => setTag(tag.id, val)"
                            >
                                <template v-slot:label>
                                    <v-chip
                                            class="mr-2 pointer"
                                            :color="tag.color"
                                            label
                                            text-color="white"
                                    >
                                        {{ tag.name }}
                                    </v-chip>
                                </template>
                            </v-checkbox>
                        </div>
                    </v-flex>
                </v-layout>

                <v-layout wrap v-if="level > 0">
                    <v-flex pr-3 v-if="item.isTask !== true">
                        <div class="custom-label">{{ $t('tutorialDetail.item.elements.userQuestions') }}*</div>
                        <v-input
                            :value="item.intents"
                            :rules="[rules.intents_rule]"
                            class="pointer text-field-line"
                            @click="openIntentsDialog()"
                        >
                            <span v-if="item.intents.length === 0">{{ $t('elements.none') }}</span>
                            <span v-else>{{ item | tutorialName }}</span>
                            <span v-if="item.intents.length > 1" class="ml-1">
                                <i>+ {{ item.intents.length - 1 }} {{ $t('elements.next1') }}</i>
                            </span>
                        </v-input>
                    </v-flex>

                    <v-flex pr-3 v-if="item.isTask !== true">
                        <v-text-field
                                :value="item.choice"
                                @input="setAttribute('choice', $event.trim())"
                                :label="$t('tutorialDetail.item.elements.buttonText') + '*'"
                                required
                                :rules="[rules.required_rule, rules.max_length_rule]"
                        ></v-text-field>
                    </v-flex>

                    <v-flex pr-3 class="flex-grow-1" v-if="item.isTask !== false">
                        <v-text-field
                                :value="item | tutorialName"
                                @input="setName($event.trim())"
                                :label="$t('tutorialDetail.item.elements.tutorialName') + '*'"
                                required
                                :rules="[rules.required_rule, rules.max_length_rule]"
                        ></v-text-field>
                    </v-flex>


                    <div class="text-right" v-if="currentUserCanEdit('tutorials') && item.id !== null">
                        <v-btn text color="red" v-on:click="attemptToDeleteNestedItem" tabindex="-1">{{ $t('buttons.delete') }}</v-btn>
                    </div>
                </v-layout>

                <v-layout wrap>
                    <v-flex xs12>
                        <rich-text-field
                            :label="$t('tutorialDetail.item.elements.itemText') + '*'"
                            :value="item.text"
                            @input="setAttribute('text', $event.trim())"
                            :rules="[required_rule]"
                        ></rich-text-field>
                    </v-flex>

                    <v-flex xs12 v-if="item.help !== null" >
                        <div class="grey lighten-5 pt-3 px-4 mb-3 d-flex">
                            <div class="flex-grow-1 mr-2">
                                <h5>
                                    <v-icon small>help</v-icon>
                                    {{ $t('tutorialDetail.item.elements.help') }}
                                </h5>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                                :value="item.help.header"
                                                @input="setAttribute('help.header', $event.trim())"
                                                :label="$t('tutorialDetail.item.elements.title')"
                                                :rules="[rules.max_length_rule]"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <rich-text-field
                                            :label="$t('tutorialDetail.item.elements.itemText') + '*'"
                                            :value="item.help.text"
                                            @input="setAttribute('help.text', $event.trim())"
                                            :rules="[rules.required_rule]"
                                        ></rich-text-field>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-btn text small color="black" icon v-on:click="setAttribute('help', null)">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </div>
                    </v-flex>

                    <v-flex xs12 v-if="item.warning !== null">
                        <div class="grey lighten-5 pt-3 px-4 mb-3 d-flex">
                            <div class="flex-grow-1 mr-2">
                                <h5>
                                    <v-icon small>warning</v-icon>
                                    {{ $t('tutorialDetail.item.elements.warning') }}
                                </h5>
                                <v-row>
                                    <v-col cols="12" sm="6">
                                        <v-text-field
                                                :value="item.warning.header"
                                                @input="setAttribute('warning.header', $event.trim())"
                                                :label="$t('tutorialDetail.item.elements.title')"
                                                :rules="[rules.max_length_rule]"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                        <rich-text-field
                                            :label="$t('tutorialDetail.item.elements.itemText') + '*'"
                                            :value="item.warning.text"
                                            @input="setAttribute('warning.text', $event.trim())"
                                            :rules="[rules.required_rule]"
                                        ></rich-text-field>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-btn text small color="black" icon v-on:click="setAttribute('warning', null)">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </div>
                    </v-flex>

                    <v-flex v-if="item.source !== null" cols="12" sm="4">
                        <div class="grey lighten-5 pt-3 px-4 mb-3 d-flex">
                            <div class="flex-grow-1 mr-2">
                                <v-text-field
                                        :value="item.source"
                                        @input="setAttribute('source', $event.trim())"
                                        :label="$t('tutorialDetail.item.elements.sources')"
                                        prepend-icon="language"
                                        :rules="[rules.url_rule, rules.max_length_rule]"
                                        @click:clear="setAttribute('source', null)"
                                ></v-text-field>
                            </div>
                            <v-btn text small color="black" icon v-on:click="setAttribute('source', null)">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </div>
                    </v-flex>

                    <v-flex v-if="item.dueDate !== null" cols="12" sm="4" pl-3>
                        <div class="grey lighten-5 pt-3 px-4 mb-3 d-flex">
                            <div class="flex-grow-1 mr-2">
                                <DatePicker
                                        label="Termín"
                                        :timestamp="item.dueDate"
                                        :clearable="true"
                                        :on-change="value => setAttribute('dueDate', value)"
                                ></DatePicker>
                            </div>
                            <v-btn text small color="black" icon v-on:click="setAttribute('dueDate', null)">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </div>

                    </v-flex>

                    <v-flex xs12>
                        <v-chip
                                v-for="id in item.contactIds"
                                v-bind:key="id"
                                class="ma-2 mt-0 mb-2 py-5"
                                color="grey lighten-4"
                                label
                                text-color="black"
                        >
                            <v-icon left>phone</v-icon>
                            {{ contactsMap[id] | localized('name', item.language) }}
                            <v-icon color="danger" class="pointer" right @click="detachContact(id)">close</v-icon>
                        </v-chip>

                        <v-btn @click="openContactDialog" text color="primary">
                            <v-icon left>add</v-icon> {{ $t('tutorialDetail.item.elements.contact') }}
                        </v-btn>

                        <v-btn v-if="!item.isTask && item.help === null" v-on:click="setAttribute('help', {header:'', text: ''})" color="primary" text>
                            <v-icon left>add</v-icon> {{ $t('tutorialDetail.item.elements.help') }}
                        </v-btn>

                        <v-btn v-if="!item.isTask && item.warning === null" v-on:click="setAttribute('warning', {header:'', text: ''})" color="primary" text>
                            <v-icon left>add</v-icon> {{ $t('tutorialDetail.item.elements.warning') }}
                        </v-btn>

                        <v-btn v-if="item.source === null" v-on:click="setAttribute('source', '')" color="primary" text>
                            <v-icon left>add</v-icon> {{ $t('tutorialDetail.item.elements.source') }}
                        </v-btn>

                        <!-- <v-btn v-if="item.dueDate === null" v-on:click="setAttribute('dueDate', '')" color="primary" text>
                            <v-icon left>add</v-icon> Termín
                        </v-btn> -->

                    </v-flex>
                </v-layout>

                <div v-if="item.isTask !== true">
                    <div v-if="children.length > 0">
                        <h4 class="mt-5 mb-2">{{ $t('tutorialDetail.item.elements.followUpItems') }}</h4>

                        <v-flex xs12 v-if="item.isTask !== true">
                            <v-text-field
                                    :value="item.followupQuestion"
                                    @input="setAttribute('followupQuestion', $event.trim())"
                                    :label="$t('tutorialDetail.item.elements.followUpQuestion') + '*'"
                                    required
                                    :rules="[rules.required_rule]"
                            ></v-text-field>
                        </v-flex>

                        <v-expansion-panels
                                :accordion="true"
                                :focusable="true"
                                :value="expandedPanelIndex"
                        >
                            <TutorialDetailItem
                                    v-bind:item="child"
                                    v-for="(child, index) in children"
                                    v-bind:key="`${index}`"
                                    :level="level+1"
                                    :index="index"
                                    :prefix="itemNumbering"
                                    v-on:valid-changed="$emit('valid-changed', $event)"
                                    :highlightedItemId="highlightedItemId"
                                    @save="callback => $emit('save', callback)"
                                    @highlightItemId="$emit('highlightItemId', $event)"
                            ></TutorialDetailItem>
                        </v-expansion-panels>
                    </div>

                    <v-btn
                            v-if="currentUserCanEdit('tutorials')"
                            text
                            color="primary"
                            v-on:click="() => addChildItem()"
                            class="mt-5"
                    >
                        + {{ $t('tutorialDetail.item.buttons.addFollowUpItem') }} {{newItemNumbering}}
                    </v-btn>
                </div>
            </div>

        </v-expansion-panel-content>

        <ChooseContact
                :model="isContactModalVisible"
                :exclude="item.contactIds"
                :language="item.language"
                @chooseContact="attachContact"
                @close="isContactModalVisible = false"
        />

        <Intents
                :model="isIntentModalVisible"
                :intents="item.intents"
                @updateIntents="updateIntents"
                @close="isIntentModalVisible = false"
        />

    </v-expansion-panel>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import Loading from "./Loading";
    import DatePicker from "./DatePicker";
    import ChooseContact from "./ChooseContact";
    import Intents from "./Intents";
    import {tutorialName} from "../filters/tutorialName";
    import {placeholder} from "../filters/placeholder";
    import {config} from "../config";
    import RichTextField from "@/components/RichTextField";

    export default {
        name: "TutorialDetailItem",
        components: {Loading, DatePicker, ChooseContact, Intents, RichTextField},
        props: {
            item: Object,
            level: Number,
            flat: Boolean,
            index: Number,
            prefix: String,

            /**
             * Change this prop to manually expand all the panels leading to the item with the given ID.
             * Expands all panels recursively in the tree structure.
             */
            highlightedItemId: {
                type: Number
            },

            /**
             * If set to true, arrows will displayed to manually change the order of items.
             * If this property is true, then you must set count prop to be equal to the number of displayed items.
             */
            enableOrdering: {
                type: Boolean,
                default: false
            },

            /**
             * If enableOrdering is true, then this property must be set to the number of displayed items.
             */
            count: {
                type: Number,
                default: 0
            }
        },

        watch: {
            highlightedItemId(id) {
                // if the highlighted item is the current one, scroll to it automatically
                if (id === this.item.id) {
                    // wait before scrolling for the item to expand first
                    setTimeout(() => 
                        this.$refs.panel.$el.scrollIntoView({behavior: 'smooth', block: 'center'})
                    , 500); // 500ms works fine for now, but if some slower devices need more time, increase it; it should always be lower than in TutorialDetail.save.
                }
            },
        },

        computed: {
            children() {
                return this.getNestedItems(this.item);
            },
            itemNumbering() {
                if (this.level > 0) {
                    if (this.prefix !== "") {
                        return this.prefix + (this.index + 1) + ".";
                    } else {
                        return this.index + 1 + ".";
                    }
                } else {
                    return "";
                }
            },
            newItemNumbering() {
                if (this.itemNumbering !== "") {
                    return this.itemNumbering + (this.children.length + 1) + ".";
                } else {
                    return this.children.length + 1 + ".";
                }
            },

            availableTags() {
                const language = this.item.language;
                return this.localizedTags(language);
            },

            /**
             * If some item should be expanded via the highlightedItemId prop,
             * then this property contains the index of the related expansion panel
             * that directly contains the highlighted item or contains the highlighted item in one of its children.
             */
            expandedPanelIndex() {
                if (this.highlightedItemId === null) {
                    return null;
                }

                const highlightedItem = this.itemById(this.highlightedItemId);

                if (highlightedItem) {
                    return this.children.findIndex(child =>
                        this.isParent(child, highlightedItem) || child.id === this.highlightedItemId
                    );
                }

                return null;
            },

            textErrors() {
              const result = this.required_rule(this.item.text);
              console.log(this.item.text, result);
              return result === true ? [] : [result];
            },

            rules() {
                return {
                    required_rule: v => typeof v === "string" && !!v.trim() || this.$t('rules.required'),
                    max_length_rule: v => (!v || v.length <= 255) || this.$t('rules.maxChars', [255]),
                    url_rule: v => !v || /^http(s)?:\/\/[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&%'()*+,;=.]+$/.test(v) || this.$t('rules.wrongUrlFormat'),
                    intents_rule: v => v && v.length >= config.tutorialIntentLimit || this.$t('rules.fewQuestions'),
                }
            },

            ...mapGetters('tutorials', ['getNestedItems', 'prepareNewItem', 'prepareNewIntent','getTranslations', 'itemById', 'isParent']),
            ...mapGetters('tags', ['localizedTags']),
            ...mapGetters('contacts', ['contacts', 'contactsMap']),
            ...mapGetters('user', ['currentUserCanEdit']),
        },

        data() {
            return {
                modal: false,
                isBeingDeleted: false,
                isContactModalVisible: false,
                isIntentModalVisible: false,
            };
        },

        methods: {
            setName(name) {
                if (this.item.intents.length === 0) {
                    const intent = this.prepareNewIntent(true);
                    this.setAttribute('intents', [intent]);
                }
                this.setAttribute('intents.0.text', name);
            },

            addChildItem() {
                if (this.item.isTemporary) {
                    if (confirm(this.$t('tutorialDetail.item.elements.saveChangesBeforeAddingNewItemConfirm'))) {
                        this.$emit('save', () => this.addChildItem());
                    }
                } else {
                    const newChild = this.prepareNewItem(this.item);
                    this.addItem({item: newChild});
                    this.$nextTick(() => this.$emit('highlightItemId', newChild.id));
                }
            },

            attemptToDeleteNestedItem() {
                const name = placeholder(tutorialName(this.item), this.$t('elements.noTitle'));
                if (confirm(this.$t('tutorialDetail.item.elements.saveChangesBeforeAddingNewItemConfirm', [name]))) {
                    if (!this.item.isTemporary) {
                        this.isBeingDeleted = true; // show loading indicator only for items already persisted on backend, others are deleted immediatelly from the Vuex store
                    }
                    this.deleteItem({item: this.item});
                }
            },

            setAttribute(key, value) {
                this.setItemAttribute({item: this.item, key, value});
                this.makeChanges();
            },

            attachContact(contactId) {
                const newContactIds = this.item.contactIds.slice();
                newContactIds.push(contactId);
                this.setAttribute('contactIds', newContactIds);
                this.isContactModalVisible = false;
            },

            detachContact(contactId) {
                const index = this.item.contactIds.indexOf(contactId);
                const newContactIds = this.item.contactIds.slice();
                newContactIds.splice(index, 1);
                this.setAttribute('contactIds', newContactIds);
            },

            hasTag(tag) {
                return this.item.tagIds.includes(tag.id) ? tag.id : '';
            },

            setTag(tagId, selected) {
                const index = this.item.tagIds.indexOf(tagId);
                const newTagIds = this.item.tagIds.slice();

                if (selected) {
                    newTagIds.push(tagId);
                } else {
                    newTagIds.splice(index, 1);
                }

                this.setAttribute('tagIds', newTagIds);
            },

            openContactDialog() {
                this.isContactModalVisible = true;
            },

            openIntentsDialog() {
                this.isIntentModalVisible = true;
            },

            updateIntents(intents) {
                this.setAttribute('intents', intents);
            },

            ...mapActions('tutorials', ['createItem', 'deleteItem', 'setItemAttribute', 'addItem', 'moveItem']),
            ...mapActions('changeTracking', ['makeChanges'])
        }
    };
</script>

<style>
    .v-expansion-panel.flat:before {
        box-shadow: none;
    }
    .v-expansion-panel.flat .v-expansion-panel-content__wrap {
        padding: 0 !important;
    }
</style>

<style scoped>
    tr {
        cursor: pointer;
    }
</style>
