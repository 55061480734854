export const setAttributeByPath = (object, path, value) => {
    const parts = path.split('.');
    parts.slice(0, -1).reduce((o,i)=>o[i], object)[parts.slice(-1)] = value;
};

export const sanitize = (str) => {
    return String(str).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
};

String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
}