<template>
    <v-dialog
            :value="model"
            scrollable
            max-width="400px"
            :persistent="true"
    >
        <v-card>
            <v-card-title class="header">{{ $t('tutorialDetail.translate.title') }}</v-card-title>
            <v-divider/>
            <v-card-text class="pt-4">
                <p>{{ $t('tutorialDetail.translate.description') }}:</p>
                <v-select v-model="targetLanguage" :items="langItems" :placeholder="$t('tutorialDetail.translate.selectTargetLang')" required>
                </v-select>
            </v-card-text>
            <v-divider/>
            <v-card-actions>
                <v-spacer/>
                <v-btn
                color="dark"
                        text
                        @click="close"
                >
                    {{ $t('buttons.cancel') }}
                </v-btn>
                <v-btn
                        color="primary"
                        text
                        @click="translate"
                        :disabled="!targetLanguage"
                        :depressed="!targetLanguage"
                        :loading="loading"
                >
                    {{ $t('tutorialDetail.translate.buttons.translate') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";

    export default {
        name: "TutorialTranslateItem",

        data() {
            return {
                loading: false,
                targetLanguage: null,
            }
        },

        props: {
            model: {type: Boolean, default: false},
            mainItemId: Number,
            language: String
        },

        computed: {
            ...mapGetters('tutorials', ['getTranslations', 'itemById']),

            mainTutorial() {
                return this.itemById(this.mainItemId);
            },

            langItems() {
                let availableTranslateLanguages = ['en', 'ru', 'uk'];
                const existingLanguages = this
                    .getTranslations(this.mainTutorial)
                    .map(translation => translation.language);
                return availableTranslateLanguages.filter(language => !existingLanguages.includes(language));
            },
        },

        methods: {
            close() {
                this.$emit('close');
            },

            async translate() {
                this.loading = true;
                await this.translateItem({id: this.mainItemId, language: this.targetLanguage}).then((response) => {
                    this.$router.push(`/tutorials/${response}`);
                });
            },

            ...mapActions('tutorials', ['translateItem']),
        }
    }
</script>

<style scoped>

</style>
