import axios from 'axios';
import {config} from './../../config';
import {normalize, schema} from 'normalizr';
import Vue from "vue";
import i18n from '@/plugins/i18n/i18n';

const state = {
    didLoadedData: false,
    reports: [],
    count: 0,
};

const report = new schema.Entity('reports');

const getters = {
    reports: state => Object.values(state.reports).sort((a, b) => a.id - b.id),
    count: state => state.count,
    emptyReport: () => ({
        faqItemId: null,
        description: "",
        note: ""
    }),
    didLoadedData: state => state.didLoadedData
};

const actions = {

    async loadReports({commit}, {page, itemsPerPage, archived}) {
        commit('didLoadedData', false);
        const response = await axios.get(`${config.serverUrl}faq/reports?archived=${archived}&page=${page}&itemsPerPage=${itemsPerPage}`)
        commit('setReports', response.data.items);
        commit('setCount', response.data.count);
        commit('didLoadedData', true);
    },

    async createReport({ commit }, { report }) {
        try {
            const response = await axios.post(config.serverUrl + 'faq/reports/', report);
            const createdReport = response.data.content;
            commit('addReport', createdReport);
            return createdReport;
        } catch(e) {
            console.error(e);
            alert(i18n.t('elements.addErrorAlert'));
        }
    },

    async updateReport({ commit }, { report }) {
        try {
            const response = await axios.put(config.serverUrl + `faq/reports/${report.id}`, report);
            const updatedReport = response.data.content;
            commit('updateReport', updatedReport);
            return updatedReport;
        } catch(e) {
            console.error(e);
            alert(i18n.t('elements.saveErrorAlert'));
        }
    },

    async deleteReports({commit}, reports) {
        commit('didLoadedData', false);
        try {
            await Promise.all(reports.map(async report => {
                await axios.delete(config.serverUrl + `faq/reports/${report.id}`);
                commit('deleteReport', report);
            }));
            commit('didLoadedData', true);
        } catch(e) {
            console.error(e);
            alert(i18n.t('elements.deleteErrorAlert'));
        }
    }

};

const mutations = {
    didLoadedData (state, loaded) {
        state.didLoadedData = loaded;
    },

    setReports(state, reports) {
        const normalized = normalize(reports, [report]);
        if (normalized.entities !== undefined && normalized.entities.reports !== undefined) {
            state.reports = normalized.entities.reports;
        } else {
            state.reports = {};
        }
    },

    setCount(state, count) {
        state.count = count;
    },

    addReport(state, report) {
        Vue.set(state.reports, report.id, report);
        state.count += 1;
    },

    updateReport(state, report) {
        Vue.set(state.reports, report.id, report);
    },

    deleteReport(state, report) {
        Vue.delete(state.reports, report.id);
        state.count -= 1;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
