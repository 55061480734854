import axios from "axios";
import {config} from '../../config';

const actions = {
    async createIntent(_, { item }) {
        return axios.post(config.serverUrl + 'faq/items/intents/', item)
    }
}

export default {
    namespaced: true,
    actions,
}