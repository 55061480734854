import Vue from "vue";
import VueI18n from "vue-i18n";
import cs from "./locales/cs";
import en from "./locales/en";

Vue.use(VueI18n);

const availableLocales = ["cs", "en"];
const browserLocale = navigator.language.split('-')[0];
const fallbackLocale = 'cs';

const i18n = new VueI18n({
  locale: availableLocales.includes(browserLocale) ? browserLocale : fallbackLocale,
  fallbackLocale,
  availableLocales,
  messages: { cs, en },
});

export default i18n;

// Hot updates
if (module.hot) {
  module.hot.accept(['./locales/cs'], function () {
    i18n.setLocaleMessage('cs', require('./locales/cs').default)
  });
  module.hot.accept(['./locales/en'], function () {
    i18n.setLocaleMessage('en', require('./locales/en').default)
  })
}