<template>
  <div>
    <v-chart class="chart" :option="option" :style="{ height: graphHeight + 'px', width: graphWidth + 'px'}"></v-chart>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import {BarChart} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default {
  name: "BarChartHorizontal",
  props: {
    titleText: {type: String, default: ""},
    yAxisData: {type: Array, default: ()=>[]},
    series: {type: Array, default: ()=>[]},
    graphHeight: {type: String, default: "500"},
    graphWidth : {type: String, default: "1200"},
    color: {type: Array, default: ()=>["#F44336", "#FF9800", "#4CAF50", "#2196F3"]},
    axisLabelShow: {type: Boolean, default: true},
    axisLabelWidth: {type: Number, default: 500}
  },
  components: {VChart},
  provide: {
    [THEME_KEY]: "light",
  },
  data() {
    return {
      option: {
        title: {
          text: this.titleText,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: this.axisLabelWidth.toString(),
          right: '4%',
          bottom: '3%',
          containLabel: false
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          data: this.yAxisData,
          axisLabel: {
            width: this.axisLabelWidth,
            show: this.axisLabelShow,
            overflow: 'truncate',
          },
        },
        series: this.series,
        color: this.color,
      }
    }
  },
}


</script>

<style scoped>
.chart{
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>