<template>
    <v-dialog
            ref="dialog"
            v-model="modal"
            full-width
            width="290px"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                    :value="formattedDate"
                    :label="label"
                    prepend-icon="event"
                    readonly
                    :clearable="clearable"
                    @click:clear="clear"
                    v-on="on"
            ></v-text-field>
        </template>
        <v-date-picker
                :value="formattedDateForDatePicker"
                @change="didSelectDate"
                scrollable
                locale="cs"
        >
        </v-date-picker>
    </v-dialog>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "DatePicker",

        props: {
            label: String,
            timestamp: Number, // date as unix timestamp
            onChange: Function,
            clearable: { type: Boolean, default: false },
        },

        data() {
            return {
                modal: false,
                date: null,
            }
        },

        computed: {
            formattedDate() {
                const date = moment(this.timestamp);
                if (date.isValid()) {
                    return date.format("ll");
                } else {
                    return "";
                }
            },
            formattedDateForDatePicker() {
                const date = moment(this.timestamp);
                if (date.isValid()) {
                    return date.format("YYYY-MM-DD");
                } else {
                    return "";
                }
            },
        },

        methods: {
            clear() {
                this.onChange(null);
            },
            didSelectDate(formattedDate) {
                const unixTimestamp = parseInt(moment(formattedDate).format('x'));
                this.onChange(unixTimestamp);
                this.modal = false;
            }
        }
    }
</script>

<style scoped>

</style>
