<template>
    <v-dialog
            :value="model"
            @input="v => !v && close()"
            width="500"
            content-class="choose-contact-dialog"
            scrollable
            @click:outside="close"
    >
        <v-card class="flex-grow-1">
            <v-card-title class="headline">
                {{ $t('contactDetail.chooseContact.elements.selectContact') }}
            </v-card-title>

            <v-divider/>

            <v-card-text class="pt-4">
                <Loading v-if="didLoadedData === false"></Loading>
                <div v-else>
                    <v-text-field 
                        :label="$t('contactDetail.chooseContact.elements.searchContact')"
                        prepend-icon="search"
                        clearable
                        v-model="searchQuery"
                     />

                    <div v-if="filteredContacts.length === 0">{{ $t('contactDetail.chooseContact.elements.noContact') }}</div>
                    
                    <v-list v-else :model="null">
                        <v-list-item
                            v-for="contact in filteredContacts"
                            :key="contact.id"
                            @click="chooseContact(contact)"
                            :input-value="false"
                        >
                            <v-list-item-avatar dark color="primary">
                                <v-icon dark>perm_contact_calendar</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <v-list-item-title>{{ contact | localized('name', language) }}</v-list-item-title>
                                    <v-list-item-subtitle class="d-flex flex-wrap mt-1">
                                        <div class="mr-2" v-if="contact.phone"><v-icon small class="mr-1">call</v-icon>{{contact.phone }}</div>
                                        <div class="mr-2" v-if="contact.email"><v-icon small class="mr-1">email</v-icon>{{contact.email }}</div>
                                        <div class="mr-2" v-if="contact.address"><v-icon small class="mr-1">home</v-icon>{{contact.address }}</div>
                                    </v-list-item-subtitle>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn right color="dark" text @click="close">{{ $t('buttons.cancel') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapGetters} from "vuex";
    import Loading from "./Loading";
    import {languages} from "../config";

    export default {
        name: "ChooseContact",
        components: {Loading},

        props: {
            exclude: Array,
            model: Boolean,
            language: {
                type: String,
                default: languages[0]
            }
        },

        data() {
            return {
                searchQuery: ""
            };
        },

        watch: {
            searchQuery() {
                
            },
        },

        computed: {
            filteredContacts() {
                const data = this.searchQuery ? this.matchingContacts(this.searchQuery) : this.contacts;
                return data.filter(c => this.exclude.includes(c.id) === false);
            },
            ...mapGetters("contacts", ["didLoadedData", "contacts", "matchingContacts"])
        },

        methods: {
            chooseContact(contact) {
                this.$emit('chooseContact', contact ? contact.id : null);
            },
            close() {
                this.$emit('close');
            },
        }
    }
</script>

<style>

.choose-contact-dialog {
    /* Enforce the dialog to be always over the full screen height to prevent jumping when searching. */
    height: 100% !important; 
}

</style>
