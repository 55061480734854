import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n/i18n';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import './filters/ellipsis';
import './filters/languageName';
import './filters/localized';
import './filters/similarIntentRules';
import './filters/tutorialName';
import './filters/questionName';
import './filters/isEmpty';
import './filters/placeholder';
import './filters/userRoles';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import store from './store/index';
import moment from 'moment';
import { Icon }  from 'leaflet'
import 'leaflet/dist/leaflet.css'
import QuerySynchronizer from 'vue-query-synchronizer/src/library';
import axios from "axios";

// env
Vue.config.productionTip = false;

// must add cache header to all REST API requests to bypass cache and always have up-to-date data
axios.defaults.headers.common['Cache-Control'] = "no-cache";

// Leaflet
// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

// Telephone Input
Vue.use(VueTelInputVuetify, {
  vuetify
});

// Query Props Synchronizer
Vue.use(QuerySynchronizer, {
  router: router,
  navigationOperation: 'replace',
});

// store init - check if the user is logged in
store.dispatch('user/restoreLogin');

// date time
const locale = window.navigator.language || 'cs';
moment.locale(locale);

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
