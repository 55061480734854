<template>
    <v-btn id="save-button" v-if="isSaving" outlined color="dark" :disabled="true">
        {{ $t('buttons.saving') }}...
    </v-btn>
    <v-btn id="save-button" v-else-if="hasRecentlyUpdated && success" outlined color="success" @click="onClick" :disabled="disabled">
      <v-icon left>check</v-icon> {{ $t('buttons.saved') }}
    </v-btn>
    <v-btn id="save-button" v-else-if="hasRecentlyUpdated && !success" outlined color="danger" @click="onClick" :disabled="disabled">
      <v-icon left>mood_bad</v-icon> {{ $t('buttons.error') }}
    </v-btn>
    <v-btn id="save-button" v-else color="success" @click="onClick" :disabled="disabled">
        {{ label || $t('buttons.save') }}
    </v-btn>
</template>

<script>
    export default {
        name: 'SaveButton',

        props: {
            disabled: Boolean,
            isSaving: Boolean,
            label: String,
            success: {
              type: Boolean,
              default: true,
            },
        },

        data() {
            return {
                hasRecentlyUpdated: false,
            }
        },

        watch: {
            isSaving: function(newValue, oldValue) {
                if (oldValue && !newValue) {
                    this.hasRecentlyUpdated = true;
                    setTimeout(() => this.hasRecentlyUpdated = false, 2000);
                }
            }
        },

        methods: {
            onClick() {
                this.$emit('click');
            }
        }
    }
</script>

<style scoped>

</style>
