import Vue from 'vue';

export const questionName = tutorial => {
    const mainIntent = tutorial.find(i => i.main);

    if (mainIntent !== undefined && mainIntent.text) {
        return mainIntent.text;
    }

    return null;
};

Vue.filter('questionName', questionName);