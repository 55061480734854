<template>
    <div>
        <Loading v-if="!didLoadedData || isDeleting || chatbot === null"></Loading>

        <v-form v-model="valid" ref="form" v-else>

            <detail-header :show-back="true" backRouteName="chatbots">
                <h2 class="chatbot-name">
                    <span :class="`icon-flag ${chatbot.language}`"></span> 
                    {{localizedChatbotName(chatbot.language)}}
                </h2>

                <v-spacer></v-spacer>

                <save-button v-if="currentUserCanEdit('chatbots')" :is-saving="isSaving" @click="save()"></save-button>
            </detail-header>

            <div class="px-6 pb-4">
                <h2 class="mt-6 mb-3">
                    {{ $t('chatbotDetail.elements.mainParams') }}
                    <v-tooltip right max-width="400">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"

                            >mdi-help-circle-outline</v-icon>
                        </template>
                        <span>{{ $t('chatbotDetail.elements.parametersExplanation') }}</span>
                    </v-tooltip>
                </h2>

                <v-card
                        class="mx-auto"
                        max-width="1400"
                        tile
                >
                    <ChatbotDetailItem
                            :chatbot="chatbot"
                            v-bind:item="chatbot.fullGreet"
                            v-on:valid-changed="v => valid = v"
                            :msg="attributeTranslation['fullGreet']"
                            param="fullGreet"

                    ></ChatbotDetailItem>
                    <ChatbotDetailItem
                            :chatbot="chatbot"
                            v-bind:item="chatbot.shortGreet"
                            v-on:valid-changed="v => valid = v"
                            :msg="attributeTranslation['shortGreet']"
                            param="shortGreet"
                    ></ChatbotDetailItem>
                    <ChatbotDetailItem
                            :chatbot="chatbot"
                            v-bind:item="chatbot.goodbye"
                            v-on:valid-changed="v => valid = v"
                            :msg="attributeTranslation['goodbye']"
                            param="goodbye"
                    ></ChatbotDetailItem>
                    <ChatbotDetailItem
                            :chatbot="chatbot"
                            v-bind:item="chatbot.fallback"
                            v-on:valid-changed="v => valid = v"
                            :msg="attributeTranslation['fallback']"
                            param="fallback"
                    ></ChatbotDetailItem>
                    <ChatbotDetailItem
                            :chatbot="chatbot"
                            v-bind:item="chatbot.happy"
                            v-on:valid-changed="v => valid = v"
                            :msg="attributeTranslation['happy']"
                            param="happy"
                    ></ChatbotDetailItem>
                    <ChatbotDetailItem
                            :chatbot="chatbot"
                            v-bind:item="chatbot.unhappy"
                            v-on:valid-changed="v => valid = v"
                            :msg="attributeTranslation['unhappy']"
                            param="unhappy"
                    ></ChatbotDetailItem>
                    <ChatbotDetailItem
                            :chatbot="chatbot"
                            v-bind:item="chatbot.restart"
                            v-on:valid-changed="v => valid = v"
                            :msg="attributeTranslation['restart']"
                            param="restart"
                    ></ChatbotDetailItem>

                </v-card>

                <h2 class="mt-6 mb-3">
                    {{ $t('chatbotDetail.elements.unusualQuestions') }}
                    <v-tooltip right max-width="400">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                    color="primary"
                                    dark
                                    v-bind="attrs"
                                    v-on="on"

                            >mdi-help-circle-outline</v-icon>
                        </template>
                        <span>
                            {{ $t('chatbotDetail.elements.unusualQuestionsExplanation') }}
                        </span>
                    </v-tooltip>
                </h2>
                <v-card
                        class="mx-auto pt-3"
                        max-width="1400"
                        tile
                >
                    <div v-if="chatbot.unusualQuestions.length === 0" class="px-3 pb-3">{{ $t('elements.none') }}</div>
                    <ChatbotUnusualQuestions
                            v-else
                            :chatbot="chatbot"
                            v-for="(question, index) in chatbot.unusualQuestions"
                            :key="index"
                            :index="index"
                            :item="question"
                            v-on:valid-changed="v => valid = v"
                            :default-opened="openedUnusualQuestionIndex === index"
                            @close="openedUnusualQuestionIndex = null"
                    ></ChatbotUnusualQuestions>
                </v-card>

                <v-btn
                        v-if="currentUserCanEdit('chatbots')"
                        text
                        class="mt-2"
                        color="primary"
                        v-on:click="() => pushNewQuestion()"
                        >
                    + {{ $t('chatbotDetail.buttons.addUnusualQuestion') }}
                </v-btn>

            </div>

        </v-form>
    </div>
</template>

<script>
    import ChatbotUnusualQuestions from "../components/ChatbotUnusualQuestions";
    import ChatbotDetailItem from "../components/ChatbotDetailItem";
    import Loading from "../components/Loading";
    import {mapActions, mapGetters} from "vuex";
    import {questionName} from "../filters/questionName";
    import DetailHeader from '../components/DetailHeader.vue';
    import {config} from "../config";
    import SaveButton from "@/components/SaveButton";

    export default {
        name: "ChatbotDetail",

        components: {
          SaveButton,
            Loading,
            ChatbotDetailItem,
            ChatbotUnusualQuestions,
            DetailHeader
        },

        props: {
            msg: String,
            param: String
        },

        mounted() {
            this.load();
        },

        beforeRouteUpdate (to, from, next) {
            next();
            this.load();
        },

        data() {
            return {
                chatbot:
                    {
                        "fullGreet": {
                            "intents": []
                        },
                        "shortGreet": {
                            "intents": []
                        },
                        "goodbye": {
                            "intents": []
                        },
                        "fallback": {
                            "intents": []
                        },
                        "happy": {
                            "intents": []
                        },
                        "unhappy": {
                            "intents": []
                        },
                        "restart": {
                            "intents": []
                        },
                        "unusualQuestions": [
                            {
                                "answers" : [
                                    {
                                        "intents" : []
                                    }
                                ],
                                "questions": [
                                    {
                                        "intents" : []
                                    }
                                ],
                            }
                        ]
                    }
                ,
                valid: false,
                isSaving: false,
                isDeleting: false,
                openedUnusualQuestionIndex: null,
            }
        },

        computed: {
            chatbotId() { return parseInt(this.$route.params.id)},
            didLoadedData() { return this.$store.state.chatbots.didLoadedData; },
            attributeTranslation() {
                return {
                    "fullGreet":  this.$t('chatbotDetail.intents.fullGreet'),
                    "shortGreet": this.$t('chatbotDetail.intents.shortGreet'),
                    "goodbye": this.$t('chatbotDetail.intents.goodbye'),
                    "fallback": this.$t('chatbotDetail.intents.fallback'),
                    "happy":this.$t('chatbotDetail.intents.happy'),
                    "unhappy": this.$t('chatbotDetail.intents.unhappy'),
                    "restart":this.$t('chatbotDetail.intents.restart'),
                }
             },
             
            ...mapGetters('chatbots', ['itemById', 'prepareNewQuestion']),
            ...mapGetters('user', ['currentUserCanEdit']),
        },

        methods: {
            load() {
                this.loadChatbots(() => {
                    this.chatbot = this.itemById(this.chatbotId);
                });
            },

            save() {
                // iterate all attributes that contain some intents and check their counts
                for (const key of Object.keys(this.chatbot)) {
                    if (typeof this.chatbot[key].intents !== "undefined" && this.chatbot[key].intents.length < config.chatbotIntentLimit) {
                        alert(this.$t('chatbotDetail.elements.requireMinSentenceAlert', [config.chatbotIntentLimit, this.attributeTranslation[key]]));
                        return;
                    }
                }

                for (const question of this.chatbot.unusualQuestions) {
                    if ((question.questions.length < config.chatbotIntentLimit) || (question.answers.length < config.chatbotIntentLimit)) {
                        const nameOfQuestion = (questionName(question.questions) == null) ? this.$t('elements.noTitle') : questionName(question.questions);
                        alert(this.$t('chatbotDetail.elements.requireMultipleVariantsForUnusual', [nameOfQuestion]));
                        return;
                    }
                }

                if (!this.$refs.form.validate()) {
                    alert(this.$t('elements.invalidFormAlert'));
                    return;
                }

                this.isSaving = true;
                this.saveBot({ chatbot: this.chatbot, onSuccess: id => {
                        this.resolveChanges();
                        this.isSaving = false;
                        this.$router.replace('/chatbots/' + id);
                    }, onFailure: () => {
                        this.isSaving = false;
                    }});
            },

            pushNewQuestion() {
                let value = this.prepareNewQuestion(true);
                const copy = this.chatbot.unusualQuestions.slice();
                copy.push(value);
                this.setItemAttribute({item: this.chatbot, key: 'unusualQuestions', value: copy});

                // open the modal dialog immediately after the new question is rendered
                this.$nextTick(function() {
                    this.openedUnusualQuestionIndex = copy.length - 1; 
                });
            },

            localizedChatbotName(language) {
                const languageName = this.$options.filters.languageName(language);
                return `${languageName}`
            },

            ...mapActions('chatbots', ['loadChatbots', 'saveBot', 'addItem', 'setItemAttribute']),
            ...mapActions('changeTracking', ['resolveChanges'])

        }
    };
</script>

<style scoped>
    tr {
        cursor: pointer;
    }

    .chatbot-name {
        text-transform: capitalize;
    }

    .tutorial-header {
        box-sizing: border-box;
        z-index: 3;
        width: 100%;
        background: #efefef;
        position: sticky;
        box-shadow: rgba(0, 0, 0, 0.15) 0 0 5px;
        border-bottom: 1px solid lightgray;
        padding: 0.8rem 1.6rem 0.8rem;
        top: 65px;
    }

    @media (max-width: 960px) {
        .tutorial-header {
            top: 55px;
        }
    }

    .tutorial-header h2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 60vw;
    }
    @media (max-width: 959px) {
        .tutorial-header h2 {
            max-width: 50vw;
        }
    }
</style>
