<template>
  <v-dialog
      :value="model"
      width="750"
      :scrollable="true"
      :persistent="true"
  >
    <v-card>
      <v-card-title class="headline">
        {{ $t('intents.questions.elements.questions') }}
      </v-card-title>

      <v-divider/>

      <v-card-text class="pt-4">
        <v-form ref="form">
          <div class="d-flex align-center mb-2">
            <v-alert type="info" class="d-flex my-0">
              {{ $t('intents.questions.elements.enterMultipleVariants2') }}
            </v-alert>
            <div class=
                     "ml-4 d-flex flex-column align-center justify-center">
              <v-progress-circular
                  class="white"
                  :rotate="360"
                  :size="50"
                  :width="8"
                  :value="intentQualityScore"
                  :color="intentQualityScoreColor"
              >
                <b>{{ this.intentQualityScore }}</b>
              </v-progress-circular>
              {{ $t('intents.questions.elements.score') }}
            </div>
          </div>

          <h4>{{ $t('intents.questions.elements.mainQuestion') }}</h4>

          <div class="d-flex" v-if="mainIntent">
            <v-text-field
                v-model.trim="mainIntent.text"
                :placeholder="placeholder(0)"
                :rules="rules.requiredRules"
                @keyup="() => {
                                    addNewIntentIfNecessary(); 
                                    validate(); /* validate to update similar intents warnings at other fields */ 
                                }"
                @keydown.enter="jumpToIntent(1)"
                autofocus
            ></v-text-field>
          </div>

          <h4>{{ $t('intents.questions.elements.alternativeQuestionWording', [intentLimit - 1]) }}</h4>

          <div v-for="(intent, i) in alternativeIntents" :key="i" class="d-flex">
            <v-text-field
                ref="intent"
                v-model.trim="intent.text"
                :placeholder="placeholder(i + 1)"
                :error="intent | hasSimilarIntent(modifiedIntents)"
                :rules="intent | similarIntentRules(modifiedIntents)"
                @keyup="() => {
                                    addNewIntentIfNecessary(); 
                                    validate(); /* validate to update similar intents warnings at other fields */ 
                                }"
                @keydown.enter="jumpToIntent(i + 2)"
            ></v-text-field>
            <v-icon
                v-if="intentCount > intentLimit + 1"
                color="gray"
                @click="removeIntent(i + 1)"
                class="ml-4"
            >
              mdi-close
            </v-icon>
          </div>

          <h4>{{ $t('userMessages.title') + $t('userMessages.obtained') }}</h4>
          <div v-if="messagesByFaqId.length === 0">
            {{ $t('userMessages.elements.noMessages') }}
          </div>

          <div v-else>
            <v-list>
              <v-list-item
                  v-for="message in messagesByFaqId"
                  :key="message.id"
              >
                <template v-if="!message.toBeArchived">
                  <v-list-item-icon
                      v-if="currentUserCanAccess('userMessages')"
                  >
                    <v-btn
                        class="material-symbols-outlined"
                        fab
                        dark
                        x-small
                        color="primary"
                        @click="addUserMessageToIntents(message)"
                    >
                      <v-icon dark>
                        add
                      </v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title style="font-weight: bold; display: block" v-text="message.count + 'x '"/>
                    <v-list-item-title v-text="message.text" style="white-space: pre-wrap"/>
                  </v-list-item-content>
                  <v-list-item-icon
                      v-if="currentUserCanAccess('userMessages')"
                  >
                    <v-btn
                        class="material-symbols-outlined"
                        fab
                        dark
                        x-small
                        color="grey"
                        @click="archive(message)"
                    >
                      <v-icon dark>
                        close
                      </v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </template>

              </v-list-item>
            </v-list>
          </div>
          <div>
          </div>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn right color="dark" text @click="close">{{ $t('buttons.cancel') }}</v-btn>
        <v-btn v-if="currentUserCanEdit('tutorials')" color="primary" @click="save">{{ $t('buttons.ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {isEmpty} from "../filters/isEmpty";
import {config} from "../config";
import {nlpUtils} from "../utils/nlp";

export default {
  name: "Intents",

  props: {
    model: {type: Boolean, default: false},
    intents: {type: Array, default: () => []},
    userMessages: {type: Array, default: () => []},
  },

  data() {
    return {
      modifiedIntents: [],
      selectedUserMessage: undefined,
    }
  },

  watch: {
    model: function (val) {
      if (val) {
        this.modifiedIntents = this.prepareIntents();
      }
    }
  },

  mounted() {
    this.loadAllMessagesByFaqId({faqId: this.$route.params.id});
  },

  computed: {
    intentLimit() {
      return config.tutorialIntentLimit;
    },
    intentCount() {
      return this.modifiedIntents.length;
    },
    mainIntent() {
      return this.modifiedIntents.find(i => i.main);
    },
    alternativeIntents() {
      return this.modifiedIntents.filter(i => !i.main);
    },

    /**
     * Returns intents that are not empty after trimming whitespaces.
     */
    validIntents() {
      return this.modifiedIntents.filter(i => i.text.trim() !== "");
    },

    intentQualityScore() {
      return nlpUtils.intentQualityScore(this.validIntents);
    },

    intentQualityScoreColor() {
      return nlpUtils.intentQualityScoreColor(this.validIntents, config.tutorialIntentLimit);
    },

    rules() {
      return {
        requiredRules: [v => !isEmpty(v) || this.$t('rules.requiredItem')],
      }
    },

    placeholders() {
      return this.$t('intents.questions.elements.questionPlaceholders2');
    },

    ...mapGetters('tutorials', ['prepareNewIntent']),
    ...mapGetters('user', ['currentUserCanEdit', 'currentUserCanAccess']),
    ...mapGetters('messagesByFaqId', ['messagesByFaqId']),

  },

  methods: {
    prepareIntents() {
      const intents = this.intents.map(i => ({...i})); // copy for editing

      const limit = intents.length < config.tutorialIntentLimit ? config.tutorialIntentLimit : intents.length + 1;

      intents.sort((a, b) => a.id - b.id);

      for (let i = intents.length; i < limit; i++) {
        intents.push(this.prepareNewIntent());
      }

      if (intents.find(i => i.main) === undefined) {
        intents[0].main = true;
      }

      return intents;
    },
    addNewIntent() {
      this.modifiedIntents.push(this.prepareNewIntent());
    },
    addNewIntentIfNecessary() {
      const noEmptyIntent = this.modifiedIntents.find(i => !i.text) === undefined;
      if (noEmptyIntent || this.modifiedIntents.length < config.tutorialIntentLimit) {
        this.addNewIntent();
      }
    },
    placeholder(index) {
      if (index >= this.placeholders.length) {
        return `${this.$t('intents.questions.elements.nextQuestion')}...`;
      } else {
        return this.placeholders[index];
      }
    },
    removeIntent(index) {
      this.modifiedIntents.splice(index, 1);
      this.addNewIntentIfNecessary();
    },
    jumpToIntent(index) {
      const ref = this.$refs.intent[index - 1];
      if (ref) {
        ref.focus();
      } else {
        this.addNewIntent();
        this.$nextTick(() => this.jumpToIntent(index));
      }
    },

    validate() {
      this.$refs.form.validate();
    },

    save() {
      if (!this.mainIntent || isEmpty(this.mainIntent.text)) {
        alert(this.$t('intents.questions.elements.enterMainQuestionAlert'));
        return;
      }

      if (this.intentQualityScore < 30) {
        alert(this.$t('intents.questions.elements.enterMoreDifferentQuestionsAlert'));
        return
      }

      this.$emit('updateIntents', this.validIntents);
      this.close();
    },

    async archive(userMessage) {
        const selectedUserMessage = {...userMessage}
        selectedUserMessage.archived = true
        await this.updateUserMessage({userMessage: selectedUserMessage})
    },

    addUserMessageToIntents(userMessage){
      this.selectedUserMessage = {
        text: userMessage.text,
        main: false
      }
      const index = this.modifiedIntents.length - 1
      this.modifiedIntents.splice(index, 0, this.selectedUserMessage)

      this.setToBeArchived({userMessageId: userMessage.id, value: true})
    },

    close() {
      this.$emit('close');
    },

    ...mapActions('messagesByFaqId', ['loadAllMessagesByFaqId']),
    ...mapActions('userMessages', ['updateUserMessage']),
    ...mapMutations('userMessages', ['setToBeArchived']),
  }
}
</script>

<style scoped>

</style>
