export default {
    buttons: {
        login: 'Login',
        changePass: 'Change password',
        logout: 'Logout',
        enter: 'Enter',
        new: 'New',
        delete: 'Delete',
        close: 'Close',
        save: 'Save',
        hide: 'Hide',
        back: 'Back',
        cancel: 'Cancel',
        saving: 'Saving',
        saved: 'Saved',
        error: 'Error',
        ok: 'OK'
    },
    rules: {
        required: 'Required',
        requiredItem: 'Required Item',
        maxChars: 'Allowed max. {0} characters',
        wrongPhoneFormat: 'Wrong format or invalid area code! Example: +420 732 298 029',
        wrongEmailFormat: 'Wrong format! Example: name@company.com',
        wrongUrlFormat: 'Wrong format! Example: http://google.com',
        wrongCoordinateFormat: 'Wrong format! Example: 49.1524345',
        requiredBothCoordinates: 'You must fill in both coordinates!',
        passwordMinChars: 'Password must be at least {0} characters!',
        passwordsDontMatch: 'Passwords do not match.',
        fewQuestions: 'Few questions!',
        wrongImageFormat: 'Image must be in {0} format!'
    },
    elements: {
        noData: 'No data',
        noTitle: 'Untitled',
        none: 'None',
        next5: 'next',
        next1: 'next',
        noDescription: 'No description',
        saveErrorAlert: 'Saving error!',
        deleteErrorAlert: 'Deleting error!',
        addErrorAlert: 'Adding error!',
        leaveWithoutSaveConfirm: 'Are you sure you want to leave without saving your changes?',
        leaveWithoutSaveConfirm2: "You didn't save your changes! Really leave?",
        invalidFormAlert: 'Some required fields are not filled in or the format is wrong somewhere! Please check all fields.',
        reallyPerformThisAction: 'Really perform this action?',
        areYouSureToPerformThisAction: 'Are you sure you want to perform this action?',
        loading: "Loading...",
    },
    langs: {
        en: "english",
        ru: "russian",
        uk: "ukrainian"
    },
    overview: {
        title: 'Overview'
    },
    tutorials: {
        title: 'Tutorials',
        description: 'Manage FAQs and tutorials for the mobile app.',
        buttons: {
            makeVisible: 'Make visible',
        },
        elements: {
            languageName: 'Name',
            searchPlaceholder: 'Search',
            withoutTranslation: 'Without translation',
            tutorialWithoutTags: 'Tutorial has no tags',
            noTagsChatbotWarning: 'Tutorial is not tagged, which may affect chatbot activity',
            withoutTag: 'No tag',
            deleteTutorialsConfirm: 'Are you sure you want to delete {0} tutorials and all their embedded entries?'
        },
        table: {
            title: 'Title',
            tags: 'Tags',
            lastUpdate: 'Modified'
        }
    },
    tutorialDetail: {
        tutorialAdding: 'Add tutorial',
        buttons: {
            deleteTranslation: 'Delete translation',
            deleteWholeTutorial: 'Delete entire tutorial',
            addSolutionStep: 'Add solution step',
        },
        elements: {
            solutionProcedure: 'Solution procedure',
            noSolution: 'No solution.',
            deleteWholeTutorialConfirm: 'Are you sure you want to delete the whole tutorial "{0}" and all its nested items?',
            deleteWholeTutorialAndTransConfirm: 'Really delete the entire tutorial "{0}", all its nested items and all its translations?',
            deleteTutorialLanguageConfirm: 'Really delete the language {0} for tutorial "{1}"? The deletion will occur including the nested items. The other language versions will remain.',
            saveTutorialFirstAlert: 'Please save the tutorial first!',
            saveChangesFirstAlert: 'Please save changes first.',
        },
        item: {
            elements: {
                questions: 'Questions',
                language: 'Language',
                hidden: 'Hidden',
                tags: 'Tags',
                userQuestions: 'User Questions',
                buttonText: 'Button text',
                tutorialName: 'Title',
                itemText: 'Text',
                help: 'Help',
                title: 'Title',
                warning: 'Warning',
                sources: 'Information source',
                source: 'Source',
                contact: 'Contact',
                followUpItems: 'Follow-up items',
                followUpQuestion: 'Follow-up question',
                saveChangesBeforeAddingNewItemConfirm: 'The tutorial is not saved and therefore a nested item cannot be added. Would you like to save all changes now?',
                deleteItemAndNestedItemsConfirm: 'Are you sure you want to delete item "{0}" and all items nested within it?',
            },
            buttons: {
                addFollowUpItem: 'Add follow-up item'
            }
        },
        translate: {
            title: 'Translation of tutorial',
            description: 'This tutorial will be automatically translated into your chosen language by Google Translate and is not guaranteed to be correct. Once translated, the tutorial must be reviewed and checked. Please select the target language.',
            selectTargetLang: 'Select target language',
            buttons: {
                translate: 'Translate'
            }
        }
    },
    tags: {
        title: 'Tags',
        description: 'Manage categories for tutorials.',
        buttons: {
            order: 'Order',
        },
        elements: {
            noTag: 'No tag.',
            deleteTagsConfirm: 'Are you sure you want to delete {0} tags? Tags will be removed from all tutorials as well.'
        },
        ordering: {
            title: 'Tags order',
        },
        dialog: {
            elements: {
                newTag: 'New tag',
                editTag: 'Edit tag',
                svgImage: 'Image (format: SVG)',
                deleteTagConfirm: 'Really delete the tag "{0}"? The tag is removed from all tutorials as well',
                localizedDescription: 'Description',
                errorWhileImageUploadAlert: 'Error uploading image! Please contact administrator'
            }
        }
    },
    contacts: {
        title: 'Contacts',
        description: 'Contacts directory. Contacts can be assigned to tutorials.',
        elements: {
            noContact: 'No contacts',
            errorWhileLoadingUsageAlert: 'Error while loading contact usage!'
        },
        table: {
            name: 'Name',
            phone: 'Phone',
            email: 'E-mail',
            address: 'Address'
        }
    },
    contactDetail: {
        buttons: {
            deleteContact: 'Delete contact',
            withoutDetermination: 'WITHOUT DETERMINATION',
            useAddress: 'Use address',
            useMapCenter: 'Use map center'
        },
        elements: {
            newContact: 'New contact',
            editContact: 'Edit contact',
            howToContact: 'How can I contact them?',
            whereOrganizationIs: 'Where is the organization located?',
            saveWithoutPositionConfirm: 'Save contact without position on map?',
            saveWithoutPositionMessage: 'You do not have a specified map location where the contact is located. This contact will not be displayed on the contact map.',
            validFormatFor: 'Valid format for',
            validEmergencyNumber: 'Valid emergency number',
            invalidFormAlert: 'Some fields or positions of the contact are not filled in correctly! Please double check them.',
            addressNotFoundAlert: 'The specified address was not found. Please try entering it in another format.',
            localizedName: 'Name',
            localizedDescription: 'Description'
        },
        card: {
            address: 'Address',
            phone: 'Phone',
            email: 'E-mail',
            web: 'Web',
        },
        deleteDialog: {
            elements: {
                wantToDeleteContacts: 'Are you sure you want to delete the following contacts?',
                contactIsNotAssigned: 'Contact is not assigned to any item.',
                contactIsAssignedAttention: 'Attention! This contact is assigned to the following tutorials. Deleting it will remove this contact from those items as well.',
            }
        },
        chooseContact: {
            elements: {
                selectContact: 'Select contact',
                noContact: 'No contacts.',
                searchContact: 'Search contact'
            }
        }
    },
    reports: {
        title: 'Problem reporting',
        description: 'Tutorial bug reports from users.',
        buttons: {
            new: 'New',
        },
        elements: {
            newReport: 'New Report',
            editReport: 'Edit Report',
            relatedTutorial: 'Related Tutorial',
            noReportForTutorial: 'There is no tutorial associated with this report.',
            newReports: 'New',
            archivedReports: 'Archived',
            noReport: 'No report',
            loadingReports: 'Loading reports',
            itemsPerPage: 'Items per page',
            deleteReportConfirm: 'Are you sure you want to delete this report?',
            deleteReportsConfirm: 'Really delete {0} selected reports?',
            none: 'None'
        },
        form: {
            description: 'Description',
            note: 'Note',
            archived: 'archived'
        },
        table: {
            tutorial: 'Tutorial',
            problemDesc: 'Problem description',
            note: 'Note'
        }
    },
    chatbotSetting: {
        title: 'Assistant settings',
        description: 'Here you can set how the assistant should respond to general questions. You define her personality.',
        elements: {
            assistant: 'Assistant',
        },
        table: {
            title: 'Title',
            lastUpdate: 'Modified'
        },
    },
    chatbotDetail: {
        elements: {
            mainParams: 'Main parameters',
            parametersExplanation: "The main parameters are used to set the assistant's responses within the basic conversation. Each parameter must have at least 2 response options. The more alternatives you provide, the more human the assistant will appear.",
            unusualQuestions: 'Unusual questions',
            unusualQuestionsExplanation: 'Unusual questions are used for when a user writes a message that has nothing to do with tutorials, e.g. users often ask a chatbot for fun if they are human, where they are from or how old they are. The more you include unusual questions, the more human the chatbot will seem and the more pleasant the discussion will be.',
            requireMinSentencesAlert: "All items must have at least {0} sentence variants. Additional variants are missing for '{1}'.",
            requireMultipleVariantsForUnusual: 'Please enter at least 2 variants for each section of the unusual question. Question "{0}" has few variants.',
            noQuestion: 'No question',
            noAnswer: 'No answer'
        },
        buttons: {
            addUnusualQuestion: 'Add unusual question'
        },
        intents: {
            fullGreet: 'Long Greeting',
            shortGreet: 'Short Greeting',
            goodbye: "Goodbye",
            fallback: "Misunderstanding",
            happy: "Affirmation",
            unhappy: "Rejection",
            restart: "Restart",
        }
    },
    intents: {
        questions: {
            elements: {
                unusualQuestion: 'Unusual question',
                enterMultipleVariants: 'Fill in as many variations as possible of how the user might ask the question. Then list multiple variations of how the chatbot might respond. The more variations you provide, the better the chatbot will perform. The order does not matter.',
                questions: 'Questions',
                enterMultipleVariants2: 'Enter alternatives for how the user can express his/her problem related to this tutorial. The more variants you provide, the better the chatbot will work.',
                score: 'Score',
                mainQuestion: 'Main question (to be used as title)',
                alternativeQuestionWording: 'Alternative question wording (min. {0})',
                mainAnswer: 'Main Answer',
                alternativeAnswerWording: 'Alternative answer wording (min. {0})',
                questionPlaceholders: [
                    "Who are you?",
                    "Are you real human?",
                    "Are you a robot?",
                ],
                questionPlaceholders2: [
                    "How can I request for Visa?",
                    "I need a visa. Can you help me?",
                    "What are the requirements for getting a visa?",
                ],
                answerPlaceholders: [
                    "I am an electronic brain that can respond like a human, but more efficiently.",
                    "I am a machine and proud to be so.",
                    "I am a chatbot and I am here to help you",
                ],
                nextQuestion: 'Next question',
                nextAnswer: 'Next answer',
                deleteQuestionConfirm: 'Are you sure you want to delete the question?',
                enterMainQuestionAlert: 'Please state the main question. Without it, changes cannot be saved',
                enterMainAnswerAlert: 'Please provide the main answer. Without it, changes cannot be saved.',
                enterMoreDifferentQuestionsAlert: 'Please provide multiple different questions to properly train the chatbot. The quality score must be at least 30%.',
                enterMoreAnswersAlert: 'Please provide multiple answers to make the chatbot respond more naturally.',
                reformulateSimilarSentence: 'The sentence is similar to the {0}. sentence. Please use a different wording.'
            }
        },
        chatbot: {
            elements: {
                chatbotStatements: 'Assistant statements',
                enterAlternativeSentences: 'Specify alternative sentences for the assistant to express during the event',
                mainSentence: 'Main sentence',
                enterAlternativeVariations: 'Alternative sentences (used as variations of the main sentences, min. {0})',
                sentencePlaceholders: [
                    "Hello, I am a bot and I am here to help you",
                    "Hello there, I'm a bot and probably you need help?",
                    "Hi, I'm a bot and I am here to help you",
                ],
                nextStatement: 'Next statement',
                enterMainSentenceAlert: 'Please provide the main sentence. Without it, changes cannot be saved.',
                enterMoreDifferentVariationsAlert: 'Please provide more variations on how the chatbot should respond.',
            }
        }
    },
    users: {
        title: 'Users',
        description: "Set who has access to the administration, or change the user's password.",
        table: {
            noUsers: 'No users',
            name: 'Name',
            login: 'Login name',
            role: 'Role'
        },
        elements: {
            deleteConfirm: 'Are you sure you want to delete {0} marked users?'
        }
    },
    userDetail: {
        newUser: 'New user',
        editUser: 'Edit user',
        passwordChange: 'Change password',
        buttons: {
            deleteUser: 'Delete user',
            changePass: 'Change password',
            dontChangePass: "Don't change password"
        },
        form: {
            firstLastName: 'First and last name',
            login: 'Login name',
            role: 'Role',
            password: 'Password',
            passwordAgain: 'Password again',
            oldPassword: 'Old password',
            newPassword: 'New password',
            newPasswordConfirm: 'Confirm new password'
        },
        roles: {
            admin: 'Admin (manages everything including users)',
            editor: 'Editor (manages everything except users)',
            noRole: 'No role (can only display)'
        },
        elements: {
            cantDeleteYourselfAlert: 'You cannot delete yourself!',
            deleteUserConfirm: 'Really delete user {0}?',
            wrongOldPasswordAlert: 'You entered the wrong old password!',
            cannotLostLastAdminAlert: "You can't get rid of the admin role because there is no other admin in the application!",
            usernameAlreadyExists: 'Cannot edit user because another user with the same login name already exists!'
        }
    },
    login: {
        title: 'Login',
        form: {
            login: 'Login name',
            password: 'Password'
        },
        elements: {
            errorWhileLoginAlert: 'There was a problem with the login. Check your username and password.'
        }
    },
    home: {
        title: 'Data management for the Smart Migration mobile app'
    },
    userMessages: {
        title: 'User messages',
        description: "Questions asked by users to the chatbot.",
        obtained: " (obtained in conversation with the chatbot)",
        uncategorizedMessagesAlert: "Warning! There are {0} questions frequently asked by users without an available response:",
        more: "more",
        elements: {
            searchPlaceholder: "Search tutorial",
            noMessages: "No messages",
            uncategorizedMessages: "Uncategorized messages",
        },
        filter: {
            messages: "Filter messages:",
            sortMessages: "Sort messages:",
            sortTutorials: "Sort tutorials:",
            uncategorized: "Uncategorized",
            moreThan: "More than",
            title: "Title",
            count: "Count",
            invalidInput: "Positive numbers only",
        },
        assignMessageDialog: {
            title: "Assign the user message to a tutorial",
            cancel: "Cancel",
            assign: "Assign",
        }
    },
    statistics: {
        title: 'Statistics of tutorials',
        description: "Tutorial statistics that can be compared with each other.",
        elements: {
            noStatistics: "No tutorials",
            mostHelpfulTitle: "The most helpful tutorials",
            leastHelpfulTitle: "The least helpful tutorials",
            mostUnfinishedTitle: "The most unfinished tutorials",
            selectAll: "Select all",
            sortTutorialsBy: "Sort tutorials by:",
            showMore: "Show more",
            maxCount: "Maximum count:",
        },
        graphElements: {
            helpfulFeedback: "Helpful",
            notHelpfulFeedback: "Not helpful",
            continued: "Continued",
            unfinished: "Unfinished",
            compare: "Compare",
            reset: "Reset",
        }
    }
}