<template>
    <v-dialog :value="model" max-width="600px" persistent scrollable @keydown.enter="deletePermanently" @keydown.esc="close">
        <v-card v-if="contacts">
            <v-card-title>
                <span class="headline">{{ $t('contactDetail.deleteDialog.elements.wantToDeleteContacts') }}</span>
            </v-card-title>

            <v-divider/>

            <v-card-text class="pt-4">
                <Loading v-if="isLoading"></Loading>
                <div v-else>
                    <div v-for="(contact, i) in contacts" :key="contact.id" class="mb-5">
                        <h3 class="mb-2 mt-0">{{ contact.name }}</h3>
                        <div v-if="getRelatedFaqItems(contact).length === 0">{{ $t('contactDetail.deleteDialog.elements.contactIsNotAssigned') }}</div>
                        <div v-else>
                            <v-alert type="error" dense>
                                {{ $t('contactDetail.deleteDialog.elements.contactIsAssignedAttention') }}
                            </v-alert>
                            <div class="mb-3">
                                <ul>
                                    <li v-for="item in getRelatedFaqItems(contact)"
                                        :key="item.id"
                                        class="my-2"
                                    >
                                        {{ item.name }}

                                        <v-icon 
                                            color="primary" 
                                            @click="openTutorial(item)"
                                        >open_in_new</v-icon>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <v-divider v-if="i < contacts.length - 1" class="my-6"/>
                    </div>
                </div>
            </v-card-text>

            <v-divider/>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="dark" text @click="close">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn color="error" @click="deletePermanently" :disabled="isLoading">{{ $t('buttons.delete') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Loading from "./Loading";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "DeleteContactDialog",
        components: {Loading},
        props: {
            model: Boolean,
            contacts: Array,
        },

        data() {
            return {
                isLoading: false,
            }
        },

        computed: {
            ...mapGetters('contacts', ['contactUsageById']),
        },

        watch: {
            async model(newVal, oldVal) {
                if (newVal && !oldVal) {
                    this.isLoading = true;
                    await this.loadContactsUsages(this.contacts);
                    this.isLoading = false
                }
            }
        },

        methods: {
            ...mapActions('contacts', ['loadContactsUsages', 'deleteContacts']),

            openTutorial(item) {
                const route = this.$router.resolve({name: 'tutorialDetail', params: {id: item.id}});
                window.open(route.href, '_blank');
            },

            getRelatedFaqItems(contact) { 
                return this.contactUsageById(contact.id) 
                    ? this.contactUsageById(contact.id).faqItems
                    : []; 
            },

            async deletePermanently() {
                this.isLoading = true;
                await this.deleteContacts(this.contacts);
                this.$emit("delete");
                this.close();
                setTimeout(() => this.isLoading = false, 500);
            },

            close() {
                this.$emit("close");
            }
        },

    }
</script>

<style scoped>

</style>
