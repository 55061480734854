<template>
    <v-list-item
            v-if="item !== null"
            style="margin-left: 0;"
    >
        <v-list-item-content>
            <v-list-item-title class="pointer" @click="openIntentsDialog()">
                <v-input
                    :value="item.questions"
                    :rules="[rules.intents_rule]"
                    class="pointer text-field-line"
                    @click="openIntentsDialog()"
                >
                    <span v-if="item.questions.length === 0">{{ $t('elements.none') }}</span>
                    <span v-else>{{ item.questions | questionName | placeholder($t('chatbotDetail.elements.noQuestion')) }}</span>
                    <span class="mx-2"> &mdash; </span>
                    <span>{{ item.answers | questionName | placeholder($t('chatbotDetail.elements.noAnswer')) }}</span>
                    <span v-if="item.questions.length > 5" class="ml-1">
                        (<i>+ {{ item.questions.length - 1 }} {{ $t('elements.next5') }}</i>)
                    </span>
                    <span v-else-if="item.questions.length > 1" class="ml-1">
                        (<i>+ {{ item.questions.length - 1 }} {{ $t('elements.next1') }}</i>)
                    </span>
                </v-input>
            </v-list-item-title>
        </v-list-item-content>

        <IntentsQuestions
                :model="isIntentModalVisible"
                :unusualQuestions="item"
                :index="index"
                @updateQuestion="updateQuestion"
                @deleteQuestion="deleteQuestion"
                @close="isIntentModalVisible = false; $emit('close')"
        />
    </v-list-item>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import IntentsQuestions from "./IntentsQuestions";
    import {config} from "../config";

    export default {
        name: "ChatbotUnusualQuestions",
        components: {IntentsQuestions},
        props: {
            chatbot: Object,
            item: Object,
            flat: Boolean,
            index: Number,
            prefix: String,

            /**
             * If set to true, the edit dialog opens immediately after mounting the component.
             */
            defaultOpened: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            itemNumbering() {
                if (this.level > 0) {
                    if (this.prefix !== "") {
                        return this.prefix + (this.index + 1) + ".";
                    } else {
                        return this.index + 1 + ".";
                    }
                } else {
                    return "";
                }
            },
            newItemNumbering() {
                if (this.itemNumbering !== "") {
                    return this.itemNumbering + (this.children.length + 1) + ".";
                } else {
                    return this.children.length + 1 + ".";
                }
            },

            rules() {
                return {
                    intents_rule: v => v && v.length >= config.chatbotIntentLimit || this.$t('rules.fewQuestions')
                }
            },

            ...mapGetters('chatbots', ['prepareNewItem', 'prepareNewIntent']),
        },

        data() {
            return {
                isIntentModalVisible: false,
            };
        },

        watch: {
            defaultOpened(val) {
                if (val) {
                    this.isIntentModalVisible = true;
                }
            },
        },

        methods: {
            setName(name) {
                if (this.item.intents.length === 0) {
                    const intent = this.prepareNewIntent(true);
                    this.setAttribute('intents', [intent]);
                }
                this.setAttribute('intents.0.text', name);
            },

            addChildItem() {
                if (this.item.isTemporary) {
                    alert(this.$t('tutorialDetail.elements.saveTutorialFirstAlert'));
                } else {
                    const newChild = this.prepareNewItem(this.item);
                    this.addItem({item: newChild});
                }
            },

            setAttribute(key, value) {
                this.setItemAttribute({item: this.chatbot, key, value});
                this.makeChanges();
            },

            openIntentsDialog() {
                this.isIntentModalVisible = true;
            },

/*            updateIntents(intents) {
                this.setAttribute('intents', intents);
            },*/


            updateQuestion(question) {
                const copy = this.chatbot.unusualQuestions.slice();
                copy[this.index] = question;
                this.setAttribute("unusualQuestions", copy);
            },

            deleteQuestion(index) {
                    const copy = this.chatbot.unusualQuestions.slice();
                    copy.splice(index,1);
                    this.setAttribute("unusualQuestions", copy);
            },

            ...mapActions('chatbots', ['setItemAttribute', 'addItem']),
            ...mapActions('changeTracking', ['makeChanges'])
        }
    };
</script>

<style>
    .v-expansion-panel.flat:before {
        box-shadow: none;
    }
    .v-expansion-panel.flat .v-expansion-panel-content__wrap {
        padding: 0 !important;
    }
</style>

<style scoped>
    tr {
        cursor: pointer;
    }
</style>
