import i18n from '@/plugins/i18n/i18n';

export const config = {
    version: '1.1.6',
    serverUrl: process.env.VUE_APP_SERVER_URL,
    tutorialIntentLimit: 3, // the minimum number of intents that must be defined in tutorials
    chatbotIntentLimit: 3, // the minimum number of intents that must be defined for chatbot's questions and unusual questions
    mostFrequentUserMessagesNumber: 3, // the number of most frequent user messages, it is used as a parameter for an API request to the server that specifies how many of the most frequent user messages should be returned in the response
    mostHelpfulTutorialsNumber: 3, // the number of most useful tutorials, it is used as a parameter for an API request to the server that specifies how many of the most helpful tutorials should be returned in the response
    mostNotHelpfulTutorialsNumber: 3, // the number of most not useful tutorials, it is used as a parameter for an API request to the server that specifies how many of the least helpful tutorials should be returned in the response
    mostUnfinishedTutorialsNumber: 3, // the number of most unfinished tutorials, it is used as a parameter for an API request to the server that specifies how many most unfinished tutorials should be returned in the response
};

export const languages = [
    "en",
    "ru",
    "uk"
];

export const localizedLanguages = {
    "en": () => i18n.t('langs.en'),
    "ru": () => i18n.t('langs.ru'),
    "uk": () => i18n.t('langs.uk')
};
