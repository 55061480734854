import Vue from 'vue';
import { nlpUtils } from '@/utils/nlp';
import i18n from '@/plugins/i18n/i18n';

/**
 * Returns an error message (string) when the given `intent` is very similar to one of `intents`.
 * Usage: `:rules="intent | similarIntentRules(modifiedIntents)"`
 * @param {*} intent The base intent to be compared with other intents.
 * @param {*} intents The other intents.
 * @returns True if the intent is unique, a string with an error message otherwise.
 */
export const similarIntentRules = (intent, intents) => [() => {
    // do not look for similarities of an empty intent
    if (!intent || intent.text === '') {
      return true; 
    }

    const precedingIntents = intents.slice(0, intents.indexOf(intent)); // look only backwards to prevent duplicate errors
    const similarIntentIndex = nlpUtils.findSimilarIntentIndex(precedingIntents, intent);

    if (similarIntentIndex !== -1) {
        return i18n.t('intents.questions.elements.reformulateSimilarSentence', [similarIntentIndex + 1]);
    } else {
        return true; // no problem
    }
}];

export const hasSimilarIntent = (intent, intents) => {
  const index = intents.indexOf(intent);
  const precedingIntents = intents.slice(0, index);
  return nlpUtils.hasSimilarIntent(precedingIntents, intent);
};

Vue.filter('hasSimilarIntent', hasSimilarIntent);
Vue.filter('similarIntentRules', similarIntentRules);