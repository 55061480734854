<template>
    <div>
        <floating-loading :shown="!didLoadedData"></floating-loading>

        <v-layout class="pb-3" align-center>
            <h1>{{ $t('reports.title') }}</h1>
            <v-icon class="icon-margin ml-2" @click="load">mdi-refresh</v-icon>
            <v-spacer></v-spacer>
            <v-btn v-if="currentUserCanEdit('reports') && selected.length === 0" dark color="primary" @click="showCreateDialog()">+ {{ $t('reports.buttons.new') }}</v-btn>
            <v-btn v-else-if="currentUserCanEdit('reports')" color="error" @click="deleteSelected">{{ $t('buttons.delete') }}</v-btn>
        </v-layout>

        <v-dialog v-model="dialog" max-width="600px" persistent scrollable>
            <v-card>
                <v-card-title class="headline">
                    <span v-if="editedReport.id === undefined">{{ $t('reports.elements.newReport') }}</span>
                    <span v-else>{{ $t('reports.elements.editReport') }}</span>
                </v-card-title>
                <v-divider/>
                <v-card-text class="pt-4">
                    <div v-if="isSaving" class="pb-3">
                        <Loading></Loading>
                    </div>
                    <v-form v-else ref="form">
                        <v-select
                            v-if="editedReport.id === undefined"
                            :label="$t('reports.elements.relatedTutorial')"
                            :items="selectTutorialItems"
                            v-model="editedReport.faqItemId"
                            @change="makeChanges"
                        ></v-select>
                        <div v-else-if="itemById(editedReport.faqItemId) !== undefined">
                            <label>{{ $t('reports.elements.relatedTutorial') }}:</label><br>
                            <router-link :to="{ name: 'tutorialDetail', params: {id: editedReport.faqItemId}}" target="_blank">
                                {{itemById(editedReport.faqItemId).name }}
                            </router-link>
                        </div>
                        <div v-else>
                            <label>{{ $t('reports.elements.relatedTutorial') }}:</label><br>
                            <i>{{ $t('reports.elements.noReportForTutorial') }}</i>
                        </div>
                        <v-lazy> <!-- Use lazy to fix auto grow issue (not growing when reopen) -->
                            <v-textarea
                                    class="mt-4"
                                    color="dark"
                                    :label="$t('reports.form.description')"
                                    autofocus
                                    v-model="editedReport.description"
                                    :rows="1"
                                    auto-grow
                                    :rules="rules.requiredRules"
                                    @input="makeChanges"
                            ></v-textarea>
                        </v-lazy>
                        <v-lazy> <!-- Use lazy to fix auto grow issue (not growing when reopen) -->
                            <v-textarea
                                    color="dark"
                                    :label="$t('reports.form.note')"
                                    v-model="editedReport.note"
                                    :rows="1"
                                    auto-grow
                                    @input="makeChanges"
                            ></v-textarea>
                        </v-lazy>
                        <v-switch
                                :disabled="editedReport.id === undefined"
                                v-model="editedReport.isArchived"
                                :label="$t('reports.form.archived')"
                                @change="makeChanges"
                        ></v-switch>
                    </v-form>
                </v-card-text>
                <v-divider/>
                <v-card-actions v-if="!isSaving">
                    <v-btn v-if="currentUserCanEdit('reports') && !isNew" color="error" text @click="attemptToDeleteReport">{{ $t('buttons.delete') }}</v-btn>
                    <v-spacer/>
                    <v-btn color="dark" text @click="closeDialog">{{ $t('buttons.close') }}</v-btn>
                    <v-btn v-if="currentUserCanEdit('reports')" color="success" type="submit" @click="saveReport">{{ $t('buttons.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-row>
            <v-col cols="12">

                <v-tabs
                        background-color="primary"
                        dark
                        class="elevation-1"
                        v-model="$query.archived"
                        @change="onTabChanged"
                >
                    <v-tab>{{ $t('reports.elements.newReports') }}</v-tab>
                    <v-tab>{{ $t('reports.elements.archivedReports') }}</v-tab>
                </v-tabs>

                <v-data-table
                        :headers="headers"
                        :items="reports"
                        class="clickable-rows elevation-1"
                        :sort-by.sync="$query.sort"
                        :sort-desc="!$query.asc"
                        @update:sort-desc="desc => $query.asc = !desc"
                        :no-data-text="$t('reports.elements.noReport')"
                        show-select
                        v-model="selected"
                        @click:row="item => showEditDialog(item)"
                        :page="$query.page"
                        :items-per-page="$query.itemsPerPage"
                        :server-items-length="count"
                        @update:options="onPageChange"
                        :loading="!didLoadedData"
                        :loading-text="$t('reports.elements.loadingReports') + '...'"
                        :disable-sort="true"
                        :footer-props="{
                          itemsPerPageOptions: [5, 10, 20, 50],
                          itemsPerPageText: $t('reports.elements.itemsPerPage'),
                          pageText: `${$query.page} z ${Math.ceil(count / $query.itemsPerPage)}`,
                          showCurrentPage: true,
                        }"
                >
                    <template v-slot:[`item.faqItemId`]="{ item }" >
                        <span v-if="itemById(item.faqItemId) !== undefined">
                            {{ itemById(item.faqItemId).name }}
                        </span>
                        <span v-else>-</span>
                    </template>
                    <template v-slot:[`item.description`]="{ item }" >
                        <b>{{ item.description ? item.description : $t('elements.noDescription') }}</b>
                    </template>
                    <template v-slot:[`item.note`]="{ item }" >
                        {{ item.note ? item.note : "-" }}
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import FloatingLoading from "../components/FloatingLoading";
    import {isEmpty} from "../filters/isEmpty";

    export default {
        name: "reports",
        components: {FloatingLoading},
        mounted() {
            this.load();
            this.loadTutorials()
        },
        data() {
            return {
                dialog: false,
                newReport: null,
                editedReport: {},
                isSaving: false,
                selected: [],
            }
        },
        methods: {
            attemptToDeleteReport() {
                if (confirm(this.$t('reports.elements.deleteReportConfirm'))) {
                    this.deleteReports([this.editedReport]);
                    this.closeDialog();
                }
            },

            deleteSelected() {
                if (confirm(this.$t('reports.elements.deleteReportsConfirm', [this.selected.length]))) {
                    this.deleteReports(this.selected);
                    this.selected = [];
                    this.closeDialog();
                }
            },

            showCreateDialog() {
                if (this.$refs.form) { 
                    this.$refs.form.resetValidation(); 
                }

                this.editedReport = Object.assign({}, this.emptyReport);
                this.dialog = true;
            },

            showEditDialog(report) {
                if (this.$refs.form) {
                    this.$refs.form.resetValidation();
                }

                this.editedReport = Object.assign({}, report);
                this.dialog = true;
            },

            closeDialog() {
                if (!this.hasChanges || confirm(this.$t('elements.leaveWithoutSaveConfirm'))) {
                    this.resolveChanges();
                    this.dialog = false;
                    this.load();
                }
            },

            async saveReport() {
                if (this.$refs.form.validate()) {
                    this.isSaving = true;

                    const request = this.isNew ? this.createReport : this.updateReport;
                    await request({report: this.editedReport});

                    this.resolveChanges();
                    this.closeDialog();
                    setTimeout(() => this.isSaving = false, 500);
                }
            },

            onTabChanged() {
                this.selected = [];
                this.$query.page = 1;
                this.load();
            },

            onPageChange(pagination) {
                if (pagination.page !== this.$query.page || pagination.itemsPerPage !== this.$query.itemsPerPage) {
                    this.$query.page = pagination.page;
                    this.$query.itemsPerPage = pagination.itemsPerPage;
                    this.load();
                }
            },

            load() {
              this.loadReports({
                page: this.$query.page - 1,
                itemsPerPage: this.$query.itemsPerPage,
                archived: !!this.$query.archived
              })
            },

            ...mapActions('reports', ['loadReports', 'createReport', 'updateReport', 'deleteReports']),
            ...mapActions('tutorials', ['loadTutorials']),
            ...mapActions('changeTracking', ['makeChanges', 'resolveChanges']),
        },

        computed: {
            didLoadedData() {
                return this.$store.state.tutorials.didLoadedData && this.$store.state.reports.didLoadedData;
            },
            selectTutorialItems() {
                let items = this.tutorials.map(t => ({text: '#' + t.id + ' ' + t.name, value: t.id}));
                items.unshift({text: this.$t('reports.elements.none'), value: null});
                return items;
            },

            isNew() {
                return this.editedReport.id === undefined;
            },

            headers() {
                return [
                    {
                        align: 'left',
                        value: 'id',
                        width: '10%',
                        text: "#",
                    },
                    {
                        align: 'left',
                        value: 'faqItemId',
                        text: this.$t('reports.table.tutorial'),
                        width: '10%',
                    },
                    {
                        align: 'left',
                        value: 'description',
                        text: this.$t('reports.table.problemDesc'),
                        width: '40%',
                    },
                    {
                        align: 'left',
                        value: 'note',
                        text: this.$t('reports.table.note'),
                        width: '30%',
                    },
                ]
            },

            rules() {
                return {
                    requiredRules: [ v => !isEmpty(v) || this.$t('rules.requiredItem')],
                }
            },

            ...mapGetters('reports', ['reports', 'emptyReport', 'count']),
            ...mapGetters('tutorials', ['tutorials', 'itemById']),
            ...mapGetters('changeTracking', ['hasChanges']),
            ...mapGetters('user', ['currentUserCanEdit']),
        },

    }
</script>

<style scoped>
    .clickable-rows >>> tbody tr {
        cursor: pointer;
    }
</style>
