import Vue from 'vue';
import {languages} from '../config';

export const localizedObject = (object, language) => {
    if (!language) {
        language = languages[0];
    }

    const attributes = object.localizedAttributes[language];

    if (attributes) {
        let copy = {...object, ...attributes};
        delete copy.localizedAttributes;
        return copy;
    } else {
        return {...object};
    }
};

export const localizedAttribute = (object, key, language) => {
    if (!language) {
        language = languages[0];
    }

    if (
        object.localizedAttributes &&
        object.localizedAttributes[language]
    ) {
        return object.localizedAttributes[language][key];
    } else {
        return null;
    }
};

Vue.filter('localized', localizedAttribute);