<template>
  <div>
    <floating-loading :shown="!didLoadedGroupedMessages"></floating-loading>
    <floating-loading :shown="!didLoadedData"></floating-loading>

    <h1>{{ $t('userMessages.title') }}</h1>

    <div v-if="groupedUserMessages.length === 0"> {{ $t('userMessages.elements.noMessages') }}</div>

    <v-container>
      <div>
        <v-row>
          <v-col
              cols="12"
              sm="6"
          >
            <v-text-field
                v-model="querySearchValue"
                :label="$t('userMessages.elements.searchPlaceholder')"
                clearable
                prepend-icon="search"
                :append-icon="querySearchValue && querySearchValue !== $query.search ? 'check' : null"
                @click:append="applyQueryFilter()"
                @click:clear="querySearchValue = ''; applyQueryFilter()"
                @keypress.enter="applyQueryFilter()"
                @blur="applyQueryFilter()"
            />
            <v-btn
                @click="clearLanguageFilter"
                fab
                x-small
                class="material-symbols-outlined"
                color="white"
            >
              <v-icon color="grey">
                block
              </v-icon>
            </v-btn>
            <span
                v-for="language in languages"
                :key="language"
                :class="`icon-flag sort-language ${language} ${$query.filterLanguage === language ? 'active' : ''}`"
                @click.stop="$query.filterLanguage = language"
            />

          </v-col>
          <v-col
              cols="12"
              sm="6"
          >
            <v-row align="center">
              <v-col
                  cols="12"
                  sm="4"

              >
                <p> {{ $t('userMessages.filter.messages') }} </p>
              </v-col>

              <v-col
                  cols="12"
                  sm="8"
              >
                <v-btn-toggle
                    v-model="toggle_multiple"
                    group
                    multiple
                    color="primary"
                >
                  <v-btn value="uncategorized" outlined color="primary">
                    {{ $t('userMessages.filter.uncategorized') }}
                  </v-btn>

                  <v-btn value="moreThan" outlined color="primary">
                    {{ $t('userMessages.filter.moreThan') }}
                  </v-btn>

                  <v-text-field
                      v-model="filterCount"
                      :rules="filterCountRules"
                      maxlength="5"
                      class="filterCount"
                      single-line
                  ></v-text-field>

                </v-btn-toggle>

              </v-col>
            </v-row>
            <v-row align="center">
              <v-col
                  cols="12"
                  sm="4"
              >
                <p> {{ $t('userMessages.filter.sortMessages') }} </p>
              </v-col>
              <v-col
                  cols="12"
                  sm="8"
              >
                <v-icon v-if="messagesSortAscending" v-on:click="messagesSortAscending = !messagesSortAscending">
                  arrow_upward
                </v-icon>
                <v-icon v-else v-on:click="messagesSortAscending = !messagesSortAscending">
                  arrow_downward
                </v-icon>
                <v-btn-toggle
                    group
                    color="primary"
                    v-model="toggle_sort_messages"
                    mandatory
                >
                  <v-btn outlined value="name" color="primary">
                    {{ $t('userMessages.filter.title') }}
                  </v-btn>
                  <v-btn outlined value="count" color="primary">
                    {{ $t('userMessages.filter.count') }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>

            <v-row align="center" align-content="center">
              <v-col
                  cols="12"
                  sm="4"
              >
                <p> {{ $t('userMessages.filter.sortTutorials') }} </p>
              </v-col>
              <v-col
                  cols="12"
                  sm="8"

              >
                  <v-icon v-if="tutorialsSortAscending" v-on:click="tutorialsSortAscending = !tutorialsSortAscending">
                    arrow_upward
                  </v-icon>
                  <v-icon v-else v-on:click="tutorialsSortAscending = !tutorialsSortAscending">
                    arrow_downward
                  </v-icon>
                <v-btn-toggle
                    group
                    mandatory
                    color="primary"
                    v-model="toggle_sort_tutorials"
                >
                  <v-btn value="name" outlined color="primary">
                    {{ $t('userMessages.filter.title') }}
                  </v-btn>

                  <v-btn value="count" outlined color="primary">
                    {{ $t('userMessages.filter.count') }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <v-spacer></v-spacer>
      <div v-if="filteredMessages.length === 0"> {{ $t('userMessages.elements.noMessages') }}</div>
      <div v-else>
        <v-expansion-panels focusable>
          <v-expansion-panel
              v-for="tutorial in filteredMessages"
              :key="tutorial.faqItemId"
          >
            <v-expansion-panel-header v-if="tutorial.faqItemName === uncategorizedMessages">
              <span :class="`icon-flag ${tutorial.language} smaller`"></span>
              {{ $t('userMessages.elements.uncategorizedMessages') }}
              <v-chip
                  class="ma-2"
                  color="primary"
                  small
                  outlined
              >
                {{ tutorial.messages.length }}
              </v-chip>
            </v-expansion-panel-header>
            <v-expansion-panel-header v-else>
              <span :class="`icon-flag ${tutorial.language} smaller`"></span>
              {{ tutorial.faqItemName }}
              <v-chip
                  class="ma-2"
                  color="primary"
                  small
                  outlined
              >
                {{ tutorial.messages.length }}
              </v-chip>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list>
                <v-list-item
                    v-for="(message, messageIndex) in tutorial.messages"
                    :key="messageIndex"
                >
                  <v-list-item-icon
                      v-if="currentUserCanAccess('userMessages')"
                  >
                    <v-btn
                        class="material-symbols-outlined"
                        fab
                        dark
                        x-small
                        color="primary"
                        @click="openDialog(message, tutorial)"
                    >
                      <v-icon dark>
                        input
                      </v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title style="font-weight: bold; display: block" v-text="message.count + 'x '"/>
                    <v-list-item-title v-text="message.text" style="white-space: pre-wrap"/>
                  </v-list-item-content>
                  <v-list-item-icon
                      v-if="currentUserCanAccess('userMessages')"
                  >
                    <v-btn
                        class="material-symbols-outlined"
                        fab
                        dark
                        x-small
                        color="grey"
                        @click="archive(message, tutorial)"
                    >
                      <v-icon dark>
                        close
                      </v-icon>
                    </v-btn>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

    </v-container>
    <AssignUserMessageDialog
        :model="isModalVisible"
        :userMessage="selectedUserMessage"
        :newIntent="selectedUserMessageText"
        :predictedTutorialId="predictedTutorialId"
        @close="isModalVisible = false"
    />
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import FloatingLoading from "@/components/FloatingLoading";
import AssignUserMessageDialog from "@/components/AssignUserMessageDialog";
import {languages} from '../config';
import {sanitize} from "@/utils/common";
import {nlpUtils} from "@/utils/nlp";

export default {
  name: "UserMessages",
  components: {FloatingLoading, AssignUserMessageDialog},
  mounted() {
    this.loadGroupedMessages();
  },

  data() {
    return {
      querySearchValue: this.$query.search,
      queryFilterValue: this.$query.filterLanguage,

      isModalVisible: false,
      selectedUserMessage: {},
      selectedUserMessageText: "",
      predictedTutorialId: undefined,

      tutorialsSortAscending: false,
      messagesSortAscending: false,

      // filter constants
      filterCount: 3,
      uncategorizedMessages: "Uncategorized messages",
      uncategorized: "uncategorized",
      moreThan: "moreThan",
      toggle_multiple: ["uncategorized", "moreThan"],
      toggle_sort_messages: "count",
      toggle_sort_tutorials: "count",
      name: "name",
      count: "count",

      filterCountRules: [
          value => value > -1 || this.$t('userMessages.filter.invalidInput')
      ]
    }
  },
  methods: {
    ...mapActions('groupedUserMessages', ['loadGroupedMessages']),
    ...mapActions('userMessages', ['updateUserMessage']),

    applyQueryFilter() {
      this.$query.search = this.querySearchValue;
    },

    clearLanguageFilter() {
      this.$query.filterLanguage = ""
    },

    sumMessages(messages) {
      let sum = 0

      messages.forEach(mess => {
        sum += mess.count
      })

      return sum
    },

    selectUserMessage(userMessage, tutorial) {
      let faqItemId = tutorial.faqItemId < 0 ? null : tutorial.faqItemId
      let faqItemName = tutorial.faqItemId < 0 ? null : tutorial.faqItemName

      this.selectedUserMessage = {
        id: userMessage.id,
        text: userMessage.text,
        count: userMessage.count,
        language: userMessage.language,
        faqItemId: faqItemId,
        faqItemName: faqItemName,
        archived: userMessage.archived,
      };

      this.selectedUserMessageText = userMessage.text
    },

    openDialog(userMessage, tutorial){
      this.selectUserMessage(userMessage, tutorial)
      this.predictedTutorialId = tutorial.faqItemId;
      this.isModalVisible = true;
    },

    async archive(userMessage, tutorial) {
        this.selectUserMessage(userMessage, tutorial)
        this.selectedUserMessage.archived = true;
        await this.updateUserMessage({userMessage: this.selectedUserMessage})
    },
  },

  computed: {
    didLoadedGroupedMessages() {
      return this.$store.state.groupedUserMessages.didLoadedGroupedMessages
    },

    ...mapGetters('groupedUserMessages', ['groupedUserMessages']),
    ...mapGetters('user', ['currentUserCanAccess']),
    ...mapGetters('tutorials', ['didLoadedData']),

    languages() {
      return languages
    },

    filteredMessages() {
      let result = this.groupedUserMessages.slice();

      result = result.filter(tutorial => this.sumMessages(tutorial.messages) > 0)

      if (this.$query.search) {
        const requiredTokens = nlpUtils.tokenizeWithoutWhitespaces(this.$query.search);
        result = result.filter(item => requiredTokens.every(token => sanitize(item.faqItemName).includes(sanitize(token))))
      }

      if(this.$query.filterLanguage === 'uk') {
        result = result.filter(item => item.language === 'uk')
      }

      if(this.$query.filterLanguage === 'en') {
        result = result.filter(item => item.language === 'en')
      }

      if(this.$query.filterLanguage === 'ru') {
        result = result.filter(item => item.language === 'ru')
      }

      if (this.toggle_multiple.find(item => item === this.moreThan) && this.filterCount > -1) {
        result = result.map(data => ({
          ...data,
          messages: data.messages.filter(item => item.count > this.filterCount)
        })).filter(data => data.messages.length > 0)
      }

      if(this.toggle_multiple.find(item => item === this.uncategorized)) {
        result = result.filter(item => item.faqItemName === this.uncategorizedMessages)
      }

      if(this.toggle_sort_tutorials === this.name) {
        if(this.tutorialsSortAscending) {
          result.sort((a, b) => a.faqItemName.localeCompare(b.faqItemName))
        } else {
          result.sort((a, b) => b.faqItemName.localeCompare(a.faqItemName))
        }
      }

      if(this.toggle_sort_tutorials === this.count) {
        if(this.tutorialsSortAscending) {
          result = result.sort((a, b) => {
            const sumA = a.messages.length
            const sumB = b.messages.length
            return sumA - sumB
          })
        } else {
          result = result.sort((a, b) => {
            const sumA = a.messages.length
            const sumB = b.messages.length
            return sumB - sumA
          })
        }
      }

      if(this.toggle_sort_messages === this.name){
        if(this.messagesSortAscending) {
          result = result.map (data => ({
            ...data,
            messages: [...data.messages].sort((a, b) => a.text.localeCompare(b.text))
          }))
        } else {
          result = result.map (data => ({
            ...data,
            messages: [...data.messages].sort((a, b) => b.text.localeCompare(a.text))
          }))
        }
      }

      if(this.toggle_sort_messages === this.count){
        if(this.messagesSortAscending) {
          result = result.map (data => ({
            ...data,
            messages: [...data.messages].sort((a, b) => a.count - b.count)
          }))
        } else {
          result = result.map (data => ({
            ...data,
            messages: [...data.messages].sort((a, b) => b.count - a.count)
          }))
        }
      }

      return result;
    },
  },

}
</script>

<style scoped>
.icon-flag {
  cursor: pointer;
}

.sort-language:not(.active) {
  opacity: 0.3;
}

.smaller {
  width: 18px;
  height: 14px;
}

.filterCount {
  width: 50px;
  padding-top: 5px;
}

.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
  flex: none;
}
.col-12 {
  padding-bottom: 0;
  padding-top: 0;
}

.v-expansion-panels {
  padding-top: 4rem;
}

.v-application p {
  margin-bottom: 0;
}



</style>