<template>
    <div class="text-center mt-5 mb-5">
        <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
        ></v-progress-circular>
    </div>
</template>

<script>
    export default {
        name: "Loading"
    }
</script>

<style scoped>

</style>
